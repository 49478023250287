@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.main {
	flex-grow: 1;
}

@container aside-column (min-width: 45dvw) {
	aside > header > span {
		display: flex;
		gap: Tools.asRem(Spacing.$gutter-small);
	}
}

.aside {
	height: auto;
	min-height: min(100%, Tools.asRem(536px)); // preserve height on item switching/data loading
	max-height: 100%;
	padding-bottom: Tools.asRem(Spacing.$gutter-medium);
	
	> header > span > h3 {
		text-transform: uppercase;
	}
}
