@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;

.cell {
  gap: Tools.asRem(Spacing.$gutter-micro);
  align-items: center;
  flex-grow: 1;
  height: Tools.asRem(32px);

  input {
    border-radius: 0;
  }
}

.indent {
  width: Tools.asRem(8px);
}