@import "./styles/elements";
@import "./styles/generic";
@import "./styles/objects";
@import "./styles/trumps";

@import './styles/colours';
@import './styles/tables';
@import './styles/inputs';
@import "./styles/buttons";
@import "./styles/layout";
@import "./styles/icons";

#root {

  flex-grow: 1;
  max-height: 100vh;
  max-height: 100dvh;

  > div { flex-grow: 1; }
}