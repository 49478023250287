@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/animations" as Animations;


.p-input-icon-left, .p-input-icon-right {
    display: inherit !important;
}

.p-dropdown,
.p-inputtext {
  &--in-cell  {
		

		width: 100%;
		border-radius: 0;
		outline-offset: -2px !important;
		margin-top: -1px !important;
		padding: 0 8px !important;
		height: 100%;

		& .p-dropdown-label {
				display: flex;
				align-items: center;
		}

		& .p-dropdown-trigger {
				margin-right: Tools.asRem(0 - Spacing.$gutter-small);
		}
  }
}

select.p-dropdown option {
	background-color: Colors.$dropdown-background;
	padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-micro);
}