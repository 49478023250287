@use "colors" as Colors;
@use "spacing" as Spacing;
@use "../tools" as Tools;
@use "fonts.body" as Body;


%table-body-family {
	font-family: OpenSans;
	font-weight: 600;
}

@mixin table-footer {
	@extend %table-body-family;

	@include Body.font-size-small;
	font-style: italic;
}