@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/animations" as Animation;
@use "src/design-system/globals/fonts" as Text;

.p-button {
    
	&:not(.p-button--tab-style, .p-button--vertical-tab) {
		background: Colors.$ob-brand-orange;
	}

	padding: Spacing.$gutter-small Spacing.$gutter-medium;
	transition: $formElementTransition;

	//  These are specific to the default button styling. Eg - not themed or modified
	border: 2px solid transparent;
	border-radius: Tools.asRem(4px);
	color: Colors.$text-primary;
	font-family: Panton;
	font-size: Tools.asRem($button-label-size-large);
	font-weight: 600;
	gap: Tools.asRem(Spacing.$gutter-small);
	
	&:not(.lowercase) { text-transform: uppercase; }

	&.p-button-outlined {
			background-color: transparent;
			color: Colors.$ob-brand-orange;
			border: 2px solid Colors.$ob-brand-orange;

			&.p-button-plain {
					color: $plainButtonTextColor;
					border-color: $plainButtonTextColor;

					@include button-states {
							&:hover {
									background: $plainButtonHoverBgColor;
									color: $plainButtonTextColor;
							}
			
							&:active {
									background: $plainButtonActiveBgColor;
									color: $plainButtonTextColor;
							}
					}
			}
	}

	&.p-button-filled {
			background-color: $filledButtonBg;
			color: $filledButtonTextColor;
			border: $filledButtonBorder;
			width: fit-content;
			
			&:disabled {
					background: $filledButtonDisabledBg;
					color: $filledButtonDisabledTextColor;

					.icon--primary::before {
							color: $filledButtonDisabledTextColor;
					}
			}

			@include button-states {
					&:hover {
							background: $filledButtonHoverBg;
					}

					&:active {
							background: $filledButtonActiveBg;
					}
			}

			&.p-button-plain {
					color: $plainButtonTextColor;
					border-color: $plainButtonTextColor;
					
					.icon--primary::before {
							color: $plainButtonTextColor;
					}

					@include button-states {
							&:hover {
									background: $plainButtonHoverBgColor;
									color: $plainButtonTextColor;
							}
			
							&:active {
									background: $plainButtonActiveBgColor;
									color: $plainButtonTextColor;
							}
					}
			}
	}

	&.p-button-filled:disabled,
	&.p-button-outlined:disabled,
	&.p-button-text:not(.p-button-text):disabled {
		opacity: .46;
	}

	&.p-button-text {

			background-color: Colors.$text-only-button-background;
			// background-color: Colors.$ob-brand-black;
			background-blend-mode: darken;
			border-color: transparent;
			color: Colors.$text-secondary;
			transition: Animation.$animation-color;
			cursor: pointer;

			& label {
				cursor: pointer;
			}

			@media only screen and (min-width: 960px) {
					// @include button-states {
					
							&:hover {
									// background: Colors.$full-grey;
									color: Colors.$ob-brand-orange;
									border-color: transparent;
	
									& .p-button-icon::before {
											color: Colors.$ob-brand-orange;
									}
							}
	
							// &:active {
							//     background: rgba($buttonBg, $textButtonActiveBgOpacity);
							//     color: $buttonBg;
							//     border-color: transparent;
							// }
					// }
			}
			
		&:disabled span { 
			opacity: .46;
		}
		&:disabled {
			color: Colors.$text-placeholder;
		}

			&.p-button-plain {
					color: $plainButtonTextColor;

					@include button-states {
							&:hover {
									background: $plainButtonHoverBgColor;
									color: $plainButtonTextColor;
							}
			
							&:active {
									background: $plainButtonActiveBgColor;
									color: $plainButtonTextColor;
							}
					}
			}
	}

	&:focus {
			// @include focused();
	}

	.p-button-label {
			transition-duration: $transitionDuration;

			@media only screen and (max-width: 960px) { 
					font-size: Tools.asRem(12px);
			}
	}

	.p-button-icon-right {
			margin-left: $inlineSpacing;
	}

	.p-button-icon-bottom {
			margin-top: $inlineSpacing;
	}

	.p-button-icon-top {
			margin-bottom: $inlineSpacing;
	}

	.p-badge {
			margin-left: $inlineSpacing;
			min-width: $fontSize;
			height: $fontSize;
			line-height: $fontSize;
			color: $buttonBg;
			background-color: $buttonTextColor;
	}

	&.p-button-raised {
			// box-shadow: $raisedButtonShadow;
	}

	&.p-button-rounded {
			border-radius: $roundedButtonBorderRadius;
	}

	&.p-button-loading {
			& svg { width: 20px; }
	}

	&.p-button-icon-only {

			// width: $buttonIconOnlyWidth;
			// padding: $buttonIconOnlyPadding;
			gap: 0;
			
			&:not(.p-button-sm) span:first-child {
					padding: Tools.asRem(8px);
					
					&::before {
							width: Tools.asRem(24px);
							height: Tools.asRem(24px);
					}
			}

			& span:first-child { 

					transition: transform .265s ease-out;

					&::before {
							// width: Tools.asRem(24px);
							// height: Tools.asRem(24px);
							aspect-ratio: 1 / 1;
							align-items: center;
							display: inline-flex;
							justify-content: center;
					}

			
					align-items: center;
					display: flex;
					justify-content: center;
			}

			.p-button-icon-left,
			.p-button-icon-right {
					margin: 0;
			}

			&.p-button-rounded {
					border-radius: 50%;
					height: Tools.asRem(34px);
		aspect-ratio: 1/1;
			}
	}

	&.p-button-sm,
	&.p-button-small {

			@include Text.font-button-small;
			// font-size: Tools.asRem($button-label-size-small);
			// font-weight: 400;
			
			// height: fit-content;
			padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);

			.p-button-icon {
					@include scaledFontSize($primeIconFontSize, $scaleSM);
			}
	}

	&.p-button-large {
			font-size: Tools.asRem($button-label-size-large);

			.p-button-icon {
					@include scaledFontSize($primeIconFontSize, $scaleLG);
			}
	}

	&.p-button-loading-label-only {
			&.p-button-loading-left {
					.p-button-label {
							margin-left: $inlineSpacing;
					}
			}
			
			&.p-button-loading-right {
					.p-button-label {
							margin-right: $inlineSpacing;
					}
			}

			&.p-button-loading-top {
					.p-button-label {
							margin-top: $inlineSpacing;
					}
			}

			&.p-button-loading-bottom {
					.p-button-label {
							margin-bottom: $inlineSpacing;
					}
			}

			.p-button-loading-icon {
					margin: 0;
			}
	}

	//	Text Button trumps
	&.active {
			pointer-events: none;
	}

	&.transparent { background-color: transparent;}

	&.plain-text {
		font-family: OpenSans;
		text-transform: none;

		&:hover {
			color: Colors.$text-primary;

		}
	}

}

.p-fluid {
    .p-button {
        width: 100%;
    }
    
    .p-button-icon-only {
        width: $buttonIconOnlyWidth;
    }

    .p-buttonset {
        display: flex;
        
        .p-button {
            flex: 1;
        }
    }
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
    color: $secondaryButtonTextColor;
    background: $secondaryButtonBg;
    border: $secondaryButtonBorder;

    @include button-states {
        &:hover {
            background: $secondaryButtonHoverBg;
            color: $secondaryButtonTextHoverColor;
            border-color: $secondaryButtonHoverBorderColor;
        }

        &:focus {
            // box-shadow: $secondaryButtonFocusShadow;
        }

        &:active {
            background: $secondaryButtonActiveBg;
            color: $secondaryButtonTextActiveColor;
            border-color: $secondaryButtonActiveBorderColor;
        }
    }
    
    &.p-button-outlined {
        background-color: transparent;
        color: $secondaryButtonBg;
        border: $outlinedButtonBorder;

        @include button-states {
            &:hover {
                background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
                color: $secondaryButtonBg;
                border: $outlinedButtonBorder;
            }

            &:active {
                background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
                color: $secondaryButtonBg;
                border: $outlinedButtonBorder;
            }
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $secondaryButtonBg;
        border-color: transparent;

        @include button-states {
            &:hover {
                background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $secondaryButtonBg;
            }

            &:active {
                background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $secondaryButtonBg;
            }
        }
    }
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
    color: $infoButtonTextColor;
    background: $infoButtonBg;
    border: $infoButtonBorder;

    @include button-states {
        &:hover {
            background: $infoButtonHoverBg;
            color: $infoButtonTextHoverColor;
            border-color: $infoButtonHoverBorderColor;
        }

        &:focus {
            box-shadow: $infoButtonFocusShadow;
        }

        &:active {
            background: $infoButtonActiveBg;
            color: $infoButtonTextActiveColor;
            border-color: $infoButtonActiveBorderColor;
        }
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $infoButtonBg;
        border: $outlinedButtonBorder;

        @include button-states {
            &:hover {
                background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
                color: $infoButtonBg;
                border: $outlinedButtonBorder;
            }

            &:active {
                background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
                color: $infoButtonBg;
                border: $outlinedButtonBorder;
            }
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $infoButtonBg;
        border-color: transparent;

        @include button-states {
            &:hover {
                background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $infoButtonBg;
            }

            &:active {
                background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $infoButtonBg;
            }
        }
    }
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
    color: Colors.$text-primary;
    background: Colors.$grass-80;
    // border: $successButtonBorder;

    @include button-states {
        &:hover {
            background: $successButtonHoverBg;
            color: $successButtonTextHoverColor;
            border-color: $successButtonHoverBorderColor;
        }

        &:focus {
            box-shadow: $successButtonFocusShadow;
        }

        &:active {
            background: $successButtonActiveBg;
            color: $successButtonTextActiveColor;
            border-color: $successButtonActiveBorderColor;
        }
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $successButtonBg;
        border: $outlinedButtonBorder;

        @include button-states {
            &:hover {
                background: rgba($successButtonBg, $textButtonHoverBgOpacity);
                color: $successButtonBg;
                border: $outlinedButtonBorder;
            }

            &:active {
                background: rgba($successButtonBg, $textButtonActiveBgOpacity);
                color: $successButtonBg;
                border: $outlinedButtonBorder;
            }
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $successButtonBg;
        border-color: transparent;

        @include button-states {
            &:hover {
                background: rgba($successButtonBg, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $successButtonBg;
            }

            &:active {
                background: rgba($successButtonBg, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $successButtonBg;
            }
        }
    }
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
    color: $warningButtonTextColor;
    background: $warningButtonBg;
    border: $warningButtonBorder;
    
    @include button-states {
        &:hover {
            background: $warningButtonHoverBg;
            color: $warningButtonTextHoverColor;
            border-color: $warningButtonHoverBorderColor;
        }

        &:focus {
            box-shadow: $warningButtonFocusShadow;
        }

        &:active {
            background: $warningButtonActiveBg;
            color: $warningButtonTextActiveColor;
            border-color: $warningButtonActiveBorderColor;
        }
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $warningButtonBg;
        border: $outlinedButtonBorder;

        @include button-states {
            &:hover {
                background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
                color: $warningButtonBg;
                border: $outlinedButtonBorder;
            }

            &:active {
                background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
                color: $warningButtonBg;
                border: $outlinedButtonBorder;
            }
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $warningButtonBg;
        border-color: transparent;

        @include button-states {
            &:hover {
                background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $warningButtonBg;
            }

            &:active {
                background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $warningButtonBg;
            }
        }
    }
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
    color: $helpButtonTextColor;
    background: $helpButtonBg;
    border: $helpButtonBorder;

    @include button-states {
        &:hover {
            background: $helpButtonHoverBg;
            color: $helpButtonTextHoverColor;
            border-color: $helpButtonHoverBorderColor;
        }

        &:focus {
            box-shadow: $helpButtonFocusShadow;
        }

        &:active {
            background: $helpButtonActiveBg;
            color: $helpButtonTextActiveColor;
            border-color: $helpButtonActiveBorderColor;
        }
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $helpButtonBg;
        border: $outlinedButtonBorder;

        @include button-states {
            &:hover {
                background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
                color: $helpButtonBg;
                border: $outlinedButtonBorder;
            }

            &:active {
                background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
                color: $helpButtonBg;
                border: $outlinedButtonBorder;
            }
        }
    }

    &.p-button-text {
        // background-color: transparent;
        // color: $helpButtonBg;
        // border-color: transparent;

        @include button-states {
            &:hover {
                background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $helpButtonBg;
            }

            &:active {
                background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $helpButtonBg;
            }
        }
    }
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {

    &.p-button-outlined {
        background-color: transparent;
        color: $dangerButtonBg;
        border: $outlinedButtonBorder;

        @include button-states {
            &:hover {
                background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
                color: $dangerButtonBg;
                border: $outlinedButtonBorder;
            }

            &:active {
                background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
                color: $dangerButtonBg;
                border: $outlinedButtonBorder;
            }
        }
    }

    &.p-button-text {
        // background-color: transparent;
        color: Colors.$ob-brand-red; //$dangerButtonBg;
        border-color: transparent;

        @include button-states {
            &:hover {
                background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $dangerButtonBg;
            }

            &:active {
                background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $dangerButtonBg;
            }
        }
    }
}

.p-button.p-button-link {
    color: $linkButtonColor;
    background: transparent;
    border: transparent;

    @include button-states {
        &:hover {
            background:  transparent;
            color: $linkButtonHoverColor;
            border-color:  transparent;

            .p-button-label {
                text-decoration: $linkButtonTextHoverDecoration;
            }
        }

        &:focus {
            background: transparent;
            box-shadow: $linkButtonFocusShadow;
            border-color:  transparent;
        }

        &:active {
            background:  transparent;
            color: $linkButtonColor;
            border-color:  transparent;
        }
    }
}

.p-button--tab-style {
    
	@include Tools.dotted-border($edge: bottom);
	background-color: transparent;
	border: 0;
	border-bottom: Tools.asRem(3px) solid transparent;
	color: Colors.$text-secondary;
	transition: color .325s ease-out 0s, border-bottom .865s ease-out 0s;
	text-transform: unset;

	&:not(:focus) {
		border-radius: unset;
	}

	&:focus {
		outline-offset: -2px;
	}

	&.active {
		border-bottom: Tools.asRem(3px) solid Colors.$ob-brand-orange;
		opacity: 1;
	}
		
	&.active p-button-icon::before,
	&.active {
		color: Colors.$ob-brand-orange;
	}
}
