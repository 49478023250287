@use "./src/design-system/globals/colors" as Colors;
@use "./src/design-system/globals/spacing" as Spacing;
@use "./src/design-system/tools" as Tools;

.gridView {
    margin-bottom: Tools.asRem(Spacing.$gutter-small);

    :global {
        .p-virtualscroller-content {
            display: block;
            padding: Tools.asRem(Spacing.$gutter-small);
            padding-top: 0;
        }
    }

    .gridViewItems {
        $item-border: 1px dashed Colors.$slate;
        $areas: vessel, owner, commodity, size, charterer, loadPort, loadTime, discPort, discTime, arrival, probability;

        border-radius: 3px;
        background-color: Colors.$charcoal;
        margin-bottom: Tools.asRem(Spacing.$gutter-small);

        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: Tools.asRem(55px);
        grid-template-areas:
            "vessel     vessel      vessel      vessel      owner       owner       owner        owner"
            "commodity  commodity   commodity   size        size        charterer   charterer    charterer"
            "loadPort   loadPort    loadPort    loadPort    loadTime    loadTime    loadTime     loadTime"
            "discPort   discPort    discPort    discPort    discTime    discTime    discTime     discTime"
            "arrival    arrival     arrival     arrival     arrival     arrival     probability  probability";

        // generate class names from the list
        @each $area in $areas {
            .#{$area} {
                grid-area: #{$area};
            }
        }

        > div {
            padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
            border-bottom: $item-border;
            border-right: $item-border;
            gap: Tools.asRem(2px);

            .label {
                text-transform: uppercase;
                color: Colors.$text-secondary;
                font-size: Tools.asRem(11px);
                font-weight: 700;

            }

            :global {
                .upper {
                    &:not(.unknown) {
                        font-weight: 700;
                    }
                }

                .lower {
                    color: Colors.$text-secondary;
                    font-size: Tools.asRem(11px);
                    margin-top: -1 * Tools.asRem(Spacing.$gutter-small); // tighten grid lines
                }

		        @include Tools.unknownValue();
            }
        }

        .discTime, .loadTime, .charterer, .owner, .probability {
            border-right: none;
        }

        .arrival, .probability {
            border-bottom: none;
        }

        .probability {
            > div {
                width: Tools.asRem(38px);
                height: Tools.asRem(38px);
            }
        }
    }
}