@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.container {
  @include Tools.dotted-border($edge: top);
}

.pAccordion {
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  & > div {
    flex-direction: column;
  }
}

.pAccordionContent {
  margin-top: Tools.asRem(Spacing.$gutter-medium);
}

.pAccordionHeader {
  border: 0 !important; // Overwrite _accordion.scss styles (same as below)

  a {
    border: 0 !important;
    outline: none !important;
    background: transparent !important;
    font-size: Tools.asRem(14px) !important;
    height: Tools.asRem(34px);
  }
}