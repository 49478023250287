@use "src/design-system/globals/colors" as Color;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

@import './styles/colours.scss';

.loader {
    height: 100vh;
    height: 100dvh;
    width: 100vw;

    & .lds-ripple {
        margin-left: calc(50vw - 40px);
        margin-top: 30vh;
    }

}

.mobile__header {
	align-items: center;
    background-color: Color.$full-black;
}

.app-logo {
    background-image: url('./styles/logos/Color logo - no background.svg');
    background-size: 100px;
    height: 95px;
    width: 182px;
    background-repeat: no-repeat;
    background-position: center;
}

.hide {
    display: none;
}

.error-message {
    font-size: 1.15rem;
    display: block;

    & a {
        color: #b3873f;
    }
}

.dialog-close-button {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    cursor: default;
}
