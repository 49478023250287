@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;

.cell {
	flex-direction: row-reverse;
}

.status {
  height: Tools.asRem(32px);
  // width: Tools.asRem(32px);
	min-width: Tools.asRem(32px);
  align-items: center;
  justify-content: center;

  &::before {
    width: Tools.asRem(18px) !important;
    height: Tools.asRem(18px) !important;
  }
}

.filled {
	flex-grow: 1;
	justify-content: flex-start;
	gap: Tools.asRem(Spacing.$gutter-small);
	padding: 0 Tools.asRem(Spacing.$gutter-small);
}

.OK::before { color: Colors.$positive; }

.NoMatchingSearch::before { color: Colors.$slate-80; }

.NonFixingBrokerSearch
.EntityWasSanctioned {
  &::before { color: Colors.$text-primary; }
}

.NonFixingBrokerSearch {
  background-color: Colors.$ob-brand-orange;
}

.ShowsRisk {
  background-color: Colors.$negative;
}

@container main-body (max-width: #{Spacing.$table-reflow-break-point}) {
	.bb {
		margin: Tools.asRem(Spacing.$gutter-micro) 0;
	}
}

.bb > span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;                      
}

