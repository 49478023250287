@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.container {
	flex-direction: column;
	flex-grow: 1;
	padding: Tools.asRem(Spacing.$gutter-large);
	gap: Tools.asRem(Spacing.$gutter-small);

	& h2 {
		color: Colors.$text-secondary;
	}
}