@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/fonts' as Fonts;

.trade-details__container {
  header {
    @include Fonts.heading-major;
    padding: Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-secondary;

    &.trade-details__header {
      background-color: transparent;
      border: none;
    }
  }

  .close-button {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    @include Tools.top-right-element;
  }

  .p-panel-content {
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    background: none;
    border: none;
  }

  form {
    flex: 1;
    height: fit-content;

    section {
      flex-direction: column;

      header h2 {
        font-weight: 400;
      }

      > div {
        display: grid;
        column-gap: Tools.asRem(Spacing.$gutter-large);
        row-gap: Tools.asRem(Spacing.$gutter-small);
        padding: Tools.asRem(Spacing.$gutter-small);
      }

      .section--main  {
        grid-template-columns: calc(50% - Tools.asRem(calc(Spacing.$gutter-large / 2))) Tools.asRem(110px) 1fr;

        :nth-child(5),
        :nth-child(7) {
          grid-column: 2 / 4;
        }
      }

      .section--buyer,
      .section--seller,
      .section--datetime {
        grid-template-columns: repeat(3, 1fr);
      }

      .trade-input--single-line {
        height: Tools.asRem(55px); // input height
        align-items: flex-end !important;
        padding-bottom: Tools.asRem(Spacing.$gutter-small);
        white-space: nowrap;

        label {
          line-height: Tools.asRem(22px);
        }
      }

      .form-input__container {
        &:not(.align-items--center) {
          align-items: flex-start;
        }

        .p-inputgroup, .p-autocomplete {
          width: 100% !important;
        }

        .p-autocomplete {
          flex-grow: 0;
        }
      }
    }


    .switchable-unit-field__dropdown {
      flex: 0 0 min-content;

      &.p-dropdown {
        min-width: Tools.asRem(60px);

        &.p-invalid {
          border-color: Colors.$ob-brand-red;
          color: Colors.$ob-brand-red;
        }
      }
    }

    .form-input__container,
    .switchable-unit-field__input {
      ::placeholder {
        text-align: start;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    form {
      gap: Tools.asRem(Spacing.$gutter-large);

      section {
        min-width: unset !important; // override `.module__canvas section`

        .section--main,
        .section--buyer,
        .section--seller,
        .section--comments,
        .section--datetime {
          display: flex;
          flex-direction: column;
          gap: Tools.asRem(Spacing.$gutter-small);
        }

        .trade-input--single-line {
          align-items: center !important;
          padding: 0;
          height: Tools.asRem(34px);
        }
      }
    }

    footer > div {
      width: 100%;
    }
  }
}

.trade-details__info-panel {
  justify-content: start;
  border: none;
  border-radius: 0;

  .p-inline-message-text {
    font-size: Tools.asRem(13px);
    font-weight: 700;
  }
}

.trade-details__footer-right-side-buttons {
  gap: Tools.asRem(Spacing.$gutter-small);
}
