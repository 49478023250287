@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.probability-level-filter{
    &__container {
        label {
            display: flex;
            justify-content: space-between;
        }

        .p-slider {
            margin: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
        }
    }

    &__slider {
        flex-direction: column;
        width: 100%;

        &.disabled {
            opacity: 0.36;

            .p-slider.p-disabled {
                opacity: 1; // slider has already opacity set
            }
        }
    }
}