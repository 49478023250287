@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.workflows-page__grid-item {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: Colors.$charcoal;
    flex: 1 0 100%;

    .workflows-page__grid-item--requested-name {
        color: Colors.$text-secondary;
    }

    @media only screen and (max-width: 960px) {
        border: 1px solid Colors.$panel-border-color;
        padding: 0 Tools.asRem(Spacing.$gutter-small);
        border-radius: 3px;

        > :first-child {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .grid-element:not(:nth-child(2n - 1)) {
            border-right: 1px dashed Colors.$slate;
        }

        .grid-element:not(:nth-child(2n)) {
            border-right: none;
        }

        .grid-element {
            padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small);
            gap: Tools.asRem(Spacing.$gutter-micro);

            .grid-element__body {
                align-items: center;
                flex-grow: 1;
                gap: Tools.asRem(Spacing.$gutter-large);
                padding: 0;
            }
        }
    }
}

.workflows-page__grid-item-swipeable-options.swipeable-hidden-options__container {
    background: Colors.$tomato;
    border-radius: 3px;

    .workflow-page__delete-button-mobile {
        width: Tools.asRem(80px);
    }
}

