@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.distance-calc-container {
  margin-top: Spacing.$gutter-large;

  .calculation-results-container {
    flex-direction: column;
  }

  @media (max-width: 960px) {
    margin-top: 0;
  }
}

.toggle-route-settings-button {
  justify-content: center;
  
  font-family: Panton, OpenSans;
  font-weight: normal;
  font-size: Tools.asRem(16px);
  line-height: Tools.asRem(18px);
}