@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.container {
  flex-direction: column;
  gap: Tools.asRem(Spacing.$gutter-medium);

  h4 {
    font-size: Tools.asRem(13px);
    font-family: OpenSans, sans-serif;
    font-weight: 700;
  }

  ol:not(.p-dropdown-items):not(.p-autocomplete-items):not(.p-tabview-nav) {
    margin-left: 0;
  }
}

.icon {
  $iconSize: Tools.asRem(Spacing.$gutter-medium);

  height: $iconSize;
  width: $iconSize;
  border-radius: calc($iconSize / 2);

  &--notstarted {
    background-color: Colors.$slate-80;
  }

  &--inprogress {
    background-color: Colors.$sky;
  }

  &--waitingonrequestor {
    background-color: Colors.$crimson;
  }

  &--completed,
  &--approved {
    background-color: Colors.$grass;
  }

  &--rejected { background-color: Colors.$tomato; }

  &--waitingforcustomer {
    background-color: Colors.$egg-plant;
  }
}
