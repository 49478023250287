@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;

.container  {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: Tools.asRem(Spacing.$gutter-small);
    flex-grow: 1;
    height: fit-content;
    padding: Tools.asRem(Spacing.$gutter-small);
}

.item {
    flex-direction: column;

    & dt {
        font-family: OpenSans, sans-serif;
        color: Colors.$text-secondary;
        font-size: Tools.asRem(11px);
        line-height: Tools.asRem(15px);
        font-weight: 700;
        text-transform: uppercase;
    }

    & dd {
        font-weight: 400;
        font-size: Tools.asRem(13px);
        line-height: Tools.asRem(18px);
        color: Colors.$text-primary;
    }
}