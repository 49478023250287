@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.task-card__container {
  background: Colors.$panel-background-color;
  flex-direction: column;
  flex-grow: 1;

  &.p-card {
    border: 1px solid Colors.$panel-border-color;

    .p-card-body {
      padding: 0;
      flex-grow: 1;
    }

    .p-card-content {
      flex-direction: column;
      flex-grow: 1;
      padding: Tools.asRem(Spacing.$gutter-micro / 2) Tools.asRem(Spacing.$gutter-small);
    }
  }

  .task-card__row {
    padding: Tools.asRem(Spacing.$gutter-micro / 2) 0;
    gap: Tools.asRem(Spacing.$gutter-small);
    height: Tools.asRem(36px);
    align-items: center;

    &:first-child {
      border: none;
      border-bottom: 1px dashed Colors.$slate;
      padding-left: Tools.asRem(Spacing.$gutter-small);
    }

    .task-card__row-data--column-1,
    .task-card__row-data--column-2 {
      flex: 1 1 50%;
    }

    .task-card__row-data--comments-count {
      color: Colors.$text-secondary;
      font-weight: 700;
      gap: Tools.asRem(Spacing.$gutter-small);
      padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
      align-items: center;
    }

    .p-inputtext {
      border-color: transparent;
      background: transparent;
      font-size: Tools.asRem(13px);

      &:hover, &:focus {
        border-color: inherit;
      }
    }
  }
}

.task__task-status-dropdown-mark {
  padding-left: Tools.asRem(Spacing.$gutter-small);

  background: none;
  border-color: transparent;

  &::before {
    $markSize: Tools.asRem(Spacing.$gutter-medium);

    height: $markSize;
    width: $markSize;
    border-radius: calc($markSize / 2);
    content: ' ';
  }
}

.task__task-status-dropdown-mark {
  &--NotStarted::before {
    background-color: Colors.$slate-80;
  }
  &--InProgress::before {
    background-color: Colors.$sky;
  }
  
  &--WaitingOnRequestor::before {
    background-color: Colors.$crimson;
  }
  
  &--Completed::before,
  &--Approved::before {
    background-color: Colors.$grass;
  }

  &--Rejected::before {
    background-color: Colors.$tomato;
  }

  &--WaitingForCustomer::before {
    background-color: Colors.$egg-plant;
  }
}

.task__task-status-dropdown--panel {
  &.p-dropdown-panel {
    .p-dropdown-items {
      :last-child {
        border-top: 1px dashed Colors.$slate;
      }
    }
  }
}