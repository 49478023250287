@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/animations" as Animations;

.p-datatable {

  &:has(.p-datatable-footer) {
    flex-direction: column;
  }

  &-wrapper {
    flex-grow: 1;
  }

  &-footer {
    & > footer { flex-grow: 1;}
  }

  &-tbody,
  &-thead {
    & .p-frozen-column {
      box-shadow: 2px 0 4px 0 rgba(#090909, .25);

			&:last-of-type {
				// border: 1px solid red;
				box-shadow: -4px 0 4px 0 rgba(#090909, .25);
				border-left: 1px solid Colors.$ob-brand-black;
			}
    }
  }

	&-header,
  & tr.p-rowgroup-header,
  &-thead tr {
    border-bottom: 2px solid Colors.$column-header-border;
  }

	&-thead tr {
		background-color: Colors.$column-header-background;
	}

	&-header {

		& > div {
			align-items: center;
			// background-color: Colors.$ob-brand-black;
			gap: Tools.asRem(Spacing.$gutter-small);
			flex-grow: 1;
			padding-left: Tools.asRem(Spacing.$gutter-small)
		}

	 	& label {	@extend %column-headings-primary; }
	}

	&-header,
  &:not(.header--micro) .p-datatable-thead > tr > th {
    height: Tools.asRem(Spacing.$table-header-height);
  }

  &.header--micro .p-datatable-thead > tr {

    border-top: 1px solid Colors.$full-black;

    & > th {
      font-size: Tools.asRem(14px);
      height: Tools.asRem(28px);
      font-weight: 400;
    }
  }

	& tr.p-rowgroup-header {

		padding-top: Tools.asRem(Spacing.$gutter-small);

		& > *:first-child {
			background-color: Colors.$datatable-header-background;
			border-color: Colors.$datatable-header-border;
			border-bottom-width: 0;
			border-left-width: 0;
			border-top-width: 1px;
			border-right-width: 1px;
			border-style: solid;
			border-top-right-radius: 3px;

			align-items: center;
			height: 100%;
			min-height: Tools.asRem(32px);
			padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-micro) Tools.asRem(36px);
			text-transform: uppercase;
			width: fit-content;

			left: 0;

			.p-row-toggler {
				background-color: transparent;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				justify-content: flex-start;
				padding-left: Tools.asRem(Spacing.$gutter-small);

				&:focus {
					outline: 0;
				}
			}
		}

		& span {
			text-wrap: nowrap;
		}
	}

	&-header,
  & tr.p-rowgroup-header,
  & th {

    color: Colors.$text-secondary;

    //	define font properties for either single headings or
    //	the top line of a doulble heading column
    & label,
    &:not(.header--double-row) {
      @extend %column-headings-primary;
    }

		& button { background: unset !important; }

    & summary {
      @extend %column-headings-secondary;
      color: Colors.$text-primary;
    }

    & * [data-sorting],
    & * [data-sorting] summary {
      color: Colors.$ob-brand-orange;
    }

    align-items: center;
    text-align: $tableCellContentAlignment;

    &:focus-visible {
			outline-offset: -2px;
			outline: 1px solid Colors.$text-primary
    }

    &:not(.no-border) {
			border-right: 1px solid Colors.$ob-brand-black;
    }

  }

	// &-header,
	& th {
		padding: 0 Tools.asRem(Spacing.$gutter-small);
		height: Tools.asRem(40px);
	}

  .p-sortable-column {

    &.sorting--server-side {

      & .p-sortable-column-icon { display: none; }
      & > div > span {
          display: inline-flex;
          justify-content: space-between;
          flex-grow: 1;
          align-items: center;

		  &.p-column-title {
			flex: 1 1 100%;
		  }
      }
    }

    .p-sortable-column-icon {
      transition: Animations.$animation-color;
      color: Colors.$text-secondary;
      margin-left: Tools.asRem(Spacing.$gutter-micro);
    }

        .p-sortable-column-badge {
            border-radius: 50%;
            height: $tableSortableColumnBadgeSize;
            min-width: $tableSortableColumnBadgeSize;
            line-height: $tableSortableColumnBadgeSize;
            color: $highlightTextColor;
            background: $highlightBg;
            margin-left: $inlineSpacing;
        }

        &:not(.p-highlight):not(.p-sortable-disabled):hover {


            .p-sortable-column-icon {
                color: Colors.$text-primary;
            }
        }

        &:has(* [data-sorting]),
        &:has(* [data-sorting]) i:before,
        &.p-highlight,
        &.p-highlight .p-sortable-column-icon {
            color: Colors.$ob-brand-orange !important;
        }

        &:has(* [data-sorting]),
        &.p-highlight {

            border-bottom: 1px solid Colors.$ob-brand-orange;

            &:not(.p-sortable-disabled):hover {
                .p-sortable-column-icon {
                }
            }
        }

        & .p-column-header-content {
            flex-grow: 1;
            justify-content: space-between;
        }


  }

  .p-datatable-tbody:not:has(.p-datatable-emptymessage) td {
    font-size: Tools.asRem(12.5px);
  }

  &:not(.row--no-border){
    tr:not(.p-rowgroup-header):not(:last-child) {
      border-bottom: 1px dashed Colors.$datatable-row-border;
    }
  }

  &:not(.row--narrow) tr { min-height: Tools.asRem(36px); }

	&-tbody {

		> tr:not(.p-rowgroup-header) {

			color: Colors.$text-primary;
			transition: $listItemTransition;

		> td {

			&:not(.contents--fill) {
				align-items: center;
			}

			&.contents--fill {
				height: 100%;
			}

			&:not(.no--padding):not(:has(var)) {
				padding: 0 Tools.asRem(Spacing.$gutter-small);
			}

			&:has(var) {
				& > div {
					height: 100%;
					align-items: center;
					gap: Tools.asRem(Spacing.$gutter-small);

					& > var {
						padding: 0 Tools.asRem(Spacing.$gutter-small);
						height: 100%;
						align-items: center;
						background-color: Colors.$ob-brand-black;
						color: Colors.$text-secondary;
						font-weight: 400;
						text-transform: uppercase;
						font-style: normal;
						font-size: Tools.asRem(11px);
					}
				}


			}

			& .upper {
					font-size: Tools.asRem(13px);
					line-height: Tools.asRem(13px);
			}

		@include Tools.unknownValue();

        & .upper:not(.unknown) {
          font-weight: 700;
        }

        & .lower {
            font-size: Tools.asRem(11px);
            line-height: Tools.asRem(14px);
            color: Colors.$text-secondary;
        }

          & span:not([class]) {
              // line-height: Tools.asRem(12px);
          }

          .p-row-toggler,
          .p-row-editor-init,
          .p-row-editor-save,
          .p-row-editor-cancel {
              // @include action-icon();
          }

          .p-row-editor-save {
              margin-right: $inlineSpacing;
          }

          > .p-column-title {
              font-weight: $tableHeaderCellFontWeight;
          }

          &.p-highlight {
							outline: Tools.asRem(3px) solid Colors.$ob-brand-orange;
							outline-offset: 0 - Tools.asRem(3px);
          }
      }

			& .p-frozen-column {
				background-color: Colors.$panel-background;
				align-items: flex-start;
				border-right: 1px solid Colors.$ob-brand-black;
				z-index: 1;
				height: 100%;

				&.no-border {
					border-right: none;
				}
			}

			&.p-selected-row,
			&.p-selected-row .p-frozen-column,
			&.p-highlight:not(:has([role="checkbox"],[checked="true"])) .p-frozen-column,
			&.p-highlight:not(:has([role="checkbox"],[checked="true"])) {
				// border: 1px solid red;
				background-color: Colors.$datatable-row-selected-background;
				color: Colors.$text-primary;

				td {
					> *,
					span,
					time,
					span.lower {
						color: Colors.$text-primary;
						opacity: 1;
					}

					.p-checkbox:not(:hover) {
						border-color: Colors.$text-primary;
					}
				}
			}

    }


  }

	&-emptymessage {

			font-size: Tools.asRem(13px);
			padding: Tools.asRem(Spacing.$gutter-small) 0;

			& td {
				grid-area: 1/1/1/6;

				& h2 { color: Colors.$text-secondary }

				& > div {
						//  child elements of the emptymessage container need
						//  to be dispaly:block or we get weird whitepacing
						//  issues with em/i text rendering
						display: block;
				}
			}
	}

	&.p-datatable-selectable-cell {
			.p-datatable-tbody > tr.p-selectable-row {
					& > td.p-selectable-cell {
							&:not(.p-highlight):hover {
									background: $tableBodyRowHoverBg;
									color: $tableBodyRowTextHoverColor;
							}

							&:focus {
									outline-offset: -.15rem;
							}
					}
			}
	}

	.p-column-resizer-helper {
			background: $tableResizerHelperBg;
	}

	.p-datatable-scrollable-header,
	.p-datatable-scrollable-footer {
			background: $panelHeaderBg;
	}


	&.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table,
	&.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table {
			> .p-datatable-thead,
			> .p-datatable-tfoot {
					z-index: 3
			}
	}

	.p-datatable-loading-icon {
			font-size: $loadingIconFontSize;
	}

	&.p-datatable-gridlines {
			.p-datatable-header {
					border-width: 1px 1px 0 1px;
			}

			.p-datatable-footer {
					border-width: 0 1px 1px 1px;
			}

			.p-paginator-top {
					border-width: 0 1px 0 1px;
			}

			.p-paginator-bottom {
					border-width: 0 1px 1px 1px;
			}

			.p-datatable-thead {
					> tr {
							> th {
									border-width: 1px 0 1px 1px;

									&:last-child {
											border-width: 1px;
									}
							}
					}
			}

			.p-datatable-tbody {
					> tr {
							> td {
									border-width: 1px 0 0 1px;

									&:last-child {
											border-width: 1px 1px 0 1px;
									}
							}

							&:last-child {
									> td {
											border-width: 1px 0 1px 1px;

											&:last-child {
													border-width: 1px;
											}
									}
							}
					}
			}


			.p-datatable-thead + .p-datatable-tfoot {
					> tr {
							> td {
									border-width: 0 0 1px 1px;

									&:last-child {
											border-width: 0 1px 1px 1px;
									}
							}
					}
			}

			&:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody {
					> tr {
							> td {
									border-width: 0 0 1px 1px;

									&:last-child {
											border-width: 0 1px 1px 1px;
									}
							}
					}
			}

			&:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody {
					> tr {
							&:last-child {
									> td {
											border-width: 0 0 0 1px;

											&:last-child {
													border-width: 0 1px 0 1px;
											}
									}
							}
					}
			}
	}

	&.p-datatable-striped {
			.p-datatable-tbody {
					> tr.p-row-odd {
							background: $tableBodyRowEvenBg;

							&.p-highlight {
									background: $highlightBg;
									color: $highlightTextColor;

									.p-row-toggler {
											color: $highlightTextColor;

											&:hover {
													color: $highlightTextColor;
											}
									}
							}

							& + .p-row-expanded {
									background: $tableBodyRowEvenBg;
							}
					}
			}
	}

	&.p-datatable-sm {
			.p-datatable-header {
					@include scaledPadding($tableHeaderPadding, $tableScaleSM);
			}

			.p-datatable-thead > tr > th {
					@include scaledPadding($tableHeaderCellPadding, $tableScaleSM);
			}

			.p-datatable-tbody > tr > td {
					@include scaledPadding($tableBodyCellPadding, $tableScaleSM);
			}

			.p-datatable-tfoot > tr > td {
					@include scaledPadding($tableFooterPadding, $tableScaleSM);
			}

			.p-datatable-footer {
					@include scaledPadding($tableFooterPadding, $tableScaleSM);
			}
	}

	&.p-datatable-lg {
			.p-datatable-header {
					@include scaledPadding($tableHeaderPadding, $tableScaleLG);
			}

			.p-datatable-thead > tr > th {
					@include scaledPadding($tableHeaderCellPadding, $tableScaleLG);
			}

			.p-datatable-tbody > tr > td {
					@include scaledPadding($tableBodyCellPadding, $tableScaleLG);
			}

			.p-datatable-tfoot > tr > td {
					@include scaledPadding($tableFooterPadding, $tableScaleLG);
			}

			.p-datatable-footer {
					@include scaledPadding($tableFooterPadding, $tableScaleLG);
			}
	}

	&-loading-overlay {
		position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4 !important;
    height: 2px;
		background-color: transparent;
		top: 40px;

		& > svg { display: none; }


		&:before {
			content: '';
			position:absolute;
			border-radius:10px;
			top:0;
			right:100%;
			bottom:0;
			left:0;
			background: Colors.$grass-60;
			width:0;
			animation:borealisBar 2s linear infinite;
		}
	}

	&.grouping--no-footer {
		& tr.p-rowgroup-footer { display: none;}
	}
}

.p-datatable-drag-selection-helper {
    background: $tableDragHelperBg;
}

.p-rowgroup-header {
	background-color: Colors.$panel-background;
  font-size: Tools.asRem(14px);
  font-weight: 600;
	z-index: 2 !important;

  & > td {
    gap: Tools.asRem(Spacing.$gutter-small);
		position: sticky;

		& svg path {
			fill: Colors.$text-secondary;
		}

		grid-area: 1/1/1/var(--columns);
  }
}

// tr,
.p-selectable-row {
	&:not(.p-highlight) {
		&:has(div.p-checkbox-focused),
		&[tabindex="0"],
		&:hover {
			// outline: 1px solid Colors.$ob-brand-orange;
			outline-offset: -1px;
		}
	}
}

.row--no-hover-state tr { outline-width: 0 !important; }

//	Previous sibling selection so any selected row should ensure the previous row
//	doesn't have a bottom border
[role="row"]:has(+ .p-selected-row),
[role="row"]:has(+ [tabindex="0"]) {
	border-bottom: 1px solid transparent !important;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  75% {
    left:60%;
    right:30%;
    width:60%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}
