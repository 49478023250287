@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.p-metergroup {
  &-horizontal { flex-direction: column; }
  &-vertical { flex-direction: row; }

  &-label-list {
    flex-wrap: wrap;
  }

  &-size-micro {
    $size: Tools.asRem(Spacing.$gutter-micro);
    $list-mark-size: Tools.asRem(Spacing.$gutter-medium);
    
    gap: Tools.asRem(Spacing.$gutter-medium);
  
    .p-metergroup-meter-container {
      height: $size;
      border-radius: calc($size / 2);
      overflow: hidden;
    }
  
    .p-metergroup-label-list {
      gap: Tools.asRem(Spacing.$gutter-large)
    }
  
    .p-metergroup-label-list-item {
      gap: Tools.asRem(Spacing.$gutter-small);
    }
  
    .p-metergroup-label-type {
      height: $list-mark-size;
      width: $list-mark-size;
      border-radius: calc($list-mark-size / 2);
    }
  }

  &-hidden-legend {
    .p-metergroup-label-list {
      display: none;
    }
  }
}
