@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.surveillance-search-container,
.surveillance-container .surveillance-container-main header > div.surveillance-search-container {
  display: flex;

  flex-direction: row;
  gap: Tools.asRem(Spacing.$gutter-small);
  align-items: flex-start;
  min-height: Tools.asRem(36px);
  height: auto;

  &-additional-filters {
    height: Tools.asRem(36px);

    .p-panel-content {
      .additional-filters__container--internal {
        grid-template-columns: 0.8fr minmax(145px, 0.8fr) minmax(105px, 1fr);

        &.p-disabled {
          opacity: 0.36;
        }
      }
    }
  }

  .search-container {
    display: flex;
    flex-grow: 1;
  }

  .surveillance-search-input .p-autocomplete-multiple-container,
  .surveillance-search-date {
    background: Colors.$input-background-color;

    input::placeholder {
      color: Colors.$text-placeholder;
      font-style: italic;
    }
  }

  .autocomplete-input {
    min-width: Tools.asRem(50px);
  }

  .expand {
    border: 0;
  }

  .surveillance-clear {
    height: Tools.asRem(36px);
    min-width: Tools.asRem(62px);
  }

  .form-input__container {
    max-height: max-content;
    min-width: Tools.asRem(160px);
  }

  .item-template .item-template-label,
  .item-template--content .item-template-label {
    width: Tools.asRem(370px);
    word-wrap: break-word;
    white-space: normal;
  }
}