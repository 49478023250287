@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;

.container {

	position: relative;
	width: fit-content;

	& > button > span::before {
			color: Colors.$text-secondary;
	}   
}

.badge {
	position: absolute;
	right: Tools.asRem(Spacing.$gutter-medium);
	top: Tools.asRem(Spacing.$gutter-micro);
	border-radius: 50%;
	padding: Tools.asRem(Spacing.$gutter-micro);

	background-color: Colors.$ob-brand-red;
	transition: background-color ;

	
	&.processing {
		background-color: Colors.$slate;
	}
}
.subscribed {
		background-color: Colors.$grass-80;
}