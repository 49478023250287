@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;

$play-pause-btn-icon: Tools.asRem(18px);

.play-pause-btn {
  align-items: center;
  justify-content: center;
  padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);

  cursor: pointer;
  background-color: Colors.$full-black;
  border-radius: Tools.asRem(Spacing.$gutter-micro);

  &-icon {
    height: $play-pause-btn-icon;
    width: $play-pause-btn-icon;
    color: Colors.$text-secondary;

    input[type="checkbox"] {
      position: absolute;
      left: -9999px;
    }

    &:hover {
      color: Colors.$grass-60;
    }

    &::before {
      mask-image: url('icons/play.svg');
      -webkit-mask-image: url('icons/play.svg');
    }

    &:has(input[type="checkbox"]:checked)::before {
      mask-image: url('icons/pause.svg');
      -webkit-mask-image: url('icons/pause.svg');
    }

    &.icon-show::before {
      display: block;
      width: auto;
      height: auto;
      aspect-ratio: 1 / 1;
      content: '';
      background: currentColor;
      mask-size: cover;
      -webkit-mask-size: cover;
    }
  }
}
