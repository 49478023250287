@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

.container {
  width: 100%;
  flex-direction: column;
}

.content {
  flex: 1;
  flex-direction: column;
  position: relative;
}

.recordDetailsContainer {
  & dt {
    align-content: center;
    word-break: break-word;
    font-family: OpenSans, sans-serif;
    font-weight: 700;
    font-size: Tools.asRem(11px);
    line-height: Tools.asRem(15px);
    color: Colors.$text-secondary;
    text-transform: uppercase;
  }
  & dd {
    align-content: center;
    word-break: break-word;
    padding-bottom: Tools.asRem(Spacing.$gutter-small);
  }

  &Counterpart {
    flex-direction: column;
    padding-bottom: Tools.asRem(Spacing.$gutter-small);

    & dd {
      padding-bottom: 0;
    }

    & div {
      gap: Tools.asRem(Spacing.$gutter-small);

      & > .lineParticipants {
        flex-direction: column;

        &LeftCol {
          width: Tools.asRem(76px);
          align-content: normal;
          color: Colors.$text-secondary;
        }
        &LeftColUser {
          width: Tools.asRem(50px);
          align-content: normal;
          color: Colors.$text-secondary;
        }
        &RightCol {
          align-content: normal;
        }
      }
    }
  }
}
