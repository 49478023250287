@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.container {

	background-color: Colors.$full-grey;

	& span { 
		align-items: flex-end;
		display: flex;
		font-size: Tools.asRem(9px);
		min-width: Tools.asRem(32px);
		margin-left: Tools.asRem(Spacing.$gutter-small);
	}
	
	& input { 
		background-color: transparent;
		border: 0;
		width: 100%;
	}
}

.errorState {
	
	&:has(input:focus) {
		// border: 1px solid Colors.$text-primary;
		border-color: Colors.$ob-brand-red;
		
		& span { color: Colors.$ob-brand-red; }
	}
	
}

.asTabEditor { 
	height: Tools.asRem(32px); 
	max-width: Tools.asRem(120px);
	
	& span { padding-bottom: Tools.asRem(Spacing.$gutter-small); }
}
.asCellEditor { 
	height: Tools.asRem(28px); 
	width: 100%;

	& span { padding-bottom: Tools.asRem(Spacing.$gutter-micro); }
}