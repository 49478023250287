@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.chart-dropdown-actions {
  > button {
    color: Colors.$text-secondary;
    background-color: Colors.$text-only-button-background;
  }

  &:hover > button {
    color: Colors.$ob-brand-orange;
  }

  .p-button-label {
    font-size: Tools.asRem(14px);
    font-weight: 700;

    * {
      align-content: center;
    }

    i {
      padding-left: Tools.asRem(Spacing.$gutter-small);
    }
  }

  .p-splitbutton-menubutton.p-button-icon-only {
    display: none;
  }

  .p-button:not(.p-button--tab-style, .p-button--vertical-tab) {
    border: none;
    background-color: Colors.$full-black;
  }

  &-menu {
    margin-top: Tools.asRem(2px);

    ul {
      width: 100%;

      &:not(p-dropdown-items) {
        margin: Tools.asRem(Spacing.$gutter-small) 0;
      }

      .p-menuitem-link {
        width: 100%;
        padding: Tools.asRem(Spacing.$gutter-small);

        > span {
          align-items: center;
        }

        .p-menuitem-icon {
          margin: 0;
          min-width: unset;
        }
    
        .p-menuitem-icon,
        .p-menuitem-text {
          color: Colors.$text-secondary;
        }
      }
    }
  }
}
