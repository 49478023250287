@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.userAutocompleteInvalidEmails {
  flex: 1;
  flex-direction: column;

  padding: Tools.asRem(Spacing.$gutter-medium);
  gap: Tools.asRem(Spacing.$gutter-small);

  border: Tools.asRem(1px) solid Colors.$tomato;
  border-radius: Tools.asRem(3px);

  &Header {
    gap: Tools.asRem(Spacing.$gutter-small);

    > *[class^='iconoir-']::before {
      background-color: Colors.$tomato;
    }
  }
}

.suggestionItem {
  & div {
    flex: 1;
  }
}
