@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;

.distlist-header,
.module__canvas:has(> header) > header.distlist-header {
  align-items: flex-start;

  .p-button:not(.expand),
  .p-button-sm:not(.expand) {
    line-height: Tools.asRem(24px);
    height: Tools.asRem(36px);
    white-space: nowrap;
  }

  button.expand,
  button.expand:hover,
  .expand.p-button.p-button-text:enabled:hover {
    border: Tools.asRem(2px) solid transparent;
    padding: Tools.asRem(2px) Tools.asRem(Spacing.$gutter-small);
  }

  * {
    transition: none;
  }

  .search-container ul:not(.p-dropdown-items):not(.p-autocomplete-items):not(.p-tabview-nav) {
    margin-left: 0;
  }

  .form-input__container {
    max-width: Tools.asRem(160px);
    max-height: unset;
  }

  .p-autocomplete-panel.autocomplete-entity .p-autocomplete-items {
    .item-template-label,
    .grouped-item-template {
      line-height: Tools.asRem(16px);
    }
  }

  .p-autocomplete-panel.autocomplete-entity.autocomplete-entity-grouped:not(.empty) .p-autocomplete-item {
    padding-left: Tools.asRem(148px);
  }

  @media (max-width: 960px) {

    .single-search-mobile {
      &__accordion {
        flex-direction: column;
        flex: 1 1 auto;
        padding: 0 !important; // override global (_accordion.scss)
        width: 100%;

        > div {
          flex-direction: column;
        }
      }

      &__accordion-header {
        .p-accordion-header-link {
          border: 0;

          &:focus {
            outline: none;
          }

          .p-accordion-header-text {
            font-size: Tools.asRem(16px);
            margin: 0 Tools.asRem(Spacing.$gutter-small);
          }

          .p-icon {
            margin: 0 Tools.asRem(Spacing.$gutter-micro);
          }

          .p-accordion-header-text, .p-icon {
            color: Colors.$text-primary;
            text-align: left;
          }
        }

        svg {
          width: Tools.asRem(16px);
          height: Tools.asRem(16px);
        }
      }

      &__accordion-tab {
        flex-direction: column;
        flex: 1 1 auto;
        border: 0;

        .p-accordion-content {
          overflow: visible;
        }
      }

      &__container {
        width: 100%;
        height: auto;
        z-index: 2;
        background: Colors.$charcoal;
        border-top: 1px solid Colors.$full-black;
        display: flex;
        flex-direction: column;
        gap: Tools.asRem(Spacing.$gutter-small);
        align-items: stretch;
        padding: Tools.asRem(Spacing.$gutter-small);

        .form-input__container {
          width: auto;
          max-width: unset;
        }

        .search-container {
          flex-grow: initial;

          .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
            font-size: Tools.asRem(13px);
            line-height: Tools.asRem(18px);
          }
        }

        input {
          font-size: Tools.asRem(13px);
          line-height: Tools.asRem(18px);
        }

        .p-autocomplete-multiple-container {
          overflow-x: hidden;
          margin-left: 0;
        }

        .p-button {
          justify-content: center;
        }
      }
    }
  }
}