@use "src/design-system/tools" as Tools;

.editingMode {
	flex-shrink: 0;
	width: Tools.asRem(300px);

	> div {
		flex-grow: 1;
		max-width: unset; // override LimitedLengthInput when editing
	}
}