@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/animations" as Animations;


.bar {

	// position: relative;
	
	&:before {
		height: 2px;
		content: '';
		position:absolute;
		border-radius:10px;
		top:0;
		right:100%;
		bottom:0;
		left:0;
		background: Colors.$grass-60; 
		width:0;
		animation:loop 2s linear infinite;
	}
}

@keyframes loop {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  75% {
    left:60%;
    right:30%;
    width:60%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}
