@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.p-menu {
    // padding: $verticalMenuPadding;
    background: $menuBg;
    color: $menuTextColor;
    border: $menuBorder;
    border-radius: $borderRadius;
    width: $menuWidth;

    & ul {
        flex-grow: 1;
    }

    &.p-menu-overlay {
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;
        color: $submenuHeaderTextColor;
        background: $submenuHeaderBg;
        font-weight: $submenuHeaderFontWeight;
        border-top-right-radius: $submenuHeaderBorderRadius; 
        border-top-left-radius: $submenuHeaderBorderRadius; 
    }

    .p-menu-separator {
        border-top: $divider;
        margin: $menuSeparatorMargin;
    }

    .p-menuitem button { 
			// margin: Spacing.$gutter-small;
			font-size: Tools.asRem(10px);
    }

    .p-menuitem {
        &:hover {
            background: Colors.$slate-90;

            .p-menuitem-content,
            .p-menuitem-icon,
            .p-menuitem-text,
            .p-submenu-icon {
                color: Colors.$ob-brand-black;
            }
        }
    }
}
