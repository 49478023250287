@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.workflow-empty-page {
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  gap: 2 * Tools.asRem(Spacing.$gutter-large);
  color: Colors.$text-secondary;

  > div {
    align-items: center;
    flex-direction: column;
    gap: Tools.asRem(Spacing.$gutter-large);

    span {
      font-size: Tools.asRem(18px);
      font-family: Panton, sans-serif;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
    }

    i::before {
      width: Tools.asRem(150px);
      height: Tools.asRem(150px);
    }
  }
}