@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

.sanction-search-history {
  $headerHeight: Tools.asRem(30px);
  $summaryHeight: Tools.asRem(57px);

  &__summary {
    padding: Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-secondary;
    height: $summaryHeight;

    div {
      font-size: Tools.asRem(11px);
      font-weight: 700;
      line-height: Tools.asRem(15px);
      text-transform: uppercase;
    }

    > :nth-child(2) {
      color: Colors.$text-primary;
      margin-right: Tools.asRem(Spacing.$gutter-small);
    }
  }

  &__datatable {
    padding-bottom: Tools.asRem(Spacing.$gutter-small);

    &-row {
      grid-template-columns: 1fr 1fr Tools.asRem(32px);
      position: sticky;
      top: $headerHeight;
      z-index: 4;
      background-color: Colors.$panel-background-color;

      .iconoir-binocular {
        cursor: pointer;

        &:hover {
          color: Colors.$ob-brand-orange;
        }
      }

      &:has(+ .p-datatable-row-expansion) {
        border-bottom: none !important;
        box-sizing: content-box;

        &, & + .p-datatable-row-expansion {
          padding-bottom: Tools.asRem(Spacing.$gutter-small);
        }
      }
    }

    thead tr, // search history table header row
    header { // header of definition list of Snapshot
      min-height: unset !important; // override `.module__canvas main > aside header`
      height: $headerHeight;
    }

    &.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead { // override _datatable.scss
      z-index: 4; // display Search history table header above Snapshot table header
    }

    &.p-datatable-scrollable {
      height: calc(100% - $summaryHeight); // 100% - summary height
    }

    .sanctions-table--detail tr {
      // make the snapshot table a bit less narrow to fit the side-panel width
      grid-template-columns:
        minmax(Tools.asRem(144px), 1fr)
        minmax(Tools.asRem(116px), .226fr);
    }
  }
}