@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.p-datatable-header label {
	padding-left: Tools.asRem(Spacing.$gutter-small);
}

.workflow-page {
    overflow: auto;
    overflow-x: hidden;
    flex-grow: 1;
    gap: Tools.asRem(8px);

    .add-workflow__button-container {
        justify-content: flex-end;

        .add-workflow__button {
            margin: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-medium);
        }
    }
    

    header {
      display: flex;
      gap: Tools.asRem(Spacing.$gutter-medium);
      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.16);
      background-color: Colors.$charcoal;
      border-bottom: 1px solid Colors.$full-black;
      padding: Tools.asRem(Spacing.$gutter-large);
    
      &  .header__search-block {
        gap: Tools.asRem(Spacing.$gutter-small);
        flex: 1;
        padding: unset;
        padding-bottom: unset !important // TODO - clean up around the _acordian stlying potentially needed here
      }

      & > div:not(:first-child) {
        padding-left: Tools.asRem(Spacing.$gutter-medium);
        @include Tools.dotted-border($edge: left);
      }

        .workflow__additional-filter {
            .filter-item {
                gap: Tools.asRem(Spacing.$gutter-small);
                align-items: center;

                small {
                    font-weight: 700;
                    font-size: Tools.asRem(13px);
                }
            }

            .p-panel {
                width: Tools.asRem(300px);

                .p-panel-content {
                    width: 100%;
                }
            }
        }
    }

    & > header + div { 

      @include Tools.dotted-border($edge: bottom);
      padding-bottom: Tools.asRem(Spacing.$gutter-medium);
      gap: Tools.asRem(Spacing.$gutter-small);
    }

    header + div:has(.p-dropdown) {
      min-width: Tools.asRem(196px);

      & > div { flex: 1 }
    }


    & > header + div,
    & > nav {

      flex-direction: row;
      align-items: center;

      @media only screen and (min-width: 960px) {
        & label { display: none; }
      }

      @media only screen and (max-width: 960px) {
        padding: 0 Tools.asRem(Spacing.$gutter-medium)
      }

      & label {
        min-width: 11ch;
      }
    }

    .workflow-page__main {
        margin: 0 Tools.asRem(Spacing.$gutter-medium);

        .p-datatable {
            flex-grow: 1;

            tr:not(.p-datatable-emptymessage) { cursor: pointer; }
        }
    }

    aside {
        header,
        footer:not(.no-background) {
            box-shadow: none;
        }

        nav {
            background-color: Colors.$page-header-background;
            padding-bottom: 0;

            .p-button--tab-style {
                padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
            }
        }
    }

    .close-button {
        @include Tools.top-right-element;
    }

    @media only screen and (max-width: 960px) {
        .add-workflow__button-container {
            position: sticky;
            bottom: 0;
            z-index: 1;
            order: 1;
            border-top: 1px solid Colors.$full-black;
            background-color: Colors.$panel-background-color;
            padding: Tools.asRem(Spacing.$gutter-small);
            justify-content: center;

            .add-workflow__button {
                width: 100%;
                margin: 0;
                justify-content: center;
                height: Tools.asRem(34px);
            }
        }

        header {
            padding: 0;
            border: none;
            flex-direction: column;

            .workflows-page__back-button {
                background-color: Colors.$panel-background-color;
                font-weight: 200;
                font-family: OpenSans, sans-serif;
                padding: Tools.asRem(6px);
            }

            .p-accordion-tab {
                flex-grow: 1;
                flex-direction: column;

                .p-accordion-header-text {
                    color: Colors.$text-primary;
                }

                .p-accordion-content {
                    flex-grow: 1;
                    padding: Tools.asRem(Spacing.$gutter-small);
                    gap: Tools.asRem(Spacing.$gutter-small);
                    overflow: visible;

                    .additional-filters__container {
                        padding: 0;

                        .p-panel {
                            position: absolute;
                            // 100vw - accordion-content paddings
                            width: calc(100vw - 2 * Tools.asRem(Spacing.$gutter-small));

                            .p-panel-content {
                                padding: Tools.asRem(Spacing.$gutter-small);
                                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.56);
                            }
                        }

                        .additional-filters__button-toggle,
                        .additional-filters__overlay-element {
                            display: inherit;
                        }

                        .additional-filters__button-toggle {
                            width: Tools.asRem(42px);
                        }
                    }
                }
            }
        }


        .workflow-page__main {
            gap: unset;
            overflow: unset;
            margin: 0;

            &.drawer--active {
                overflow: hidden;
                margin-top: 0;
            }

            section {
                .workflows-page__data-view {
                    padding: 0 Tools.asRem(Spacing.$gutter-small);
                    background: Colors.$ob-brand-black;

                    .p-dataview-content {
                        flex-grow: 1;
                        background: unset;
                        padding: 0;

                        .p-grid {
                            flex-grow: 1;
                            flex-direction: column;
                            gap: Tools.asRem(Spacing.$gutter-small);

                            > div {
                                border: none;
                            }
                        }
                    }
                }
            }

            aside {
                .p-datatable-footer {
                    position: sticky;
                    bottom: 0;
                    z-index: 3;
                }
            }
        }
    }
}

$table-reflow-break-point: 648px;

@container main-body (max-width: #{$table-reflow-break-point}) {
  .workflow-page__main .p-datatable {
    
    & thead { display: none;}
    
    & tbody tr {
      padding: Tools.asRem(Spacing.$gutter-small) 0;
      grid-template-columns: .88fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: Tools.asRem(Spacing.$gutter-small);
  
      & td:first-child { grid-area: 1 / 1 / 1 / 1 ; }
      & td:nth-child(2) { grid-area: 2 / 1 / 2 / 3; }
      & td:nth-child(3) { grid-area: 3 / 1 / 3 / 3; }
      & td:nth-child(4) { grid-area: 1 / 2 / 1 / 4; }
      & td:nth-child(5) { grid-area: 2 / 3 / 2 / 3; }
      
      & td:nth-child(5),
      & td:nth-child(3) { display: none; }
    }
    
  }
}

@container main-body (min-width: #{$table-reflow-break-point}) {

  .p-datatable-header { display: none; }

  .workflow-page__main .p-datatable tr:not(.p-datatable-emptymessage) {
    grid-template-columns:
      minmax(135px, 3fr) // title
      minmax(136px, 1fr) // progress
      minmax(136px, 1fr) // requested
      minmax(136px, 1fr) // updated
      Tools.asRem(42px); // actions
  }
}

.p-dialog {
    &.delete-workflow__dialog {
        min-width: Tools.asRem(453px);

        .p-dialog-content {
            padding-top: Tools.asRem(Spacing.$gutter-small);
            gap: unset;
        }
    }
}

.p-workflow-sorting-menu {
  width: unset;
  
  .p-menuitem button {
    font-size: Tools.asRem(14px);
  }

  ul:not(.p-dropdown-items) {
    margin: 0;
  }

  &-footer {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
    justify-content: flex-end;

    .p-button.p-button-text {
        color: Colors.$ob-brand-orange;
    }
  }
}

.p-workflow-sorting-item {
  @include Tools.dotted-border($edge: bottom);

  padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
  gap: Tools.asRem(Spacing.$gutter-large);

  align-items: center;

  & button {
    margin: 0;
  }

  & span {
    flex-grow: 1;
  }
}

.p-workflow-progressbar {
    width: 100%;
}
