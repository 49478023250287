@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

$panelWidth: 336px;

// TODO: to be updated with story #225
.dc-autocomplete {

    &.p-autocomplete-panel {
        // Autocomplete has some alignOverlay method, which updates styles so we must override it
        // https://github.com/primefaces/primereact/blob/master/components/lib/autocomplete/AutoComplete.js#L206
        @media only screen and (min-width: 960px) {
            // width: Tools.asRem($panelWidth);
            min-width: clamp(Tools.asRem(336px), 45dvw, 70dvw) !important;
            // max-width: Tools.asRem($panelWidth);
            left: 0 !important;
            top: 25px !important;
        }
    }

    &.empty {
        font-style: italic;

        .p-autocomplete-items {
            width: 100%;
        }
    }
}

.search-container {
    flex-grow: 1;

    .expand {
        // margin-right: Tools.asRem(Spacing.$gutter-small);
        width: Tools.asRem(42px);
        // height: Tools.asRem(34px);
        // border: 0;
        // background: Colors.$full-grey;

        &:hover,
        &.p-button.p-button-text:enabled:hover {
            color: Colors.$text-secondary;
            border: 0;

            .p-button-icon::before {
                color: Colors.$text-secondary;
            }
        }

        &:focus {
            outline: 0;
        }

        *[class*=iconoir-]::before {
            color: Colors.$text-secondary;
            width: Tools.asRem(18px);
            height: Tools.asRem(18px);
        }
    }

    .autocomplete-container .p-inputtext {
        height: auto;
        min-height: 34px;
    }

    .p-autocomplete-multiple-container,
    .p-autocomplete-input-token {
        overflow: visible;
        position: relative;
    }

    .p-autocomplete-multiple-container {
        flex-wrap: nowrap;
    }

    .p-autocomplete-token.hidden-token {
        display: none;
    }

    &.search-container-expanded {
        .p-autocomplete .p-autocomplete-multiple-container {
            flex-wrap: wrap;
            row-gap: Tools.asRem(Spacing.$gutter-small);
        }

        .p-autocomplete-token.hidden-token {
            display: inline-flex;
        }
    }
}

.autocomplete-container {
    &, ul, .autocomplete-input {
        width: 100%;
        height: 100%;
    }

    .dropdown-container {
        position: relative;
    }

    .chip-counter {
        min-width: Tools.asRem(70px);
        cursor: help;
        font-size: Tools.asRem(13px);
    }

    .autocomplete-input {
        color: Colors.$text-secondary;

        @media only screen and (min-width: 960px) {
            // min-width: Tools.asRem($panelWidth);
        }

        &::placeholder,
        &:placeholder-shown {
            // color: Colors.$text-secondary;
            overflow: hidden;
            text-overflow: ellipsis;
            // font-style: normal;
        }
    }

    .p-inputtext {
        height: Tools.asRem(34px);
        outline: none;
        border-radius: Tools.asRem(3px);
        padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
        padding-left: Tools.asRem(2 * Spacing.$gutter-large);

        &:before {
            // Taken from .pi .pi-search classes
            content: "\e908";
            font-family: primeicons, sans-serif;
            speak: none;
            font-style: normal;
            font-size: Tools.asRem(15px);
            font-weight: 600;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            left: Tools.asRem(Spacing.$gutter-small);
            top: Tools.asRem(Spacing.$gutter-small);
        }
    }

    .p-autocomplete-multiple-container:not(.p-disabled):hover,
    .p-autocomplete-multiple-container:not(.p-disabled):focus-visible,
    .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
        border-color: Colors.$text-primary;
        outline: none;
    }
}
