@use "src/design-system/tools" as Tools;

.sanctions-table--entity {

    & tr:not(.p-datatable-emptymessage) {


        @media only screen and (max-width: 960px) {
            grid-template-columns:
                minmax(Tools.asRem(156px), Tools.asRem(224px))  // Company Name
                minmax(Tools.asRem(124px), .46fr)               // Registration Nat.
                minmax(Tools.asRem(115px), .4fr)                // Designated Date
                minmax(Tools.asRem(105px), .4fr)                // Created On
                minmax(Tools.asRem(98px), .226fr)               // Risk Level
                Tools.asRem(84px);                              // `Check` button

            .column__ {
                &country { display: none; }
            }
        }

        @media only screen and (min-width: 960px) {
            grid-template-columns:
                minmax(Tools.asRem(132px), Tools.asRem(224px))  // Country Name
                minmax(Tools.asRem(198px), .66fr)               // Country
                minmax(Tools.asRem(164px), .66fr)               // Registration Nat.
                minmax(Tools.asRem(115px), .2fr)                // Designated Date
                minmax(Tools.asRem(115px), .2fr)                // Created On
                Tools.asRem(68px)                               // Source
                minmax(Tools.asRem(112px), .226fr)              // Risk Level
                Tools.asRem(84px);                              // `Check` button
        }
    }
}