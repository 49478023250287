@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.leg-footer {
    &__container {
        gap: Tools.asRem(Spacing.$gutter-small);
        padding: Tools.asRem(Spacing.$gutter-small);
        border-radius: 2px;
        background: Colors.$charcoal;
        text-transform: uppercase;

        label {
            color: Colors.$text-secondary;

            font-size: Tools.asRem(11px);
            line-height: Tools.asRem(15px);
            font-weight: 700;
        }
    }

    &__distance,
    &__time {
        flex: 0 1 auto;
        flex-direction: column;

        font-size: Tools.asRem(13px);
        line-height: Tools.asRem(18px);
    }

    
}