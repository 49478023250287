@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: Tools.asRem(Spacing.$gutter-large);
  flex: 1;
  align-items: center;
  justify-items: end;

  :global {
    .not-available__container {
      color: Colors.$text-secondary;
      font-style: italic;
    }
  }
}

.addon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 Tools.asRem(Spacing.$gutter-small);
  height: Tools.asRem(30px);
  color: Colors.$text-secondary;
  background: Colors.$ob-brand-black;
  font-family: OpenSans, sans-serif;
  font-weight: 700;
  font-size: Tools.asRem(13px);
}
