@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;

.sanctions-table--individual {
    .p-datatable-tbody  {
        tr {
            td:not(.contents--fill) {
                // flex-direction: column;
                align-items: center;
                gap: Spacing.$gutter-micro;

                .visible-items {
                    flex: 0 1 auto;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                }

                .hidden-items {
                    white-space: nowrap;
                    color: Colors.$text-secondary;
                }
            }
        }
    }

    & tr:not(.p-datatable-emptymessage) {
        grid-template-columns:
            minmax(Tools.asRem(156px), .4fr)    // Names
            minmax(Tools.asRem(156px), .3fr)    // Aliases
            minmax(Tools.asRem(120px), .2fr)    // Nationality
            minmax(Tools.asRem(120px), .2fr)    // DoB
            Tools.asRem(76px)                   // Source
            minmax(Tools.asRem(115px), .15fr)   // Designated Date
            minmax(Tools.asRem(115px), .15fr)   // Created On
            Tools.asRem(84px);                  // `Check` button

        @media only screen and (max-width: 960px) {
            grid-template-columns:
                minmax(Tools.asRem(140px), .6fr)    // Names
                minmax(Tools.asRem(100px), .4fr)    // Nationality
                Tools.asRem(76px)                   // Source
                minmax(Tools.asRem(115px), .4fr)    // Designated Date
                minmax(Tools.asRem(115px), .4fr)    // Created On
                Tools.asRem(84px);                  // `Check` button
        }
    }
}