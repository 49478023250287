@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.ribbon {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &__container {
        position: relative;
    }

    &__close-icon {
        position: absolute;
        right: 0;
        height: 100%;
        padding: Tools.asRem(Spacing.$gutter-medium);
        align-items: flex-start;
        color: Colors.$text-secondary;
        cursor: pointer;
    }

    .p-inline-message-text {
        padding: Tools.asRem(Spacing.$gutter-small);
        padding-left: Tools.asRem(Spacing.$gutter-medium);
        padding-right: Tools.asRem(calc(2 * Spacing.$gutter-medium + 24px)); // 2 * icon padding + medium icon width
    }

    .p-inline-message-icon {
        flex-shrink: 0;
        margin: 0;
    }
}