@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/fonts' as Text;

.candlestick-chart {
	&__footer {
		@include Text.table-footer;
		color: Colors.$text-secondary;
		justify-content: flex-start;
		padding: Tools.asRem(Spacing.$gutter-small) 0;

		span {
			color: Colors.$text-primary;
		}
	}

    &__tabbed-nav,
    &__dropdown {
        .p-button--tab-style {
            padding: Tools.asRem(Spacing.$gutter-small);
        }
    }

    &__dropdown-period--container {
      gap: Tools.asRem(Spacing.$gutter-small);

      .p-dropdown {
        flex-grow: 1;
      }
    }

    &__dropdown--panel {
        .p-dropdown-item {
            padding-right: Tools.asRem(Spacing.$gutter-large);
            width: 100%;
            display: grid;
            grid-template-columns: minmax(20%, 100px) auto;
            gap: Tools.asRem(Spacing.$gutter-medium);
        }
    }

    &__loader-container {
        height: 50%;
        position: relative;
    }

    &__settings {
        .form-input__container {
            align-items: stretch;

            label {
                font-size: Tools.asRem(13px);
                font-weight: 700;
                line-height: Tools.asRem(18px);
            }
        }
    }
}

.artis-chart__container {
    /* chart line */
    .highcharts-line-series,
    /* navigator line */
    .highcharts-navigator-series,
    /* tooltip tracker point */
    .highcharts-line-series > .highcharts-color-0,
    /* tooltip item bubble */
    .highcharts-tooltip-box > * > .highcharts-color-0 {
        fill: Colors.$sky-70;
        stroke: Colors.$sky-70;
    }
    /* chart axis labels */
    .highcharts-xaxis-labels,
    .highcharts-yaxis-labels,
    /* chart navigator labels */
    .highcharts-navigator-xaxis.highcharts-axis-labels {
        color: Colors.$text-secondary;
        fill: Colors.$text-secondary;
    }
    /* tooltips */
    .highcharts-tooltip-box {
        fill: Colors.$text-primary;
        stroke: none;
    }
}
