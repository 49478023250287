@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.p-radiobutton {
    position: relative;
    display: inline-flex;
    user-select: none;
    vertical-align: bottom;

	&:focus,
	&:focus-within {

		// &-box{
			outline: 1px solid Colors.$slate-60;
		// }
		// border: 1px solid;
		border-radius: 50%;
	}

	&-box {

		& * { outline: 0 }

		width: Tools.asRem(22px);
		aspect-ratio: 1 / 1;
		// outline: 0;

		border: 1px solid Colors.$slate;
		background: transparent;
	}

    &.p-highlight .p-radiobutton-box {

        & .p-radiobutton-icon {
            background-color: Colors.$ob-brand-orange;
            transform: translateZ(0) scale(1, 1);
            visibility: visible;
        }
    }
    // height: $radiobuttonHeight;

    &:not(.p-disabled):not(.p-highlight) .p-radiobutton-box:hover {
        border-color: Colors.$slate-60;
    }

    .p-radiobutton-box {
        display: flex;
        justify-content: center;
        align-items: center;

        // border: $radiobuttonBorder;
        // background: $inputBg;
        // width: $radiobuttonWidth;
        // height: $radiobuttonHeight;
        color: $textColor;
        border-radius: 50%;
        // transition: $formElementTransition;

        &:not(.p-disabled).p-focus {
            // @include focused-input();
        }

        .p-radiobutton-icon {
            width: $radiobuttonIconSize;
            height: $radiobuttonIconSize;
            transition-duration: $transitionDuration;
            // background-color: transparent;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform: translateZ(0) scale(0.1);
            border-radius: 50%;
            visibility: hidden;
        }

        &.p-highlight {
            // border-color: $radiobuttonActiveBorderColor;
            // background: $radiobuttonActiveBg;

            &:not(.p-disabled):hover {
                // border-color: $radiobuttonActiveHoverBorderColor;
                // background: $radiobuttonActiveHoverBg;
                // color: $radiobuttonIconActiveHoverColor;
            }
        }
    }

    &.p-invalid > .p-radiobutton-box {
        @include invalid-input();
    }

    &:focus {
        outline: 0 none;
    }

    .p-radiobutton-input {
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      z-index: 1;
      outline: 0 none;
      border: 2px solid #d1d5db;
      border-radius: 50%;
    }
}

.p-input-filled {
    .p-radiobutton {
        .p-radiobutton-box {
            background-color: $inputFilledBg;

            &:not(.p-disabled):hover {
                background-color: $inputFilledHoverBg;
            }
        }

        &.p-highlight .p-radiobutton-box {
            background: $radiobuttonActiveBg;

            &:not(.p-disabled):hover {
                background: $radiobuttonActiveHoverBg;
            }
        }
    }
}

@if ($highlightBg == $radiobuttonActiveBg) {
    .p-highlight {
        .p-radiobutton {
            .p-radiobutton-box {
                border-color: $radiobuttonIconActiveColor;
            }
        }
    }
}