@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.chart-window__container {
    flex-direction: column;
    background-color: Colors.$panel-background-color;
    gap: Tools.asRem(Spacing.$gutter-small);
    height: 100%;

    // override header styles to match aside header in regular window
    header {
        align-items: center;
        background-color: Colors.$column-header-background;
        border-bottom: 2px solid Colors.$column-header-border;
        min-height: Tools.asRem(42px);
        padding: 0 0 0 Tools.asRem(Spacing.$gutter-small);

        h2 {
            font-size: Tools.asRem(14px);
            color: Colors.$text-secondary;
        }

        h3 {
            font-size: Tools.asRem(11px);
            color: Colors.$text-primary;
            font-family: OpenSans, sans-serif;
            font-weight: 200;
        }
    }

    main {
        padding: 0 Tools.asRem(Spacing.$gutter-small);
        flex-direction: column;
        height: 100%;

        .price-change-subscriptions__container {
            max-height: 35%;

            &.p-panel.p-component:not(div:first-child) {
                gap: Tools.asRem(Spacing.$gutter-small);
                padding-bottom: Tools.asRem(Spacing.$gutter-large);
                // compensate padding of `main`
                margin-right: calc(-1 * Tools.asRem(Spacing.$gutter-small));
                margin-left: calc(-1 * Tools.asRem(Spacing.$gutter-small));
            }

            .p-inputtext {
                font-size: Tools.asRem(13px);
            }

            .p-dropdown {
                min-height: Tools.asRem(34px);
            }
        }
    }

    footer {
        width: calc(100% - 2 * Tools.asRem(Spacing.$gutter-small));
        position: fixed;
        bottom: 0;

        > div {
            flex-grow: 1;
            align-items: center;
            justify-content: flex-end;
        }
    }
}