@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.lastUpdatedInfo {
  width: 100%;
  align-self: flex-end;
  justify-content: flex-end;
  padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-large);
  color: Colors.$text-secondary;
  font-family: OpenSans, sans-serif;
  font-size: Tools.asRem(11px);
  line-height: Tools.asRem(18px);
  font-weight: 400;
  font-style: italic;

  @media only screen and (max-width: 960px) {
    padding: Tools.asRem(Spacing.$gutter-small);
    background: Colors.$ob-brand-black;
  }
}