@use 'sass:math';
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/objects" as *;

.p-autocomplete {

	flex-grow: 1;

	&-items-wrapper {
			flex-grow: 1;
	}


	&:has(input[disabled]) {
		position: relative;
		align-items: center;

		&::before {
			position: absolute;
			right: Tools.asRem(Spacing.$gutter-micro);
		}

		& input { padding-right: Tools.asRem(28px)}
	}

	.p-autocomplete-loader {
			right: nth($inputPadding, 2);
	}

	&.p-autocomplete-dd {
		.p-autocomplete-loader {
				right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
		}
	}

	.p-autocomplete-multiple-container {
			padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
		gap: Tools.asRem(Spacing.$gutter-small);

			&:not(.p-disabled):hover {
					border-color: $inputHoverBorderColor;
			}

			&:not(.p-disabled).p-focus {
					@include focused-input();
			}

			.p-autocomplete-input-token {
					padding: math.div(nth($inputPadding, 1), 2) 0;

					input {
							// font-family: $fontFamily;
							// font-size: $fontSize;
							color: $textColor;
							padding: 0;
							margin: 0;

							@media only screen and (max-width: 960px) {
								font-size: 1rem;
							}
					}
			}

			// .p-autocomplete-token {
			// 		// padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
			// 		// margin-right: $inlineSpacing;
			// 		background-color: Colors.$token-background ;
			// 		color: Colors.$token-label;
			// 		border-radius: 2px;
			// 		font-size: Tools.asRem(13px);
			// 		padding: 0 Tools.asRem(6px);
			// 		height: Tools.asRem(24px);

			// 		& label { font-weight: 900; }

			// 		.p-autocomplete-token-icon {
			// 			margin-left: Tools.asRem(Spacing.$gutter-small)
			// 		}
			// }
	}

	&.p-invalid.p-component > .p-inputtext {
			@include invalid-input();
	}

	&-token { @extend %token; }
}

.p-autocomplete-panel {

	&.autocomplete-entity {
    background: Colors.$charcoal;
    border: 1px solid Colors.$full-black;
    border-radius: 0 0 $borderRadius $borderRadius;
    box-shadow: $inputOverlayShadow;
    margin-top: -1px;

    padding: Tools.asRem(Spacing.$gutter-small) 0;

    &.empty .p-autocomplete-item  {
        padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
    }

    &:not(.empty) .p-autocomplete-item  {
      padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
    }

    .p-autocomplete-items {
        // padding: $inputListPadding;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        // gap: Tools.asRem(Spacing.$gutter-micro);

        .p-autocomplete-item {
            // margin: $inputListItemMargin;
            color: Colors.$text-secondary;
            background: transparent;
			font-weight: 400;
            transition: $listItemTransition;

            &:hover,
            &.p-highlight {
                color: Colors.$ob-brand-black;
                background: Colors.$slate-90;
            }

            & > div { flex-grow: 1; }

        }

        .p-autocomplete-item-group {
            font-weight: 700;
        }
        .p-autocomplete-item-group:not(:first-child) {
            margin-top: Tools.asRem(16px);
        }

        .p-autocomplete-item-group,
        .p-autocomplete-item {
            align-items: center;
            display: flex;
            min-height: Tools.asRem(24px);
            font-size: Tools.asRem(13px);
        }

        .p-autocomplete-item-group {
            color: Colors.$text-primary;
            padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
            z-index: 1;
            pointer-events: none;
        }

        .p-autocomplete-item-group + li {
            // top margin as negative element height + 1/2 of the top/bottom padding
            margin-top: 0 - Tools.asRem(24px);
        }


    }

		&.autocomplete-entity-grouped {
			&:not(.empty) .p-autocomplete-item  {
				$clamp-min: Tools.asRem(112px);
				$clamp-max: Tools.asRem(148px);
				padding-left: clamp($clamp-min, Tools.asRem(136px), $clamp-max);
			}
		}
	}

	&.autocomplete-entity-grouped {
		&:not(.empty) .p-autocomplete-item  {
			$clamp-min: Tools.asRem(112px);
			$clamp-max: Tools.asRem(148px);
			padding-left: #{clamp($clamp-min, Tools.asRem(136px), $clamp-max)};
		}

		&.autocomplete-entity-with-sections {
			.p-autocomplete-items-wrapper {
				.p-autocomplete-items {

					&:not(.empty) .p-autocomplete-item  {
						padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
						min-height: Tools.asRem(26px);

						&:has(.item-template--caption) {
							min-height: Tools.asRem(34px);
						}

						@media (max-width: 960px) {
							&:has(.item-template--group) {
								min-height: Tools.asRem(46px);
								margin-top:  Tools.asRem(Spacing.$gutter-small);
							}
						}
					}

					.p-autocomplete-item + .p-autocomplete-item {
						@media (max-width: 960px) {
							&:has(.item-template--group) {
								min-height: auto;
								margin-top: 0;
								flex-shrink: 0;
							}
						}
					}

					.p-autocomplete-item-group {
						min-height: Tools.asRem(26px);
					}

					.p-autocomplete-item-group + li {
						margin-top: 0;
					}

					.p-autocomplete-item-group:not(:first-child) {
						margin-top: Tools.asRem(Spacing.$gutter-large);
					}

					.item-template {
						display: grid;
						// grid-template-columns: minmax(min-content, Tools.asRem(132px)) auto auto;

						.item-template-grouplabel {
							color: Colors.$text-primary;
						}

						@media (max-width: 960px) {
							// grid-template-columns: auto minmax(min-content, Tools.asRem(80px))  minmax(min-content, Tools.asRem(21px));
							grid-row-gap:  Tools.asRem(Spacing.$gutter-micro);
							// For mobile show optional group label in 1st row and rest in second
							.item-template-grouplabel {
								grid-column-start: 1;
								grid-row-start: 1;
								grid-row-end: 1;
							}

							.item-template-label {
								grid-column-start: 1;
								grid-row-start: 2;
								grid-row-end: 2;
							}

							.item-template-sublabel {
								grid-column-start: 2;
								grid-row-start: 2;
								grid-row-end: 2;
							}

							.country-flag {
								grid-column-start: 3;
								grid-row-start: 2;
								grid-row-end: 2;
							}
						}
					}
				}
			}
		}
	}
}