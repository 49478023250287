@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.container {
  background-color: Colors.$panel-background-color;
  border: 1px solid Colors.$panel-border-color;
  border-radius: 3px;
  padding: Tools.asRem(Spacing.$gutter-small);
  flex-direction: column;
  gap: Tools.asRem(Spacing.$gutter-small);
  word-break: break-word;
  font-size: Tools.asRem(13px);
}

.title {
  flex-direction: row;
  justify-content: space-between;
  font-family: OpenSans, sans-serif;

  :first-child {
    font-weight: 700;
    color: Colors.$text-primary;
  }

  :nth-child(2) {
    font-weight: 400;
    color: Colors.$text-secondary;
  }
}