@use "src/design-system/globals/colors" as Color;
@use "src/design-system/tools" as Tools;

h1, h2, h3, h4 {
  @extend %font-headings;
	color: Color.$text-primary;
}

h1 { 

	@extend %heading-major;

	@media only screen and (min-width: 960px) {
		// font-size: Tools.asRem(28px); 
	}
}