@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

$configButtonSize: Tools.asRem(20px);

.blotter-data-table {
  margin-right: $configButtonSize;

  // Use SCSS variable first so 'Tools', comments, etc. can be used. Just for clarity.
  $blotterGridTemplateColumns:
    minmax(Tools.asRem(188px), 1fr)             // instrument
    minmax(Tools.asRem(110px), 1fr)             // type
    repeat(4, minmax(Tools.asRem(125px), 1fr))  // broker (buy), buy side, broker (sell), sell side
    minmax(Tools.asRem(150px), 1fr)             // quantity
    Tools.asRem(120px)                          // price
    Tools.asRem(135px)                          // trade date
    Tools.asRem(107px)                          // clearing id
    Tools.asRem(42px);                          // action button

  --blotterGridTemplateColumns: #{$blotterGridTemplateColumns};

  &.p-datatable {
    tr {
      display: grid;
      grid-auto-flow:column;
      grid-template-columns: var(--blotterGridTemplateColumns);

      td {
        .not-available__container {
          font-style: italic;
          color: Colors.$text-secondary;
        }

        .p-chip {
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(13px);
          font-weight: 700;
          height: Tools.asRem(24px);
          border-radius: Tools.asRem(2px);

          &.chip--outright {
            border: 1px solid Colors.$grass;
            background-color: Colors.$grass-20;
            color: Colors.$grass;
          }

          &.chip--spread {
            border: 1px solid Colors.$indigo;
            background-color: Colors.$indigo-20;
            color: Colors.$indigo;
          }

          .p-chip-text {
            color: currentColor !important; // keep current color when row is selected
          }
        }

        &.blotter-data-table__column--quantity {
          > :first-child {
            grid-template-columns: 1fr minmax(Tools.asRem(82px), auto);
            gap: Tools.asRem(Spacing.$gutter-small);

            > :first-child {
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;
              text-align: end;
            }
          }
        }

        &.blotter-data-table__column--price {
          > :first-child {
            grid-template-columns: 1fr minmax(Tools.asRem(36px), auto);
            gap: Tools.asRem(Spacing.$gutter-small);
          }
        }
      }

      th {
        .p-badge {
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          height: Tools.asRem(22px);
          width: Tools.asRem(22px);
          min-width: Tools.asRem(22px);
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(14px);
          line-height: Tools.asRem(14px);
          background-color: Colors.$ob-brand-orange;
          top: Tools.asRem(-10px);
          right: Tools.asRem(-15px);
        }

        .p-column-title {
          position: relative;
          gap: Tools.asRem(Spacing.$gutter-small);

          .blotter-data-table__filter-icon--wrapper {
            padding-left: Tools.asRem(Spacing.$gutter-small);

            &:hover {
              ::before {
                color: Colors.$ob-brand-orange;
              }
            }
          }
        }
      }

      th.sorting--server-side {
        [data-sorting]::after {
          top: 0; // align badge
        }
      }
    }

    @media only screen and (max-width: 960px) {
      border: none !important;
      margin-top: $configButtonSize;
      margin-right: 0;

      tbody {
        background: Colors.$ob-brand-black;
      }

      tr {
        $grid-element-border: 1px dashed Colors.$datatable-row-border;

        grid-template-columns: 1fr;
        margin: Tools.asRem(Spacing.$gutter-small);

        &:first-child {
          margin-top: 0;
        }

        .trade-item {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          background-color: Colors.$panel-background;
          border: 1px solid Colors.$full-black;
          border-radius: Tools.asRem(3px);
          padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);

          .grid-element {
            gap: calc(Tools.asRem(Spacing.$gutter-micro) / 2);
            grid-column: span 6;

            &:nth-last-child(2):nth-child(even) {
              border-bottom: $grid-element-border;
            }

            &.grid-element__instrument {
              border-right: none;
              grid-column: span 10;
            }

            &.grid-element__actions {
              align-items: end;
              justify-content: center;
              grid-column: span 2;
              min-height: Tools.asRem(47px);
            }

            &.grid-element__type {
              .grid-element__body .p-chip {
                height: Tools.asRem(24px);
              }
            }

            &.grid-element__price, &.grid-element__quantity {
              .grid-element__body {
                > div {
                  display: flex;
                  justify-content: flex-end;
                  gap: Tools.asRem(Spacing.$gutter-small);
                }
              }
            }

            .grid-element__body {
              align-items: center;
              height: Tools.asRem(32px);
              padding: 0;

              > div {
                height: 100%;
                align-items: center;
              }
            }

            .upper {
              font-family: OpenSans, sans-serif;
              font-size: Tools.asRem(13px);
              font-weight: 700;
            }

            .lower {
              color: Colors.$text-secondary;
              font-size: Tools.asRem(11px);
              line-height: Tools.asRem(14px);
              margin-top: -1 * Tools.asRem(Spacing.$gutter-micro); // tighten grid lines
            }
          }

          &:hover,
          &:focus {
            background: Colors.$charcoal;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.blotter-grid-column-configuration {
  @media only screen and (max-width: 960px) {
    transform: none;
    top: 0;

    button {
      transform: none;
      margin-right: Tools.asRem(Spacing.$gutter-small);
    }
  }
}

.blotter-grid-column-panel {
  margin-right: $configButtonSize;

  @media only screen and (max-width: 960px) {
    margin-right: 0;
    position: fixed;
    top: 0;
  }
}

.blotter-data-table__filter-menu {
  min-width: Tools.asRem(176px);
  max-height: Tools.asRem(250px);
  overflow: auto;

  ul {
    padding: Tools.asRem(Spacing.$gutter-small) 0;
    margin-left: 0 !important;

    > li > div {
      align-items: center;
      padding: Tools.asRem(Spacing.$gutter-small);
      gap: Tools.asRem(Spacing.$gutter-micro);
      cursor: pointer;
    }
  }
}

.blotter-data-table__action-menu {
  &-init-state {
    width: 100%;
  }
}
