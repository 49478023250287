@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

$activity-footer-height: Tools.asRem(50px);

.activity-wrap {
  width: 100%;
  flex-direction: column;

  .activity-container,
  .module__canvas .activity-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    flex: 1;

    .activity-content {
      flex: 1;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      overflow-y: auto;
      flex-direction: column;
      gap: Tools.asRem(Spacing.$gutter-small);
      padding: Tools.asRem(Spacing.$gutter-small);
      margin-bottom: $activity-footer-height;

      .activity-note-input {
        flex-grow: 0;
        resize: vertical;
      }

      textarea::placeholder,
      .placeholder {
        color: Colors.$text-placeholder;
        font-style: italic;
      }

      .p-timeline {
        overflow-y: auto;
        flex-direction: column;
        flex-grow: 1;

        .p-timeline-event {
          flex-shrink: 0;
          min-height: auto;
        }

        .p-timeline-event-content {
          padding-right: 0;
          padding-bottom: Tools.asRem(Spacing.$gutter-small);
        }

        .p-timeline-event-opposite {
          display: none;
        }

        .p-timeline-event-connector {
          background-color: Colors.$slate;
        }

        .p-timeline-event-marker {
          background-color: Colors.$ob-brand-orange;
          border: 0;
        }
      }

      .activity-item {
        width: 100%;
        flex-direction: column;
        border: 1px solid Colors.$full-black;
        border-radius: Tools.asRem(3px);
        background-color: Colors.$column-header-background;
        padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
        gap: Tools.asRem(Spacing.$gutter-small);
        font-family: OpenSans, sans-serif;
        font-size: Tools.asRem(13px);
        line-height: Tools.asRem(18px);
        font-weight: 400;
        color: Colors.$text-primary;

        .activity-note-header {
          font-weight: 700;
        }

        .activity-note-footer {
          color: Colors.$text-secondary;
        }
      }

      .p-accordion {
        flex-direction: column;
        width: 100%;

        &-tab {
          flex-direction: column;
          border: 1px solid Colors.$full-black;
          border-radius: Tools.asRem(3px);
          background-color: Colors.$column-header-background;
        }

        &-header {
          border-bottom: 0;

          &-link {
            border: 0;
            align-items: flex-start;

            &:focus {
              outline: none;
            }

            .p-accordion-header-text {
              font-family: OpenSans, sans-serif;
              font-size: Tools.asRem(13px);
              line-height: Tools.asRem(18px);
              font-weight: 400;
              color: Colors.$text-secondary;
              font-style: italic;
              margin: 0 Tools.asRem(Spacing.$gutter-small);

              .activity-accordion-date {
                font-style: normal;
              }
            }

            .p-icon {
              margin: Tools.asRem(Spacing.$gutter-micro);
            }

            .p-accordion-header-text, .p-icon {
              color: Colors.$text-secondary;
            }
          }

          svg {
            width: Tools.asRem(16px);
            height: Tools.asRem(16px);
          }
        }

        &-content {
          .activity-accordion-content {
            flex-direction: column;
            gap: Tools.asRem(Spacing.$gutter-medium);

            & > div {
              flex-direction: column;

              dt {
                align-content: center;
                font-family: OpenSans, sans-serif;
                font-weight: 700;
                font-size: Tools.asRem(11px);
                line-height: Tools.asRem(15px);
                color: Colors.$text-secondary;
                text-transform: uppercase;
              }

              dd {
                align-content: center;
                word-break: break-word;
                font-family: OpenSans, sans-serif;
                font-weight: 400;
                font-size: Tools.asRem(13px);
                line-height: Tools.asRem(18px);
                color: Colors.$text-primary;
              }
            }
          }
        }
      }
    }
  }

  footer,
  footer:not(.no-background):has(> *) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $activity-footer-height;
    padding-top: Tools.asRem(Spacing.$gutter-small);
    flex-direction: row-reverse;
  }
}

.activity-loading-container {
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  align-content: center;
}
.activity-loading-content {
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  align-content: center;
}

