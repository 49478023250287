@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

$button-icon-size: 18px;

.time-zone__container {
  align-self: flex-end;

  button.p-button, button.p-button-icon-only:not(.p-button-sm) {
    background: Colors.$ob-brand-black;
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    border-radius: Tools.asRem(Spacing.$gutter-micro);
    border: 1px solid Colors.$ob-brand-black;

    span:first-child {
      width: Tools.asRem($button-icon-size);
      height: Tools.asRem($button-icon-size);

      &:before {
        width: Tools.asRem($button-icon-size);
        height: Tools.asRem($button-icon-size);
        color: Colors.$text-secondary;
      }
    }

    .p-button-label {
      height: 0;
    }
  }
}

.time-zone-menu__container.p-menu {
  padding: Tools.asRem(Spacing.$gutter-small);
  width: Tools.asRem(160px);

  .p-menu-list {
    .p-submenu-header {
      color: Colors.$text-primary;
      background-color: transparent;
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(13px);
      font-weight: 700;
      line-height: Tools.asRem(18px);
      text-align: left;
      padding: Tools.asRem(Spacing.$gutter-micro) 0;
    }

    .p-menuitem {
      margin-top: Tools.asRem(Spacing.$gutter-medium);

      .menu--radio-buttons {
        flex-direction: column;
        gap: Tools.asRem(Spacing.$gutter-medium);

        .p-radiobutton-box {
          width:  Tools.asRem(26px);
        }

        label {
          color: Colors.$text-secondary;
          font-size: Tools.asRem(13px);
          font-weight: 700;
          line-height: Tools.asRem(26px);
          padding-left: Tools.asRem(Spacing.$gutter-small);
        }
      }
    }
  }
}