@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.item-template {
    display: grid;
		grid-template-columns: minmax(min-content, 1fr) minmax(min-content, Tools.asRem(112px)) Tools.asRem(32px);
		column-gap: Tools.asRem(Spacing.$gutter-small);
    // justify-content: space-between;
    font-weight: 400;
    align-items: center;

		& img {
			grid-area: 1 / 3 / 1 / 3;
		}

    &-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
    }

    &-captionlabel {
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(9px);
      font-weight: 400;
      line-height: Tools.asRem(12px);
      text-align: left;
    }

    &-sublabel {
        // flex: 0 1 35%;
    }

    strong {
        font-weight: 800;
    }
}

.grouped-item-template {
  font-weight: 700;
}