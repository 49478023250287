@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/animations" as Timings;
@use 'src/design-system/globals/fonts' as Text;

.container {
	position: relative;
	flex-grow: 1;
	overflow-x: hidden;
}

@media screen and (min-width: 960px) {
	.container {
		margin-right: Tools.asRem(20px);
		border-top-right-radius: unset !important;
	}
}

.grid {

	// & tr:not(.p-datatable-emptymessage) {
	& tr {
		grid-template-columns: var(--maxCharacters) repeat(var(--columns), Tools.asRem(148px)) minmax(Tools.asRem(156px), 1rem);
	}

}

@media screen and (max-width: 960px) {
	.grid {
		& thead { display: none; }

		// & tr:not(.p-datatable-emptymessage) {
		// 	grid-template-columns: Tools.asRem(112px) repeat(var(--columns), Tools.asRem(148px)) 1rem;
		// }
	}
}

.header {
	line-height: Tools.asRem(13px);

	> div {
		flex: 1;
		overflow: hidden;
	}

	span {
		width: 100%;
	}

	span > div {
		justify-content: space-between;
	}

	& span > div > div {
		flex-direction: column;
		overflow: hidden;

		> div {
			white-space: nowrap;
			display: inline;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	& span:first-child {
		text-transform: uppercase;
	}
}

.footer {
	@include Text.table-footer;

	color: Colors.$text-secondary;
	padding: Tools.asRem(Spacing.$gutter-micro) 0;
	justify-content: flex-end;
	margin-top: Tools.asRem(0 - Spacing.$gutter-small);
	margin-right: Tools.asRem(Spacing.$gutter-large + Spacing.$gutter-small);

	& > svg {
		margin-top: Tools.asRem(0 - Spacing.$gutter-small);
		height: Tools.asRem(32px);
	}
}

.mockHeader {
	min-height: Tools.asRem(42px);
	align-items: center;
	background-color: Colors.$column-header-background;
	border: 1px solid Colors.$panel-border-color;
	padding: 0 Tools.asRem(Spacing.$gutter-medium);
}

.marketClosed {
	top: Tools.asRem(14px);
}

.ribbonContainer {
	margin-bottom: calc(-1 * Tools.asRem(Spacing.$gutter-small)); // - section gap
	border-radius: 0 !important;
	border: none !important;

	.ribbon {
		justify-content: flex-start;
		border-radius: 0;
		padding: 0;
		background: Colors.$negative;

		span {
			color: Colors.$slate-20;
			font-size: Tools.asRem(13px);

			> :first-child {
				font-family: Panton, sans-serif;
				font-size: Tools.asRem(14px);
				font-weight: 400;
				padding-right: Tools.asRem(Spacing.$gutter-small);
			}

			> :nth-child(2) {
				font-family: OpenSans, sans-serif;
				line-height: 18px;
				font-weight: 400;
			}
		}
	}

	@media screen and (max-width: 960px) {
		.ribbon {
			span {
				display: flex;
				flex-direction: column;
			}
		}
	}
}