@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.sl-status,
main.distlist-container-table .distlist__table--distLists td > div.sl-status,
.module__canvas > main.distlist-container-table .distlist__table--distLists td > div.sl-status {
  display: flex;

  i {
    margin-right: Tools.asRem(Spacing.$gutter-small);
  }

  font-family: OpenSans, sans-serif;
  font-size: Tools.asRem(13px);
  line-height: Tools.asRem(18px);
  font-weight: 400;
  color: Colors.$text-secondary;
}

.status-positive {
  i::before {
    color: Colors.$positive;
  }
}

.status-negative {
  i::before {
    color: Colors.$negative;
  }
}