$egg-plant: var(--egg-plant);
$egg-plant-90: var(--egg-plant-90);
$egg-plant-80: var(--egg-plant-80);
$egg-plant-70: var(--egg-plant-70);
$egg-plant-60: var(--egg-plant-60);
$egg-plant-50: var(--egg-plant-50);
$egg-plant-40: var(--egg-plant-40);
$egg-plant-30: var(--egg-plant-30);
$egg-plant-20: var(--egg-plant-20);

