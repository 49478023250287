@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.surveillanceEscalatePopup {
  flex-direction: column;
  gap: Tools.asRem(24px);
  flex-grow: 1;

  label {
    font-family: OpenSans, sans-serif;
    font-size: Tools.asRem(13px);
    line-height: Tools.asRem(18px);
    font-weight: 700;
    color: Colors.$text-secondary;
  }

  &HighImportance {
    align-items: center;
    gap: Tools.asRem(Spacing.$gutter-small);
  }

  &Person, &Note, &Subject {
    flex-direction: column;
    flex-grow: 1;
    gap: Tools.asRem(Spacing.$gutter-micro);

    small {
      color: Colors.$negative;
    }
  }
}

.invalid {
  border-color: Colors.$negative;
}
