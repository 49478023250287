@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.price-change-subscription {
  &__container {
    gap: Tools.asRem(Spacing.$gutter-micro);

    .p-button-text {
      min-width: Tools.asRem(42px);
      min-height: Tools.asRem(34px);

      &.price-change-subscription__button--add:not(:disabled) {
        color: Colors.$ob-brand-orange;
      }
    }

    .p-badge {
      top: unset;
      right: unset;
      min-width: unset;
      width: Tools.asRem(Spacing.$gutter-large);
      height: Tools.asRem(Spacing.$gutter-large);
      transform: translate(-20%, -2px);
      z-index: 1;
    }

    .p-inputgroup {
      > .price-change-subscription-direction {
        flex: 0 1 Tools.asRem(56px);
      }

      .p-dropdown-label {
        padding: 0;
        padding-left: Tools.asRem(Spacing.$gutter-small);
        width: 0;
      }

      .p-dropdown-trigger {
        width: auto;
        padding: 0 Tools.asRem(Spacing.$gutter-micro);
      }

      > :not(:last-child):not(:hover):not(.p-focus), .p-inputtext {
        border-right-color: transparent; // display only single border between items
      }

      .message-invalid {
        white-space: nowrap;
        position: absolute;
        top: 100%;
        color: Colors.$ob-brand-red;
      }
    }

    &.p-invalid {
      padding-bottom: Tools.asRem(Spacing.$gutter-large);
    }
  }

  &__dropdown-panel--direction {
    .p-dropdown-item > div {
      gap: Tools.asRem(Spacing.$gutter-micro);
    }
  }
}
