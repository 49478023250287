@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.searchchip {

    gap: Tools.asRem(Spacing.$gutter-micro);
    border: 1px solid Colors.$full-black;
    border-radius: Tools.asRem(2px);
    padding: Tools.asRem(Spacing.$gutter-micro);
    font-size: Tools.asRem(11px);
    color: #353434;
    z-index: 1;

    & label {
        font-weight: 800;
    }

    &[data-item-group="commodity"] { background-color: Colors.$egg-plant-30; }
    &[data-item-group="loading"] { background-color: Colors.$lime-40; }
    &[data-item-group="discharge"] { background-color: Colors.$amber-20; }
    &[data-item-group="vessel"] { background-color: Colors.$crimson-30; }
    &[data-item-group="business"] { background-color: Colors.$slate-30; }
    &[data-item-group="filter"] { background-color: Colors.$slate-70; }

    & button {
        padding: 0;
        height: Tools.asRem(16px);
        width: Tools.asRem(16px);
    }

    &:focus-within {
        outline: 2px solid Colors.$text-primary;
        border-color: Colors.$text-primary;
    }
}