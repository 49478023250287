@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.p-dropdown {
	background: Colors.$select-list-background;
}

.p-dropdown,
.p-dropdown-panel {
	border: 1px solid Colors.$input-border-color;
}

.p-dropdown-panel {
	background: Colors.$select-list-panel-background;
}

.p-dropdown {
	// border: Colors.$full-black;
	align-items: center;
	transition: $formElementTransition;

	&:not(.p-dropdown--in-cell) { border-radius: 3px; }

	min-height: Tools.asRem(42px);

	@media only screen and (min-width: 960px) {
		min-height: Tools.asRem(36px);
	}

	&:focus,
	&:not(.p-disabled):hover {
			border: 1px solid Colors.$dropdown-focus-border;
	}

	&:not(.p-disabled).p-focus {
		@include focused-input();
	}

	&-footer {
		padding: 0 Tools.asRem(Spacing.$gutter-small);
	}

	&-label {
		background: transparent;
		border: 0 none;
		padding: 0 Tools.asRem(Spacing.$gutter-small);

		&.p-placeholder {
			font-style: italic;
			color: Colors.$text-placeholder;
		}

		&:enabled:focus {
			outline: 0 none;
			box-shadow: none;
		}
	}

	&-trigger {
		background: transparent;
		color: $inputIconColor;
		width: $inputGroupAddOnMinWidth;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
		padding: Tools.asRem(Spacing.$gutter-micro);
	}

	&-clear-icon {
		color: $inputIconColor;
		right: $inputGroupAddOnMinWidth;
	}

	// &.p-invalid.p-component {
	// 		@include invalid-input();
	// }

	&-items-wrapper {
		flex-grow: 1;
		// padding: Tools.asRem(Spacing.$gutter-small) 0;
	}

	&-item {
		align-items: center;
		padding: Tools.asRem(Spacing.$gutter-small);
		font-weight: unset;
		position: relative;
		overflow: unset;
		display: flex;

		transition: $listItemTransition;
		// border-radius: $inputListItemBorderRadius;

		&.p-highlight {
			padding-right: Tools.asRem(Spacing.$gutter-medium * 2);
			color: $inputListItemHighlightTextColor;
			background: $inputListItemHighlightBg;

			&::after {
					content: '';
					// check icon
					mask-image: url('data:image/svg+xml;charset=utf-8,<svg stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 13L9 17L19 7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');
					background-color: currentColor;
					background-repeat: no-repeat;
					display: inline-block;
					width: Tools.asRem(16px);
					height: Tools.asRem(16px);
					position: absolute;
					right: Tools.asRem(4px);
			}
		}

		&:not(.p-disabled):hover {
			color: $inputListItemTextHoverColor;
			background: $inputListItemHoverBg;
		}
		}

	&-panel {

		box-shadow: $inputOverlayShadow;
		color: Colors.$text-secondary;
		flex-direction: column;
		margin-top: 2px;

		& > *:last-child {
			padding-bottom: Tools.asRem(Spacing.$gutter-small);
		}

	}
}

// .p-dropdown-panel {

// 	box-shadow: $inputOverlayShadow;
// 	color: Colors.$text-secondary;
// 	flex-direction: column;
// 	margin-top: 2px;

// }

.p-dropdown-header {
	padding: $inputListHeaderPadding;
	border-bottom: $inputListHeaderBorder;
	color: $inputListHeaderTextColor;
	background: $inputOverlayHeaderBg;
	margin: $inputListHeaderMargin;
	border-top-right-radius: $borderRadius;
	border-top-left-radius: $borderRadius;

	.p-dropdown-filter {
			padding-right: nth($inputPadding, 2) + $primeIconFontSize;
			margin-right: -1 * (nth($inputPadding, 2) + $primeIconFontSize);
	}

	.p-dropdown-filter-icon {
			right: nth($inputPadding, 2);
			color: $inputIconColor;
	}

	.p-dropdown-clearable-filter {
			.p-dropdown-filter {
					padding-right: 2 * (nth($inputPadding, 2) + $primeIconFontSize);
					margin-right: -2 * (nth($inputPadding, 2) + $primeIconFontSize);
			}

			.p-dropdown-filter-clear-icon {
					right: (2 * nth($inputPadding, 2)) + $primeIconFontSize;
			}
	}
}

.p-dropdown-items {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-micro);

	// padding: Tools.asRem(Spacing.$gutter-small);
	// padding: $inputListPadding;

	.p-dropdown-item-group {
			margin: $submenuHeaderMargin;
			padding: $submenuHeaderPadding;
			color: $submenuHeaderTextColor;
			background: $submenuHeaderBg;
			font-weight: $submenuHeaderFontWeight;
	}

	.p-dropdown-empty-message {
			padding: $inputListItemPadding;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
	}
}

.p-input-filled {
    .p-dropdown {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;

            .p-inputtext {
                background-color: transparent;
            }
        }
    }
}
