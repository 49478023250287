@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Color;
@use "src/design-system/tools" as Tools;

$min-height: 22px;

.price-history {


 
    &__summary { 
        flex-direction: column; 

        & > * { 
            align-items: center;
            min-height: Tools.asRem($min-height); 
        }
    }

    &__value {

        align-items: baseline;

        & em { color: Color.$text-secondary; }

        & strong { 
            opacity: unset;
            font-size: Tools.asRem(32px);
        }
    }


    &__change {

        
        font-size: Tools.asRem(14px);
        
        &.up  { color: Color.$grass-80; }
        
        &.down { color: rgb(234, 67, 53); }
        
        & span { 
            &::before { color: inherit };
            margin: 0 4px 0 4px;
            display: inline-block;
            width: Tools.asRem($min-height);
        }
    }


}