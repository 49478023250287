@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.note-panel {
  &-colors {
    &--info {
      $color: Colors.$sky-80;
      border-color: $color;

      .note-panel--header > *[class^='iconoir-']::before {
        background-color: $color;
      }
    }
    &--error {
      $color: Colors.$tomato;
      border-color: $color;

      .note-panel--header > *[class^='iconoir-']::before {
        background-color: $color;
      }
    }
  }

  &--container {
    flex: 1;
    flex-direction: column;

    padding: Tools.asRem(Spacing.$gutter-medium);
    gap: Tools.asRem(Spacing.$gutter-small);

    border-width: Tools.asRem(1px) ;
    border-style: solid;
    border-radius: Tools.asRem(3px);
  }

  &--header {
    gap: Tools.asRem(Spacing.$gutter-small);
    align-items: center;
    font-weight: 700;
  }
}
