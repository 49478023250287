@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Color;
@use "src/design-system/tools" as Tools;

time {
    font-size: Tools.asRem(11px);
  }
  
time:not(.text-primary) {
  color: Color.$text-secondary;
}
