@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.code-snippet__container {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid Colors.$slate;
    background-color: Colors.$charcoal;
    padding: Tools.asRem(Spacing.$gutter-large);

    strong {
        margin-bottom: Tools.asRem(Spacing.$gutter-small);
    }

    > div > * {
        display: block;
        word-break: break-word;
    }

    .code-snippet__copy-button {
        aspect-ratio: 1/1;
        position: absolute;
        right: Tools.asRem(Spacing.$gutter-large);
        width: Tools.asRem(42px);
    }
}