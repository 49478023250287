@use "src/design-system/globals/colors" as Color;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.route-settings-container {
    flex: 1 1 100%;
    width: 100%;

    .time-zone__container {
        padding-top: calc(15px + Spacing.$gutter-micro); // label height + flex gap
    }

    .p-accordion-tab {
        flex-direction: column;
        width: 100%;
    }

    .p-accordion-content {
        flex-direction: column;
        gap: Tools.asRem(Spacing.$gutter-medium);
        width: 100%;

        .parameters__container {

					& > div {
						// flex-grow: 1;
						width: 50%;
					}


            align-items: flex-start;
            border-bottom: 1px dashed Color.$slate;
            gap: Tools.asRem(Spacing.$gutter-large);
            padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);

            // .speed-field__container, .datetimerange__container {
            //     flex: 1 1 0%;
            //     min-width: 0;
            // }
        }
    }

    @media only screen and (max-width: 960px) {
        padding: Tools.asRem(Spacing.$gutter-small);
    }
}