@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/animations" as Animations;

td[role=cell] {

	&.cell--aligned-right,
	&:has(.cell--aligned-right) {
		justify-content: flex-end;
	}

}

tr[role=row] {
    &.row-aligned {
        &--top { align-items: flex-start; }
    }
}

.cell--bold-text {
    font-weight: 400;
    // padding-top: Tools.asRem(Spacing.$gutter-small);
}  

