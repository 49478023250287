@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;

.infoPanel {
  & div {
    flex: 1;
  }

  div[data-pc-section="content"] {
    padding: 0;

    border-radius: 0;
    background: none;
    border: none;
  }

  div[data-pc-name="message"] {
    justify-content: flex-start;
    border-radius: 0;
  }
}
