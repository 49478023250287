@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.p-tabview {


	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;

	& ul {
		display: flex;
		list-style: none;
		flex: 1;
	} 

	.p-tabview-nav {
		@include Tools.dotted-border($edge: bottom);

		flex-grow: 1;
    margin-left: 0;

    &-container {
      background-color: Colors.$column-header-background;
    }

		li {
				// margin-right: $tabviewHeaderSpacing;
			height: Tools.asRem(40px);
			display: flex;
			overflow: hidden;
			position: relative;

			& a {
				@extend %font-button-small;
				color: Colors.$text-secondary;

				&:focus-visible {
					// outline-offset: -2px;
					outline: 0;
				}
			}

			&.p-disabled.p-highlight { opacity: unset;}

			&:focus-within {
				outline: none;
			}

			.p-tabview-nav-link {
				transition: $listItemTransition;
				padding: 0 Tools.asRem(Spacing.$gutter-large);
				align-self: center;
			}

			&.p-highlight {
				border-bottom: 3px solid Colors.$ob-brand-orange;
				pointer-events: none;
				
				& a {
					color: Colors.$ob-brand-orange;
				}
			}

			&:not(.p-highlight) {
				padding-bottom: 3px; // compensate for border bottom in highlight
			}
		}

		&-content { flex-grow: 1; }
  }

	.p-tabview-close {
			margin-left: $inlineSpacing;
	}
    
	.p-tabview-nav-btn.p-link {
			background: $tabviewHeaderActiveBg;
			color: $tabviewHeaderTextActiveColor;
			width: $buttonIconOnlyWidth;
			box-shadow: $raisedButtonShadow;
			border-radius: 0;

			&:focus {
					@include focused-inset();
			}
	}

	.p-tabview-panels {
			// padding: 0 Tools.asRem(Spacing.$gutter-medium);
			flex-grow: 1;
			overflow: hidden;

			& > div { flex-grow: 1 }
	}
}
