@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/fonts' as Fonts;

$panelZIndex: 99;

.open {
	right: 0 !important;
}

.panel {
	background-color: Colors.$panel-background-color;
	box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.36);
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-small);
	border-left: 1px solid Colors.$panel-border-color;

	position: absolute;
	right: calc(0px - 100%);

	// set min-width a bit smaller than the section's width to avoid overlapping on the narrow desktop screen
	min-width: min(90%, Tools.asRem(350px));
	max-width: Tools.asRem(362px);
	height: 100%;

	transition: right 0.458s ease-out;
	z-index: $panelZIndex;

	& > * {
		padding: 0 Tools.asRem(Spacing.$gutter-small);
	}

	& > div {
		flex-direction: column;
	}

	& ul {
		list-style: none;
		flex-direction: column;
		margin: unset !important;
	}

	& ul,
	& li {
		display: flex;
		gap: Tools.asRem(Spacing.$gutter-small);
	}

	& li {
		align-items: center;
	}

	& li span {
		margin-left: auto;
		gap: Tools.asRem(Spacing.$gutter-micro);
		color: Colors.$text-secondary;
	}

	p {
		font-size: Tools.asRem(14px);
		color: Colors.$text-secondary;
		margin: Tools.asRem(Spacing.$gutter-micro);
	}
}

.header {
	@include Fonts.heading-major;
	@include Tools.dotted-border($edge: bottom);

	align-items: center;
	color: Colors.$text-secondary;
	gap: Tools.asRem(Spacing.$gutter-small);
	position: relative;
	padding: Tools.asRem(Spacing.$gutter-small);
}

.footer {
	&:not(:empty) {
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.36) inset;
		border: 1px solid Colors.$panel-border-color;
		border-right: none;
		border-left: none;
		justify-content: flex-end;
		padding: Tools.asRem(Spacing.$gutter-small);
	}
}

.close {
	background-color: transparent !important;
	width: Tools.asRem(36px);
  aspect-ratio: 1/1;
	margin-left: auto;
}

.scroll {
	overflow-y: auto;
	flex-grow: 1;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0.75;
	background: Colors.$full-black !important; // override .module__canvas main > section > *:not(header):not(.no-background)
	z-index: calc($panelZIndex - 1);
}