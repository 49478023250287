@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.surveillanceEscalationsProcessed {
  width: 100%;

  &Cell {
    flex: 1;
    align-items: center;
    gap: Tools.asRem(Spacing.$gutter-small);

    &ProgressBar {
      flex: 1;
      background-color: Colors.$ob-brand-black;
    }

    &Label {
      min-width: Tools.asRem(48px);
      justify-content: right;
    }
  }
}
