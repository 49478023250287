@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

img {
    &.country-flag {
        aspect-ratio: 3.4 / 2.6;
        border-radius: 1px;
        height: 16px;
        outline: 1px solid Colors.$text-tertiary;
        outline-offset: 1px;
        // width: 22px;
        
    }
}


figcaption {
    
    flex-direction: column;
    gap: Tools.asRem(4px);

    :not(strong) {
        // border: 1px solid red;
        color: Colors.$text-secondary;
        display: inline-block;
        font-size: Tools.asRem(11px);
        line-height: Tools.asRem(12px);
        // color: red;
    }

    & strong {
        font-size: Tools.asRem(13px);
        display: block;
        font-weight: 700;
    }
}
