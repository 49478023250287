@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.header {
    display: grid;
    grid-template-columns: Tools.asRem(184px) Tools.asRem(236px) Tools.asRem(82px) 1fr Tools.asRem(138px);

    & .p-progress-spinner {
        height: 36px;
        width: 36px;
    }
}

.reports-page {
    background-color: var(--ob-black);
    display: flex;
    flex: 1;
}

.reports-menu {
    display: flex;
    flex-direction: column;
    width: Tools.asRem(250px);
    background: var(--panel-background);

    .menu-item-container {
        display: flex;
        flex-direction: row;
    }

    .report-folder {
        background-color: var(--column-header-background);
        padding: Tools.asRem(Spacing.$gutter-small);
    }

    .report-item {
        flex: 1;
        padding: Tools.asRem(Spacing.$gutter-small);
        cursor: pointer;

        >label {
            cursor: pointer;
        }

        &:hover {
            background: var(--full-grey) !important;
            color: var(--text-primary);
        }

    }

    .active {
        width: 0.625rem;
        height: 0.625rem;
        background-color: var(--grass);
        border-radius: 2px;
        margin-top: Tools.asRem(Spacing.$gutter-small + 5);
        margin-left: Tools.asRem(Spacing.$gutter-small);
    }

}

.reports-viewer {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 0;
}

.report-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.nav {
    align-items: center;
    justify-content: space-between;
    padding-top: Tools.asRem(Spacing.$gutter-small);
}