@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/tokens" as Tokens;
@use "src/design-system/tools" as Tools;

$width-add-position-button: 138px;

.worksheet-tabs {

	gap: Tools.asRem(Spacing.$gutter-micro);
	flex-grow: 1;
	display: grid;
	height: Tools.asRem(44px);
	max-height: Tools.asRem(44px);
	border-bottom: 1px solid var(--full-black);
	padding-left: Tools.asRem(Spacing.$gutter-large);

	& > button,
	& > div > button {
		margin: Tools.asRem(Spacing.$gutter-small) 0;
	}

	&.overflowing {

		grid-template-columns: 36px 72px auto minmax(36px, 1fr) auto;

		& > div:nth-child(2),
		& > div:nth-last-child(2) {
			position: relative;
		}

		& > div:nth-child(2)::after,
		& > div:nth-last-child(2)::before {
			background: Tokens.$inner-graident-shadow;
			border-left: 1px solid Colors.$charcoal;

			bottom: Tools.asRem(4px);
			content: "";
			position: absolute;
			top: 0;
			width: Tools.asRem(Spacing.$gutter-large);
			z-index: 1;
		}

		& > div:nth-child(2) {
			//	chevron buttons…
			&::after {
				right: -16px;
			}

			padding-right: Tools.asRem(Spacing.$gutter-micro);
		}

		& > div:nth-last-child(2) {
			//	container for the add worksheet button
			&::before {
				left: -16px;
				transform: rotate(180deg);
			}

			padding-left: Tools.asRem(Spacing.$gutter-micro);

			// & > button:first-child { @include Tools.dotted-border($edge: right); }
		}
	}

	&:not(.overflowing) {
		grid-template-columns: 36px auto minmax(36px, 1fr) auto;
	}

	&__container {

		padding-top: Tools.asRem(Spacing.$gutter-small);
		padding-left: Tools.asRem(Spacing.$gutter-medium);
		overflow: hidden;

		& > div:not(.worksheet-tab--active) {
			border-bottom: 1px solid Colors.$full-black
		}
	}

	& > div:last-child {
		padding: 0 Tools.asRem(Spacing.$gutter-medium) 0 Tools.asRem(Spacing.$gutter-micro);
	}

}

.worksheet-tab {

	align-items: center;
	padding: Tools.asRem(Spacing.$gutter-small) 0 Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
	color: Colors.$text-secondary;
	height: Tools.asRem(36px);

	&:not(.worksheet-tab--editing-mode) {
		max-width: Tools.asRem(196px);
	}

	&--active {
		border: 1px solid Colors.$full-black;
		background-color: Colors.$charcoal;
		border-bottom-width: 0;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	& label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:not(:has(+ .worksheet-tab--active)):not(.worksheet-tab--active) button:last-child {
		//	Any tab which isnt immediately followed by an item in active state
		@include Tools.dotted-border($edge: right);
	}

}
