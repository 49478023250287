@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.tm-toast,
.p-toast.tm-toast {
  opacity: 1;
  position: absolute;
  top: Tools.asRem(125px);
  right: Spacing.$gutter-large;
  width: auto;
  --toast-indent: 0px;

  > div {
    display: flex;
    flex-direction: column;
    gap: Spacing.$gutter-large;
  }

  .p-toast-message {
    display: flex;
    border-radius: Tools.asRem(3px);
    border: 1px solid Colors.$full-black;
    background: Colors.$charcoal;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    margin: 0;

    .p-toast-message-content {
      display: flex;
      height: 100%;
      padding: 0;
      border-width: 0;

      .tm-toast-icon {
        height: 100%;
        background-color: Colors.$grass-70;
        display: flex;
        align-items: center;

        &-info {
          background-color: Colors.$indigo;
        }

        &-warn {
          background-color: Colors.$amber;
        }

        &-error {
          background-color: Colors.$ob-brand-red;
        }

        i {
          margin: Spacing.$gutter-medium;
          font-size: Tools.asRem(24px);
          color: Colors.$full-grey;
        }
      }

      .tm-toast-message {
        display: flex;
        flex-direction: column;
        margin: Spacing.$gutter-small;
        margin-left: Spacing.$gutter-medium;
        max-width: Tools.asRem(160px);

        .tm-toast-summary {
          color: Colors.$grass-70;
          font-size: Tools.asRem(11px);
          font-family: OpenSans, sans-serif;
          font-weight: 700;
          line-height: Tools.asRem(15px);
          text-transform: uppercase;
          margin-bottom: Spacing.$gutter-micro;

          &-info {
            color: Colors.$indigo;
          }

          &-warn {
            color: Colors.$amber;
          }

          &-error {
            color: Colors.$ob-brand-red;
          }
        }

        .tm-toast-detail {
          color: Colors.$text-primary;
          font-size: Tools.asRem(13px);
          font-family: OpenSans, sans-serif;
          font-weight: 400;
          line-height: Tools.asRem(18px);
          word-break: break-word;
        }
      }

      .p-toast-icon-close {
        color: Colors.$text-secondary;
        width: Tools.asRem(24px);
        height: Tools.asRem(24px);
        margin-top: Spacing.$gutter-small;
        margin-right: Spacing.$gutter-small;

        svg {
          width: Tools.asRem(12px);
          height: Tools.asRem(12px);
        }
      }
    }
  }
}
