@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.p-contextmenu {
  // padding: $verticalMenuPadding;
  background: $overlayMenuBg;
  color: Colors.$text-secondary;
  border: 1px solid Colors.$full-black;
  box-shadow: $overlayMenuShadow;
  border-radius: $borderRadius;
  // width: $menuWidth;

	& ul {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: Tools.asRem(Spacing.$gutter-micro);

    &:not(.p-dropdown-items) {
      margin-left: 0;
    }
  }

  // & li { padding-right: Tools.asRem(Spacing.$gutter-large * 2); }


  // .p-menuitem-link {
  //     @include menuitem-link();
  // }

  .p-submenu-list {
    padding: $verticalMenuPadding;
    background: $overlayMenuBg;
    border: $overlayMenuBorder;
    box-shadow: $overlayMenuShadow;
    border-radius: $borderRadius;
  }

  .p-menuitem {
    &:hover {
      background: Colors.$slate-90;

      .p-menuitem-content,
      .p-menuitem-icon,
      .p-menuitem-text,
      .p-submenu-icon {
        color: Colors.$ob-brand-black;
      }

      .p-checkbox {
        border-color: Colors.$ob-brand-black;
      }
    }

    .menuitem-tooltip-icon {
      display: flex;
      align-items: center;
      padding-right: Tools.asRem(Spacing.$gutter-small);
    }

    &.p-menuitem-active {
      > .p-menuitem-link {
        // background: $menuitemActiveBg;

        .p-menuitem-text {
          // color: $menuitemTextActiveColor;
        }

        .p-menuitem-icon, .p-submenu-icon {
          // color: $menuitemIconActiveColor;
        }
      }
    }
  }

  .p-menu-separator {
    border-top: $divider;
    margin: $menuSeparatorMargin;
  }

  .p-submenu-icon {
    font-size: $menuitemSubmenuIconFontSize;
    transition: transform $transitionDuration;
  }
}

.p-menuitem {

  &-link {
    // border: 1px solid red;
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large * 2) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small);
    gap: Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-secondary;

    .p-menuitem-active & {
      color: Colors.$ob-brand-black;
    }
  }

  &-active {
    background-color: Colors.$slate-90;
  }

  & .p-disabled { opacity: .46;}

}
