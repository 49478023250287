@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

$overlayZIndex: 1000;

.editable-content__container--overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: $overlayZIndex;
}

.editable-content__container {
  z-index: calc($overlayZIndex + 1); // make sure that container is above the overlay
  gap: Tools.asRem(Spacing.$gutter-micro);
  position: relative;
  
  // container with edit button (not in edit state) should be below the overlay
  &:has(> .button--edit) {
    z-index: calc($overlayZIndex - 1);
  }

  .button--edit {
    text-transform: none;
    border: none;
    font-family: OpenSans;
    font-size: Tools.asRem(13px);

    &:not(:hover) {
      color: Colors.$text-primary;
    }
  }

  .button--save {
    height: Tools.asRem(36px);
    background-color: Colors.$text-only-button-background !important;
  }


  .p-dropdown {
    height: Tools.asRem(36px);
  }
}