@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.legHeader {
  border-bottom: 1px solid Colors.$datatable-header-border;

  div {
    border: 1px solid Colors.$datatable-header-border;
    border-bottom: none;
    background: Colors.$column-header-background;
    width: fit-content;
    padding: 0 Tools.asRem(Spacing.$gutter-medium);
    gap: Tools.asRem(Spacing.$gutter-small);
    cursor: pointer;
    color: Colors.$text-secondary;
    height: Tools.asRem(32px);
    align-items: center;

    font-family: Panton;
    font-size: Tools.asRem(14px);
    font-weight: 700;
    text-transform: uppercase;
  }
}