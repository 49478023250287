@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

main.distlist-container,
.module__canvas > main.distlist-container {
  padding: 0;
  margin: 0;
  gap: 0;
  display: flex;
  flex-direction: row;

  section {
    overflow: hidden;
  }

  &-table {
    border: 0;
    padding: 0;
    background-color: transparent;

    .distlist__table--distLists {
      margin: 0 Tools.asRem(Spacing.$gutter-medium);

      tr {
        display: grid;
        grid-template-columns: 1fr .5fr minmax(0, 1fr);
        align-content: center;

        &.p-selectable-row:not(.p-highlight):not(:hover)[tabindex="0"] {
          outline: none;
        }
      }

      td {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        max-width: 100%;

        > div {
          display: block;

          > span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
            max-width: 100%;
          }
        }
      }

      th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        max-width: 100%;

        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          > span.p-column-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .distlist__table--single {
      tr {
        grid-template-columns: repeat(2, 1fr) repeat(3, .5fr);
      }
    }
  }

  .not-available__container {
    font-style: italic;
    color: Colors.$text-secondary;
  }

  .distlist-empty > div:not(.lds-ripple) {
    flex-direction: column;
    flex-grow: 1;
    background-color: Colors.$panel-background;
    border: 1px solid Colors.$panel-border-color;
    border-radius: Tools.asRem(4px) Tools.asRem(4px) 0 0;
    margin: 0 Tools.asRem(Spacing.$gutter-medium);
    justify-content: center;
    align-items: center;
    gap: Tools.asRem(Spacing.$gutter-large);

    i:before {
      width: Tools.asRem(150px);
      height: Tools.asRem(150px);
      color: Colors.$text-secondary;
    }

    h2 {
      font-family: Panton, OpenSans, sans-serif;
      font-size: Tools.asRem(18px);
      line-height: Tools.asRem(22px);
      font-weight: 400;
      text-transform: uppercase;
      color: Colors.$text-secondary;
      margin: 0 Tools.asRem(Spacing.$gutter-large);
      text-align: center;
    }

    button {
      margin: Tools.asRem(Spacing.$gutter-large) 0;
      justify-content: center;
    }
  }

  .distlist-side-panel {
    margin-right: Tools.asRem(Spacing.$gutter-medium);
  }

  .distlist__view {
    border: 0;
    border-radius: 0;
    background-color: Colors.$ob-brand-black;

    @media only screen and (max-width: 960px) {
      margin: Tools.asRem(Spacing.$gutter-small);
      border-radius: Tools.asRem(4px) Tools.asRem(4px) 0 0;
    }

    .p-dataview-content {
      background-color: transparent;
      flex-grow: 1;

      .p-grid {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: Tools.asRem(Spacing.$gutter-small);

        .list-element {
          cursor: pointer;
          background-color: Colors.$panel-background;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          border: 1px solid Colors.$full-black;
          border-radius: Tools.asRem(3px);
          padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);

          &--urgent .grid-element__body {
            color: Colors.$ob-brand-red;
          }

          &:hover,
          &:focus {
            background: Colors.$charcoal;
          }

          &-slanted {
            font-style: italic;
            color: Colors.$text-secondary;
          }
        }
      }
    }

    &.distlist__messages {
      .list-element {
        grid-template-columns: repeat(3, 1fr);
      }

      .grid-element {
        border: 0;
        &:nth-child(1) { grid-area: 1 / 1 / 2 / 4; @include Tools.dotted-border($edge: bottom); border: 0; }
        &:nth-child(2) { grid-area: 2 / 1 / 3 / 2; @include Tools.dotted-border($edge: right); border: 0; }
        &:nth-child(3) { grid-area: 2 / 2 / 3 / 3; @include Tools.dotted-border($edge: right); border: 0; }
        &:nth-child(4) { grid-area: 2 / 3 / 3 / 4; border: 0; background-image: none; padding: 0 Tools.asRem(Spacing.$gutter-small); }
        &:nth-child(5) {
          grid-area: 3 / 1 / 4 / 4;
          border-right: none;
          @include Tools.dotted-border($edge: top);
          padding: 0;

          .grid-element__body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: left;
            padding: Tools.asRem(Spacing.$gutter-small) 0 0 Tools.asRem(Spacing.$gutter-small);
            font-family: OpenSans, sans-serif;
            font-style: italic;
            font-size: Tools.asRem(11px);
            line-height: Tools.asRem(15px);
            font-weight: 400;
            color: Colors.$text-secondary;

            button {
              height: Tools.asRem(34px);
            }
          }
        }
      }
    }

    &.single-data__view {
      .p-dataview-content .p-grid .list-element {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .grid-element {
          &:nth-child(1) { grid-area: 1 / 1 / 2 / 3; @include Tools.dotted-border($edge: bottom); border: 0; }
          &:nth-child(2) { grid-area: 2 / 1 / 3 / 2; @include Tools.dotted-border($edge: right); border: 0; }
          &:nth-child(3) { grid-area: 2 / 2 / 3 / 3; border: 0;@include Tools.dotted-border($edge: bottom); }
          &:nth-child(4) { grid-area: 3 / 1 / 4 / 2; border: 0; @include Tools.dotted-border($edge: top);  }
          &:nth-child(5) { grid-area: 3 / 2 / 4 / 3; border: 0; @include Tools.dotted-border($edge: left); }

          &:nth-child(2) .grid-element__body,
          &:nth-child(4) .grid-element__body {
            display: block;
            @include Tools.truncated; }
        }
      }
    }
  }

  .grid-element {
    display: flex;
    flex-direction: column;
    padding: 0 Tools.asRem(Spacing.$gutter-small);
    cursor: pointer;

    &:nth-child(1) { grid-area: 1 / 1 / 2 / 3; @include Tools.dotted-border($edge: bottom); border: 0; }
    &:nth-child(2) { grid-area: 2 / 1 / 3 / 2; @include Tools.dotted-border($edge: right); border: 0; }
    &:nth-child(3) { grid-area: 2 / 2 / 3 / 3; border: 0; }
    &:nth-child(4) { grid-area: 3 / 1 / 4 / 3; border: 0; @include Tools.dotted-border($edge: top); }

    &__header {
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(11px);
      font-weight: 700;
      line-height: Tools.asRem(15px);
      text-align: left;
      color: Colors.$text-secondary;
      text-transform: uppercase;
    }

    &__body {
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(13px);
      font-weight: 400;
      line-height: Tools.asRem(18px);
      text-align: left;
      color: Colors.$text-primary;
      padding: Tools.asRem(Spacing.$gutter-small) 0;

      .cell--unit {
        flex-grow: 1;
        justify-content: space-between;

        :nth-child(2) {
          font-weight: 700;
        }
      }
    }
  }
}

.distlist-header,
.module__canvas .distlist-header {
  box-shadow: none;
  padding: Tools.asRem(Spacing.$gutter-large);
  margin-bottom: Tools.asRem(Spacing.$gutter-large);
}

.distlist-add.p-button.p-button-sm {
  padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
  line-height: Tools.asRem(18px);
  border: 0;
  border-radius: Tools.asRem(4px);
}

.distlist-toast.p-toast.tm-toast {
  top: Tools.asRem(Spacing.$gutter-large);
}

@media only screen and (max-width: 960px) {
  main.distlist-container,
  .module__canvas main.distlist-container {
    gap: unset;
    margin-bottom: Tools.asRem(50px);

    .distlist-empty > div:not(.lds-ripple) {
      margin: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small) 0 Tools.asRem(Spacing.$gutter-small);
    }
  }

  .module__canvas:has(> footer) > footer.distlist-footer,
  .module__canvas:has(> footer) > footer.distlist-dl-panel__footer,
  .module__canvas main > aside > *:not(header):not(.p-tabview):not(section):not(div:first-child).distlist-dl-panel__footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 3;
    bottom: 0;
    margin: 0;
    padding: Tools.asRem(Spacing.$gutter-small);
    border-top: 1px solid Colors.$full-black;
    background: Colors.$panel-background;

    button {
      width: 100%;
      justify-content: center;
    }
  }

  .module__canvas .distlist-container {
    overflow: visible;
    margin: 0;
    padding: 0;
    margin-bottom: Tools.asRem(50px);
    border: 0;
    border-radius: 0;

    &.drawer--active {
      overflow-y: scroll;
      margin-bottom: 0;
    }

    > section {
      container: none;
      flex-grow: 1;
    }
  }

  nav.distlist-navigation  {
    padding: Tools.asRem(Spacing.$gutter-small);
  }

  .distlist-header, .module__canvas:has(>header)>header.distlist-header {
    border-bottom: 1px solid Colors.$full-black;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
  }

  .p-dataview-emptymessage,
  .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: 0;
  }
}
