@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.sanctioned-individual {
    &__other-info {
        word-break: break-word;
        padding: Tools.asRem(Spacing.$gutter-small);
    }

    &__additional-info {
        .p-datatable-tbody tr td:not(.no--padding) {
            padding-bottom: Tools.asRem(Spacing.$gutter-micro);
        }
    }

    &__other-info, &__additional-info {
        a {
            word-break: break-all;
        }
    }
}