@use "colors" as Colors;
@use "spacing" as Spacing;
@use "../tools" as Tools;


%font-headings {
	font-family: Panton;
	font-weight: 600;
}

%heading-major {
	@extend %font-headings;
	
	font-size: Tools.asRem(18px);
}

%heading-minor {
	@extend %font-headings;
	
	font-size: Tools.asRem(14px);
}


@mixin heading-major {
	@extend %heading-major;
}
@mixin heading-minor {
	@extend %heading-minor;
	color: Colors.$text-secondary;
}


%general-subtitle-small {
	@extend %font-headings;
	font-size: Tools.asRem(11px);

	color: Colors.$text-secondary;
	text-transform: uppercase;
}

@mixin general-subtitle-small {
	@extend %general-subtitle-small;
}

%definition-label {
	font-family: 'OpenSans';
	color: Colors.$text-secondary;
	font-size: Tools.asRem(11px);
	font-weight: 800;
	text-transform: uppercase;
}

@mixin definition-label {
	@extend %definition-label;
}