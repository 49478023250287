@use 'sass:math';

@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/animations" as Animation;
@use "src/design-system/globals/timings" as Timing;

$switch-height: 22px;
$button-height: 14px;

.p-inputswitch {
    width: Tools.asRem(42px);
    height: Tools.asRem($switch-height);
    position: relative;
    display: inline-block;

    .p-inputswitch-slider {
        background: Colors.$full-grey;
		border: 1px solid Colors.$slate;
        transition: $formElementTransition;
        border-radius: $inputSwitchBorderRadius;
		outline: 0;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &:before {
            background: Colors.$slate-80;
            width: Tools.asRem($button-height);
			aspect-ratio: 1/1;
            left: Tools.asRem(Spacing.$gutter-micro);
            margin-top: math.div(-1 * $button-height, 2);
            border-radius: 8px;
            transition-duration: $transitionDuration;
            position: absolute;
            content: "";
            top: 50%;
        }
    }

    &.p-highlight {
        .p-inputswitch-slider {
            background: Colors.$charcoal;

            &:before {
                transform: translateX(19px);
                background: Colors.$ob-brand-orange;
            }
        }
    }

	&:not(.p-disabled):hover,
    &.p-focus {
        .p-inputswitch-slider {
			border: 1px solid Colors.$text-primary;
        }
    }

    &.p-invalid {
        .p-inputswitch-slider {
            @include invalid-input();
        }
    }

    .p-inputswitch-input {
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 1;
        outline: 0 none;
        cursor: pointer;
    }
}