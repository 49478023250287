@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg:$panelContentBg;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: Colors.$ob-brand-black;

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: 1px dashed Colors.$slate-90;

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: Colors.$text-secondary;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 700; // 700 not defined for Panton so will fallback to 600

/// Font family of a dialog header
/// @group overlay
$dialogHeaderFontFamily: Panton, OpenSans, sans-serif;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: Tools.asRem(24px);

/// Line height of a dialog header
/// @group overlay
$dialogHeaderLineHeight: Tools.asRem(30px);

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 0 0 Tools.asRem(Spacing.$gutter-small);

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 0 1.5rem;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: 1px solid #dee2e6;

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 1.5rem;

/// Padding of a confirmpopup content
/// @group overlay
$confirmPopupContentPadding:$panelContentPadding;

/// Padding of a confirmpopup footer
/// @group overlay
$confirmPopupFooterPadding:0 1rem 1rem 1rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: $textColor;

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: #ffffff;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: $inputPadding;