@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;

.cargo-edit__container {
  flex-grow: 1;
  overflow: hidden;

  .cargo-edit__form {
    flex-grow: 1;

    @media (max-width: 960px) {
      flex-grow: 0;
    }

    .cargo-edit__location-dropdown {
      flex: 0 0 35%;
      width: 0;

      .p-dropdown-label {
        padding-right: 0;
      }
    }

    > .form-input__container {
      align-items: normal; // Overwrite IEA mods align-items: center
    }

    // fix styles as dropdown looks bad when field is invalid
    .p-invalid {
      .cargo-edit__location-dropdown {
        &:not(.p-disabled).p-focus {
          outline: none;
          border: none;
        }

        .p-dropdown-label {
          border-right: none;
        }
      }
    }
  }

  header {
    font-family: Panton, OpenSans, sans-serif;
    font-size: Tools.asRem(18px);
    font-weight: 400;
    line-height: Tools.asRem(24px);
    text-align: left;
    padding: Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-secondary;
    border-bottom: 1px solid Colors.$full-black !important;
    min-height: Tools.asRem(40px) !important;
  }

  form {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    flex-grow: 1;
    overflow-y: auto;
    flex-direction: column;

    .p-disabled {
      opacity: .36;
    }

    label {
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(13px);
      font-weight: 700;
      line-height: Tools.asRem(18px);
      text-align: left;
    }

    .autocomplete-container .p-inputtext {
      padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);

      &:before {
        display: none;
      }
    }

		// TODO - remove this. We shouldn't be making style changes to a component
		// at such a local level
    // .p-dropdown {
    //   min-height: Tools.asRem(36px);
    //   background: Colors.$full-grey;
    // }

    .ce-visibility__container {

      > label {
        display: flex;

        i {
          padding-left: Tools.asRem(Spacing.$gutter-micro);
        }
      }

      .form-input--radio-buttons {
        height: Tools.asRem(34px);
      }
    }

    small.message-invalid {
      color: Colors.$ob-brand-red;
      display: inline-block;
      overflow: hidden;
    }

    .p-inputtext.p-invalid.p-component {
      border-color: Colors.$ob-brand-red;
      color: Colors.$ob-brand-red;
    }

    .spot-code-input {
      max-width: none;
      padding-right: 0;
      height: auto;
      border: 0;

      input {
        border: 1px solid Colors.$full-black;
      }
    }
  }

  footer.cargo-edit__footer {
    justify-content: space-between;
    background-color: Colors.$charcoal;
    border-top: 1px solid Colors.$full-black;
    flex-direction: row;

    @media (max-width: 960px) {
      flex-direction: column;
      align-items: stretch;

      button {
        justify-content: center;
        font-family: Panton, OpenSans, sans-serif;
        font-weight: normal;
        font-size: Tools.asRem(16px);
        line-height: Tools.asRem(18px);
        width: auto;

        &.ce-mobile-cancel,
        &.ce-mobile-cancel > span:before {
          color: Colors.$ob-brand-orange;
        }
      }
    }
  }

  .cargo-edit-delete__dialog.p-dialog-default {
    border: 0;

    h4 {
      color: Colors.$ob-brand-red;
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(16px);
      font-style: normal;
      font-weight: 700;
      line-height: Tools.asRem(18px);
    }
  }
}

.cargo-edit__autocomplete,
.cargo-edit__autocomplete.p-autocomplete-panel.autocomplete-entity {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 30dvh !important;

  .item-template {
    display: flex;
    grid-template-columns: none;
  }

  &:not(.empty) .p-autocomplete-item  {
    $clamp-min: Tools.asRem(112px);
    $clamp-middle: Tools.asRem(125px);
    $clamp-max: Tools.asRem(148px);
    padding-left: clamp($clamp-min, $clamp-middle, $clamp-max);
  }

  .p-autocomplete-items-wrapper {
    overflow: visible;

    .p-autocomplete-items {
      .p-autocomplete-item {
        font-family: OpenSans, sans-serif;
        font-size: Tools.asRem(13px);
        font-weight: 400;
        line-height: Tools.asRem(18px);
        min-height: Tools.asRem(26px);
        text-align: left;

        &:has(.item-template--caption) {
          min-height: Tools.asRem(34px);
        }
      }

      .p-autocomplete-item-group {
        font-family: OpenSans, sans-serif;
        font-size: Tools.asRem(13px);
        font-weight: 400;
        line-height: Tools.asRem(18px);
        text-align: left;
        color: Colors.$text-primary;
      }

      .item-template {
        font-weight: 400;
      }
    }
  }

  @media (max-width: 960px) {
    &:not(.empty) .p-autocomplete-item  {
      padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
    }

    .p-autocomplete-item-group + li {
      margin-top: 0;
    }

    .p-autocomplete-item-group:not(:first-child) {
      margin-top: 0;
    }
  }
}

.p-autocomplete-panel.tc-charterer,
.p-autocomplete-panel.spot-charterer {
  .item-template {
    grid-template-columns: minmax(min-content, Tools.asRem(148px)) minmax(min-content, Tools.asRem(112px));
    column-gap: Tools.asRem(Spacing.$gutter-large);
  }

  .item-template-label {
    white-space: break-spaces;
  }
}


