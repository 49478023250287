@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/fonts' as Fonts;

$tab-height: Tools.asRem(20px);

.button {

	@include Fonts.general-subtitle-small;

	align-items: center;
	transform: rotate(180deg);
	background-color: Colors.$column-header-background;
	border: 1px solid Colors.$panel-border-color;
	border-bottom-width: 0;
	border-radius: 3px 3px 0 0;

	width: fit-content;
	height: $tab-height;

	padding: 0 Tools.asRem(Spacing.$gutter-small);
	text-transform: uppercase;
	white-space: nowrap;

	& label { 
		align-items: center;
		cursor: pointer;
	}

	& > label::before {
		margin-right: Tools.asRem(Spacing.$gutter-micro);
	}

	& > span:first-child {
		transition: transform .265s ease-in-out;
		transform: rotate(0);
	}
}

.ste > span:first-child {
	transform: rotate(90deg);
}

.tab {

	position: absolute;
	gap: Tools.asRem(Spacing.$gutter-small);
	position: absolute;
	right: 0;

	transform-origin: bottom right;
	transform: rotate(-90deg);

	top: 0 - $tab-height;
	flex-direction: row;
}