@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;

textarea,
input {
	font-family: OpenSans;
  border-radius: 3px;

	&:-webkit-autofill {
		//	The dribbler on the webkit team that was arrogant enough to think they can
		//	decide BY DEFAULT what autocomplete entered text looks like needs a quite word
		//	uttering in their ear.
		//	Happily enough though. We can override their monumentally stupid design decsions
		background: Colors.$full-grey;
		-webkit-text-fill-color: Colors.$text-secondary;
		-webkit-box-shadow: 0 0 0px 1000px Colors.$full-grey inset;
	}

	&.p-inputtext {
		padding: Spacing.$gutter-small;
		flex-grow: 1;

	}

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

	&.p-inputnumber-input {
		text-align: end;
	}
}



.p-inputtext:not(.p-dropdown *) {
    color: Colors.$text-secondary;
    background: Colors.$full-grey;
    border: $inputBorder;
    transition: $formElementTransition;
    appearance: none;

    &::selection {
        background: Colors.$indigo-90;
        color: Colors.$text-primary;
    }

    @media only screen and (max-width: 960px) {
        font-size: 1rem;
    }

    &:enabled:hover {
        border-color: Colors.$input-border-hover;
    }

    &:enabled:focus {
        // @include focused-input();
        outline: 0;
        border-color: Colors.$slate-70;
    }

    &.p-invalid.p-component {
        border-color: Colors.$ob-brand-red;
				color: Colors.$ob-brand-red;
    }

    &.p-inputtext-sm {
        // @include scaledFontSize($inputTextFontSize, $scaleSM);
        // @include scaledPadding($inputPadding, $scaleSM);
    }

    &.p-inputtext-lg {
        @include scaledFontSize($inputTextFontSize, $scaleLG);
        @include scaledPadding($inputPadding, $scaleLG);
    }
}

.p-float-label > label {
    left: nth($inputPadding, 2);
    color: $inputPlaceholderTextColor;
    transition-duration: $transitionDuration;
}

.p-float-label > label.p-error {
    color: $errorColor;
}

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
    left: Spacing.$gutter-medium;
    color: $inputIconColor;
}

.p-input-icon-left > .p-inputtext {
    padding-left: Spacing.$gutter-large * 2.5;
}

.p-input-icon-left.p-float-label > label {
    left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
    right: nth($inputPadding, 2);
    color: $inputIconColor;
}

.p-input-icon-right > .p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

// @include placeholder {
//     color: $inputPlaceholderTextColor
// };

.p-input-filled {
    .p-inputtext {
        background-color: $inputFilledBg;

        &:enabled:hover {
            background-color: $inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: $inputFilledFocusBg;
        }
    }
}

.p-inputtext-sm {
    .p-inputtext {
        @include scaledFontSize($inputTextFontSize, $scaleSM);
        @include scaledPadding($inputPadding, $scaleSM);
    }
}

.p-inputtext-lg {
    .p-inputtext {
        @include scaledFontSize($inputTextFontSize, $scaleLG);
        @include scaledPadding($inputPadding, $scaleLG);
    }
}