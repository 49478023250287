@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use 'src/design-system/globals/fonts' as Fonts;

.sanctions-table--detail {

    // width: 80%;

    & tr {
        grid-template-columns:
            minmax(Tools.asRem(164px), 1fr)
            minmax(Tools.asRem(116px), .226fr);
    }
}


header.definition-list__header {
    @include Fonts.heading-minor;
    font-weight: 400;
    padding: 0 Tools.asRem(Spacing.$gutter-small);
}

.sanction-details__no-data-ribbon {
    justify-content: start;
    gap: Tools.asRem(Spacing.$gutter-small);

    &.p-inline-message {
        border: none;
        border-radius: 0;
    }

    .p-inline-message-text {
        font-size: Tools.asRem(13px);
        font-weight: 700;
    }
}