@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.switchable-unit-field {
    align-items: center;
    gap: Tools.asRem(Spacing.$gutter-small);
    
    &__dropdown {
        .p-dropdown-trigger {
            width: auto;
        }

        .p-dropdown-label {
            padding: Tools.asRem(Spacing.$gutter-micro);
        }

        &.p-dropdown:not(.p-disabled):hover,
        &.p-dropdown:not(.p-disabled).p-focus {
            border-color: Colors.$slate-70;
            border-left: 1px solid Colors.$slate-70; // restore left border for inputgroup-addon
            outline: none;
        }
    }

    &__input {
        &,
        .p-inputtext {
            text-align: end;
        }
    }
}