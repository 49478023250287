@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(2 * Tools.asRem(Spacing.$gutter-large));
  max-width: Tools.asRem(Spacing.$empty-page-max-width);
  height: 100%;
  margin: auto;
  padding: 0;

  @media only screen and (max-width: 960px) {
    padding: Tools.asRem(Spacing.$gutter-small);
  }
}

.image {
  width: min(Tools.asRem(365px), 70%);
  max-height: min(300px, 40%);
  margin-bottom: calc(2 * Tools.asRem(Spacing.$gutter-medium));

  @media only screen and (max-width: 960px) {
    width: Tools.asRem(225px);
    margin-bottom: 0;
  }
}

.content {
  align-items: center;
  flex-direction: column;
  gap: Tools.asRem(Spacing.$gutter-large);

  h1, p {
    color: Colors.$text-secondary;
  }

  h1 {
    text-transform: uppercase;
  }

  p {
    text-align: center;
  }
}
