@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;

.container {
  flex-direction: column;
  flex: 1 1 100%;
}

.snapshot {
  margin: 0;

  :global {
    .definition-list__container {
      overflow-y: auto;
    }

    .individual-sanction-detail--container:first-of-type {
      .p-datatable-thead > tr {
        border-top: none;
      }
    }
  }
}

.loader {
  position: relative;
  overflow: hidden;

  &:not(:empty) {
    min-height: Tools.asRem(2px);
  }
}