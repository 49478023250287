@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Color;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/animations" as Timings;

.rate-grids--functions {
	gap: Tools.asRem(Spacing.$gutter-small);
	
	@media only screen and (min-width: 960px) { 
		gap: Tools.asRem(Spacing.$gutter-large);
	}
	
	justify-content: flex-end;
	padding: 0 Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-large);
	align-items: center;
}