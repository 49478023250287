@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

.section {
  width: Tools.asRem(442px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: Spacing.$gutter-large !important;
}

.icon {
  &:before {
    width: Tools.asRem(150px) !important;
    height: Tools.asRem(150px) !important;
    color: Colors.$text-secondary;
  }
}

.header {
  font-family: Panton, OpenSans, sans-serif;
  font-size: Tools.asRem(18px);
  line-height: Tools.asRem(22px);
  font-weight: 400;
  text-transform: uppercase;
  color: Colors.$text-secondary;
  margin: 0 Tools.asRem(Spacing.$gutter-large);
  text-align: center;
  white-space: pre-line;
}
