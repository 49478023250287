@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.leg-parameters {
    &__container {
        flex-direction: column;
        gap: Tools.asRem(Spacing.$gutter-small);

        padding-top: Tools.asRem(Spacing.$gutter-large);
        padding-bottom: Tools.asRem(Spacing.$gutter-small);

        .weather-margin__container,
        .form-input__container,
        .hours-in-port-field__container {
            gap: Tools.asRem(Spacing.$gutter-small);

            > div,
            > span {
                flex: 1;
            }

            label {
                flex: 0 0 50%;
                align-self: center;
                color: Colors.$text-primary;
                font-weight: normal;
            }

            .p-dropdown {
                flex: 0 0 50%;
            }

            .p-inputgroup-addon {
                color: Colors.$text-secondary;
            }

            .p-inputtext {
                text-align: end;
            }
        }

        // override SpeedField styles
        .form-input__container {
            flex-direction: row;

            label {
                font-size: Tools.asRem(13px);
                color: Colors.$text-secondary;

                strong {
                    color: Colors.$text-primary;
                    font-weight: normal;
                }
            }
        }
    }

}