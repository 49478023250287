@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/animations" as Timings;


.mod-data {

	&__grid {

		& .p-rowgroup-footer { display: none;}

		& .p-rowgroup-header { margin-top: Tools.asRem(Spacing.$gutter-small)}

		& tr:not(.p-datatable-emptymessage) {
			grid-template-columns: Tools.asRem(42px) Tools.asRem(128px) Tools.asRem(86px) repeat(var(--columns), Tools.asRem(148px)) minmax(Tools.asRem(162px), 1fr);
		}

		&.no--selection {
			
			& tr:not(.p-datatable-emptymessage) {

				//	JODI data tables initially have no historical charting. So we should loose the
				//	42px column at the start
				grid-template-columns: Tools.asRem(216px) Tools.asRem(86px) repeat(var(--columns), Tools.asRem(148px)) minmax(Tools.asRem(162px), 1fr);
			}
		}

		& tr:has(+ .p-rowgroup-footer) {
			border-bottom: 2px solid Colors.$ob-brand-black !important;
		}

		&--extrawide {
			
			& tr:not(.p-datatable-emptymessage) {
			grid-template-columns: Tools.asRem(42px) Tools.asRem(216px) Tools.asRem(86px) repeat(var(--columns), Tools.asRem(148px)) minmax(Tools.asRem(162px), 1fr);
		}
		}
	}
}
