@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.dropdown {
	flex-grow: 1;
	display: flex;
	// margin: 0 Tools.asRem(Spacing.$gutter-small);
}

.container {
	// flex-direction: column;
	// gap: Tools.asRem(Spacing.$gutter-small);
	// height: fit-content;
}

.menuItem { flex-shrink: 0; }