@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.error-page__container {
    gap: Tools.asRem(56px);
    overflow: auto !important; // override .module__canvas main
    padding: Tools.asRem(Spacing.$gutter-medium) 0;
    max-width: Spacing.$empty-page-max-width;
    margin: auto;
    align-items: center;

    img {
        width: Tools.asRem(345px);
    }

    .error-page__title,
    .error-page__sub-title,
    .error-code__container p {
        color: Colors.$text-secondary;
        text-align: center;
    }

    .error-page__title {
        text-transform: uppercase;
        font-size: Tools.asRem(18px);
        line-height: Tools.asRem(20px);
        font-weight: 400;
    }

    .error-page__sub-title {
        font-size: Tools.asRem(13px);
        line-height: Tools.asRem(18px);
        font-family: OpenSans, sans-serif;
        font-weight: 400;
    }

    .error-page__action {
        gap: Tools.asRem(Spacing.$gutter-large);
        align-items: center;

        .action__buttons {
            margin-top: Tools.asRem(Spacing.$gutter-large);
            gap: Tools.asRem(Spacing.$gutter-large);

            button {
                font-size: Tools.asRem(16px);
                height: Tools.asRem(34px);
                font-weight: 400;
            }
        }
    }

    @media only screen and (max-width: 960px) {
        padding: Tools.asRem(Spacing.$gutter-small);

        img {
            width: Tools.asRem(225px);
        }

        .error-code__container p a {
            display: block;
            width: fit-content;
            margin: auto;
        }
    }
}