@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/helpers/DataTable/Templates/ColumnTemplates/General.module" as General;

.surveillance-results {
  &__table {
    @include Tools.datatable-new-item();

    .p-datatable-wrapper {
      flex-direction: column;
    }

    // center selection checkbox within column
    .p-selection-column {
      flex: 1;
      padding: 0;
      justify-content: center;
      justify-items: center;

      .p-column-header-content {
        flex: 0;
      }
    }

    tr {
      display: grid;
      grid-template-columns: Tools.asRem(48px) Tools.asRem(160px) Tools.asRem(160px) 1fr Tools.asRem(110px) Tools.asRem(180px) 1fr;
      align-content: center;
      cursor: pointer;

      &.p-selectable-row:not(.p-highlight):not(:hover)[tabindex="0"] {
        outline: none;
      }

      &:not(.p-rowgroup-header):not(.p-selected-row).p-highlight,
      &:not(.p-rowgroup-header):not(.p-selected-row).p-highlight .p-frozen-column {
        background-color: unset;

        td span.lower {
          color: Colors.$text-secondary;
        }
      }

      th {
        .p-column-header-content {
          flex: 1;

          .p-column-title:empty {
            display: none;
          }
        }
        .p-column-title {
          width: 100%;
        }
      }
    }

    &-extended {
      tr {
        grid-template-columns: Tools.asRem(48px) Tools.asRem(160px) Tools.asRem(45px) Tools.asRem(160px) 1fr Tools.asRem(110px) Tools.asRem(180px) 1fr;
      }
    }
    &-escalated {
      tr {
        grid-template-columns: Tools.asRem(48px) Tools.asRem(160px) Tools.asRem(168px) 1fr Tools.asRem(100px) Tools.asRem(100px) 1fr Tools.asRem(175px);
      }
    }

    th.intermediate .p-checkbox .p-checkbox-box {
      background-color: Colors.$ob-brand-orange;

      // hide OOB checkbox mark to display horizontal line instead
      svg {
        display: none;
      }

      &:after {
        content: "\002015"; // horizontal line for intermediate state
      }
    }

    .p-datatable-tbody {
      & > tr:not(.p-rowgroup-header) {
        & > .upper {
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(13px);
          font-weight: 700;
          line-height: Tools.asRem(18px);
          color: Colors.$text-primary;
        }

        & > td .lower {
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(11px);
          font-weight: 400;
          line-height: Tools.asRem(15px);
          color: Colors.$text-secondary;
        }
      }

      .p-selection-column {
        min-height: Tools.asRem(34px);
        color: transparent; // When checkbox is hidden with showSelectionElement - primereact writes "false" inside cell
      }

      tr:not(.p-rowgroup-header).p-selected-row .p-selection-column,
      tr:not(.p-rowgroup-header).p-highlight .p-selection-column {
        color: transparent;
      }

      .p-datatable-emptymessage {
        cursor: default;
        color: Colors.$text-primary;

        strong {
          color: Colors.$text-secondary;
          font-family: Panton;
          font-size: Tools.asRem(18px);
        }
      }

      .single-line-sibling {
        display: flex;
        flex-direction: row;

        i {
          margin-right: Tools.asRem(Spacing.$gutter-small);
          margin-left: -1 * Tools.asRem(Spacing.$gutter-micro);
        }
      }
    }

    .p-chip,
    .p-datatable-tbody > tr.p-highlight td > .p-chip,
    .p-datatable-tbody > tr:not(.p-rowgroup-header).p-selected-row td > .p-chip {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      padding: 0 Tools.asRem(Spacing.$gutter-small);
      height: Tools.asRem(24px);

      &.chip-voice {
        background: Colors.$indigo-20;
        border: 1px solid Colors.$indigo;

        span {
          color: Colors.$indigo;
        }
      }

      &.chip-cellular,
      &.chip-sms {
        background: Colors.$tomato-20;
        border: 1px solid Colors.$tomato;

        span {
          color: Colors.$tomato;
        }
      }

      &.chip-whatsappaudio,
      &.chip-whatsappmessage {
        background: Colors.$grass-20;
        border: 1px solid Colors.$grass;

        span {
          color: Colors.$grass;
        }
      }

      span {
        padding: 0;
        margin: 0;
        font-family: OpenSans, sans-serif;
        font-weight: 700;
        font-size: Tools.asRem(13px);
        line-height: Tools.asRem(18px);
      }
    }

    & .spacing {
      &--small {
        gap: Spacing.$gutter-small;
      }
    }

    td,
    td > div,
    td > div > span {
      display: block;
      align-content: center;
      @extend .truncated;
    }

    .not-available__container {
      font-style: italic;
      color: Colors.$text-secondary;
    }

    .content-cell,
    .content-cell > div {
      display: flex;
    }

    &-header {
      position: relative;
      align-items: center;

      $indicator-badge-size: Tools.asRem(22px);
      .p-badge {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;

        height: $indicator-badge-size;
        width: $indicator-badge-size;
        min-width: $indicator-badge-size;

        font-family: OpenSans, sans-serif;
        font-size: Tools.asRem(14px);
        line-height: Tools.asRem(14px);

        color: Colors.$text-primary;
        background-color: Colors.$ob-brand-orange;

        top: Tools.asRem(-10px);
        right: Tools.asRem(-15px);
      }

      .p-overlay-badge {
        flex: 1;
        justify-content: flex-end;
      }

      &-context-menu {
        max-height: Tools.asRem(250px);
        overflow: auto;

        > div > div {
          left: unset;
          top: 100%;
        }
        ul {
          padding: Tools.asRem(Spacing.$gutter-small) 0;

          > li > div {
            padding: Tools.asRem(Spacing.$gutter-small);
            gap: Tools.asRem(Spacing.$gutter-small);

            align-items: center;
          }
        }
      }
    }
  }
}

.sky-badge {
  display: flex;
  padding: Tools.asRem(6px) Tools.asRem(5px);
  border-radius: Tools.asRem(2px);
  border: Tools.asRem(1px) solid Colors.$sky-80;
  font-family: OpenSans, sans-serif;
  font-size: Tools.asRem(13px);
  font-weight: 700;
  line-height: Tools.asRem(16px);
  background-color: Colors.$panel-background;
  color: Colors.$sky-80;
  width: min-content;
  min-width: Tools.asRem(20px);
  max-width: Tools.asRem(28px);
  justify-content: center;
  margin: 0 auto Tools.asRem(1px) auto;

  .p-datatable-tbody > tr:not(.p-rowgroup-header).p-selected-row td & {
    color: Colors.$sky-80;
  }

  i.icon--small::before {
    width: Tools.asRem(16px);
  }
}
