@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/fonts' as Fonts;

.subscription-details__container {
  flex: 1 1 100%;
  overflow: hidden;

  header {
    @include Fonts.heading-major;
    padding: Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-secondary;
  }

  .close-button {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    @include Tools.top-right-element;
  }

  .subscription-details__form {
    padding: Tools.asRem(Spacing.$gutter-small);
    gap: Tools.asRem(Spacing.$gutter-small);
    width: Tools.asRem(540px);
  }

  .subscription-details__valid-products {
    &--list {
      gap: Tools.asRem(Spacing.$gutter-small);
      flex-direction: column;

      &-item {
        gap: Tools.asRem(Spacing.$gutter-small);
        align-items: center;
        justify-content: space-between;

        > div {
          gap: Tools.asRem(Spacing.$gutter-small);
        }

        dd {
          color: Colors.$text-secondary;
        }
      }
    }
  }

  .subscription-details__invalid-products {
    gap: Tools.asRem(Spacing.$gutter-small);
    border: 1px solid Colors.$tomato;
    border-radius: Tools.asRem(3px);
    padding: Tools.asRem(Spacing.$gutter-medium);

    &--label {
      gap: Tools.asRem(Spacing.$gutter-small);
      align-items: center;
      font-size: Tools.asRem(13px);
      font-weight: 700;

      *[class*=iconoir-]::before {
        color: Colors.$tomato;
      }
    }

    &--list {
      flex-direction: column;
      gap: Tools.asRem(Spacing.$gutter-small);
    }
  }

  .subscription-details__products {
    &--header {
      font-size: Tools.asRem(14px);
    }
  }

  .form-input__container {
    flex-direction: row;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;

    .p-radiobutton input, :not(:has(.p-disabled + label)) label {
      cursor: pointer;
    }

    .message-invalid {
      grid-column-start: 2;
    }

    .regions--loader{
      position: relative;
      grid-column: 1/-1; // span on all columns
    }

    .rate-grids--radio-buttons {
      display: grid;
      grid-template-columns: repeat(3, Tools.asRem(22px) Tools.asRem(60px));
      gap: Tools.asRem(Spacing.$gutter-small);
      align-items: center;
      height: Tools.asRem(36px);

      &.p-invalid {
        padding: Tools.asRem(Spacing.$gutter-micro);
      }
    }
  }
}