@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.container {
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-small);

	@media only screen and (max-width: 960px) {
		min-height: 40dvh;
	}
}

.inputContainer {
	align-items: stretch;
}

.label {
	font-size: Tools.asRem(13px) !important;
	line-height: Tools.asRem(18px);
}

.actions {
	flex-direction: row;
	gap: Tools.asRem(Spacing.$gutter-small);
}
