@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use 'src/design-system/globals/fonts' as Fonts;

.container {

	overflow-y: auto;

	& header {
		@include Fonts.heading-minor;
		padding: 0 Tools.asRem(Spacing.$gutter-small);
		justify-content: space-between;

		&:first-child {
			position: sticky;
			top: 0;
			z-index: calc(infinity);
		}

		& button {
			text-decoration: underline;
			flex-direction: row-reverse;
			background-color: transparent !important;
			padding-right: 0 !important;
			font-size: Tools.asRem(12px) !important; 
		}
	}

}

.detail {
	min-height: fit-content;
}