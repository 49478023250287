@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.workflow-name__container {
  .button--edit {
    font-weight: 700;
  }

  .edit-name__input {
    gap: Tools.asRem(Spacing.$gutter-small);
  }
}