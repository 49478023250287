@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.surveillanceTranscriptionEdit {
  color: Colors.$ob-brand-orange !important;
}

.surveillanceTranscriptionButton {
  &Small {
    height: Tools.asRem(34px);
    width: Tools.asRem(86px);
    justify-content: center;
  }
  &Large {
    height: Tools.asRem(34px);
    width: Tools.asRem(143px);
    justify-content: center;
  }
}

.footer {
  width: 100%;
  /* could possibly be request to make it sticky */
  // position: sticky;
  bottom: 0;

  color: Colors.$text-secondary;
  justify-content: space-between;
  font-family: OpenSans, sans-serif;
  font-size: Tools.asRem(13px);
  font-style: italic;
  font-weight: 400;
  line-height: Tools.asRem(18px);
  
	/* from layout.scss to apply everything except background */
  border-top: 0;
  gap: Tools.asRem(Spacing.$gutter-small);

  // use padding only if there is any content
  &:has(> *) {
    padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small);
  }
}
