@use "colors" as Colors;
@use "spacing" as Spacing;
@use "../tools" as Tools;

$body-size-default: Tools.asRem(13px);
$body-size-default-small: Tools.asRem(10px);

%font-body {
	font-family: OpenSans;
}

%font-body-base-size {
	@media only screen and (max-width: 960px) { 
		font-size: $body-size-default-small;
	}

	@media only screen and (min-width: 960px) { 
		font-size: $body-size-default;
	}
}

@mixin font-size-small {
	font-size: Tools.asRem(10px);
}




