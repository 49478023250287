$overlay-background-alpha: var(--overlay-background-alpha);

//  Table + Module elements
$column-header-background: var(--column-header-background);
$column-header-border: var(--column-header-border);

$input-background-color: var(--input-background-color);
$input-border-color: var(--input-border-color);
$input-border-hover: var(--slate-70);

$dropdown-background: var(--dropdown-background);
$dropdown-border: var(--dropdown-border);
$dropdown-focus-border: var(--dropdown-focus-border);

$overlay-background: var(--overlay-background);
$overlay-header-background: var(--overlay-header-background);

$footer-background: var(--footer-background);

$tooltip-background: var(--tooltip-background);

$text-only-button-background: var(--text-only-button-background);


$datatable-header-background: var(--datatable-header-background);
$datatable-header-border: var(--datatable-header-border);

$datatable-row-selected-background: var(--datatable-row-selected-background);
$datatable-row-border: var(--datatable-row-border);
$datatable-row-hover-background: var(--datatable-row-hover-background);



$data-visualisation-axis-line: var(--data-visualisation-axis-line);
$data-visualisation-tool-tip-background: var(--data-visualisation-tool-tip-background);
$data-visualisation-axis-labels: var(--data-visualisation-axis-labels);

$page-header-background: var(--page-header-background);
$page-header-border-color: var(--page-header-border-color);

$panel-border-color: var(--panel-border-color);
$panel-background-color: var(--panel-background-color);

$token-background: var(--token-background);
$token-border: var(--token-border);
$token-label: var(--token-label);

$select-list-background: var(--select-list-background);
$select-list-panel-background: var(--select-list-panel-background);

$positive: var(--positive);
$negative: var(--negative);
