@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.usersShareContainer {
  flex-direction: column;
  width: 100%;
  gap: Tools.asRem(Spacing.$gutter-small);

  &List {
    flex-direction: column;
    gap: Tools.asRem(Spacing.$gutter-small);
  }
}
