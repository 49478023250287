@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.map-container {
  .atlas-map {
    .map-point {
      &.load_main_desk {
        background-image: url('../../../../../public/img/icons/load_main_desk.svg');
        height: Tools.asRem(48px);
        width: Tools.asRem(48px);
      }

      &.disch_main_desk {
        background-image: url('../../../../../public/img/icons/disch_main_desk.svg');
        height: Tools.asRem(48px);
        width: Tools.asRem(48px);
      }

      &.via_main_desk {
        background-image: url('../../../../../public/img/icons/via_main_desk.svg');
        height: Tools.asRem(48px);
        width: Tools.asRem(48px);
      }

      &.load_sec_desk { // Will be used in V2 (when it will be possible to select several loading, discharging, via)
        background-image: url('../../../../../public/img/icons/load_sec_desk.svg');
        height: Tools.asRem(24px);
        width: Tools.asRem(24px);
      }

      &.disch_sec_desk { // Will be used in V2 (when it will be possible to select several loading, discharging, via)
        background-image: url('../../../../../public/img/icons/disch_sec_desk.svg');
        height: Tools.asRem(24px);
        width: Tools.asRem(24px);
      }

      &.via_sec_desk {
        background-image: url('../../../../../public/img/icons/via_sec_desk.svg');
        height: Tools.asRem(24px);
        width: Tools.asRem(24px);
      }

      &.load_main_mob {
        background-image: url('../../../../../public/img/icons/load_main_mob.svg');
        height: Tools.asRem(24px);
        width: Tools.asRem(24px);
      }

      &.disch_main_mob {
        background-image: url('../../../../../public/img/icons/disch_main_mob.svg');
        height: Tools.asRem(24px);
        width: Tools.asRem(24px);
      }

      &.via_main_mob {
        background-image: url('../../../../../public/img/icons/via_main_mob.svg');
        height: Tools.asRem(24px);
        width: Tools.asRem(24px);
      }

      &.load_sec_mob { // Will be used in V2 (when it will be possible to select several loading, discharging, via)
        background-image: url('../../../../../public/img/icons/load_sec_mob.svg');
        height: Tools.asRem(16px);
        width: Tools.asRem(16px);
      }

      &.disch_sec_mob { // Will be used in V2 (when it will be possible to select several loading, discharging, via)
        background-image: url('../../../../../public/img/icons/disch_sec_mob.svg');
        height: Tools.asRem(16px);
        width: Tools.asRem(16px);
      }

      &.via_sec_mob {
        background-image: url('../../../../../public/img/icons/via_sec_mob.svg');
        height: Tools.asRem(16px);
        width: Tools.asRem(16px);
      }
    }

    &.map-style-night,
    &.map-style-grayscale_dark {
      .map-label {
        background-color: Colors.$slate-50;
        color: Colors.$ob-brand-black;
        border-color: Colors.$ob-brand-black;
        background-image: url('../../../../../public/img/icons/map_flag_dark.svg');
      }
    }

    &.map-style-road,
    &.map-style-road_shaded_relief,
    &.map-style-grayscale_light {
      .map-label {
        background-color: Colors.$charcoal;
        color: Colors.$text-primary;
        border-color: Colors.$full-black;
        background-image: url('../../../../../public/img/icons/map_flag.svg');
      }
    }

    &.map-style-satellite,
    &.map-style-satellite_road_labels,
    &.map-style-high_contrast_dark,
    &.map-style-high_contrast_light {
      .map-label {
        background-color: Colors.$ob-brand-orange;
        color: Colors.$ob-brand-black;
        border-color: Colors.$ob-brand-black;
        background-image: url('../../../../../public/img/icons/map_flag_dark.svg');
      }
    }
  }
}