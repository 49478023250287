@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/animations" as Animations;
@use "src/design-system/tools" as Tools;

$menu-item-height: 42px;
$menu-zIndex: 1000;

.left-menu {

	&__container {

		//  Hide the menu off screen for mobile devices
		@media only screen and (max-width: 960px) {
			position: absolute;
			top: 0;
			bottom: 0;
			left: (0 - Spacing.$menu-width);
			z-index: $menu-zIndex;
			transition: Animations.$animation-panel-position;

			.left-menu--expanded & {
				left: 0;
			}
		}
	}

	&--overlay.menu-underlay {
		z-index: $menu-zIndex - 1;
	}

	&__items {
		background-color: Colors.$full-black;
		flex-direction: column;
		gap: Spacing.$gutter-large;
		overflow: hidden;
		padding: Spacing.$gutter-small 0 Spacing.$gutter-large 0;
		transition: Animations.$animation-panel-width;
		width: Spacing.$menu-width;

		.left-menu--expanded & {
			width: Spacing.$menu-expanded-width;
		}

		& hr {
			border-width: 0;
			border-bottom: 1px dotted Colors.$slate;
			display: block;
			flex-grow: 1;
		}

		.p-button {
			cursor: pointer;
			padding: 0;
			align-items: center;
			justify-content: flex-start;
			gap: Tools.asRem(Spacing.$gutter-medium);
			background-color: transparent;
			height: $menu-item-height;
			min-height: $menu-item-height;
			min-width: calc(#{Spacing.$menu-width} - 2px);
			font-family: Panton;
			font-size: Tools.asRem(18px);
			font-weight: 200;
			overflow: hidden;
			white-space: nowrap;
			border: none;
			border-radius: 0;

			&:not(.active) {
				&, .p-button-icon::before {
					color: Colors.$text-primary;
				}
			}

			&.active {
				color: Colors.$ob-brand-orange;
				background-color: Colors.$ob-brand-black;

				&::before {
					background-color: Colors.$ob-brand-orange;
					content: '';
					height: 100%;
					position: absolute;
					width: Tools.asRem(Spacing.$gutter-micro);
				}
			}

			:first-child {
				align-items: center;
				justify-content: center;
				min-width: calc(#{Spacing.$menu-width} - 2px);
				height: 100%;
			}
		}

		& button:focus-visible {
			outline: Colors.$ob-brand-black auto 1px;
		}
	}

	&__routes {
		flex-direction: column;
		gap: Spacing.$gutter-large;
		overflow: hidden;
		overflow-y: auto;
	}
	
	&__avatar-container {
		position: relative;

    align-items: center;
    justify-content: center;

		$indicator-badge-size: Tools.asRem(18px);
		.p-badge {
			position: absolute;
			left: 53%;
			top: 53%;
	
			display: flex;
			align-items: center;
			justify-content: center;
	
			width: $indicator-badge-size;
			height: $indicator-badge-size;
			min-width: $indicator-badge-size;
			z-index: 10;
			
			border: 2px solid Colors.$slate-40;
			border-radius: calc($indicator-badge-size / 2);
		}
	}

	&__avatar,
	&__logo {
		align-items: center;
		justify-content: center;
		width: Spacing.$menu-width;

		:first-child {
			height: Tools.asRem(36px);
			padding: Tools.asRem(Spacing.$gutter-micro);
			width: Tools.asRem(36px);
			background-color: transparent;

			img {
				aspect-ratio: 1 / 1;
				border: 2px solid Colors.$slate-40;
			}
		}
	}

	&__profile-panel-container {
		margin: 0px;
		padding: 0px;

		.p-overlaypanel-content {
			& > div {
				padding: 0px;
			}
		}
	}
}

.app__canvas:not(.left-menu--expanded) {
    & .left-menu__container {

        //  Hide the menu off screen for mobile devices
        @media only screen and (max-width: 960px) {
            height: $menu-item-height;
        }
    }
}

