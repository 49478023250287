@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/fonts' as Fonts;

.task-sidebar {
  &__container {

    &.p-sidebar {
      background-color: Colors.$panel-background-color;
    }

    .p-sidebar-content {
      flex-direction: column;
      padding: 0;
    }

    .p-sidebar-header {
      background: Colors.$charcoal;
      color: Colors.$text-secondary;
      padding: Tools.asRem(Spacing.$gutter-small);
      @include Tools.dotted-border($edge: bottom);
      @include Fonts.heading-major;

      .p-sidebar-close {
        @include Tools.top-right-element;

        padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
        color: Colors.$text-secondary;
      }
    }
  }

  &__form {
    flex-direction: column;
    flex-grow: 1;
    padding: Tools.asRem(Spacing.$gutter-small);
    overflow: auto;

    .form-input__container {
      &.task-sidebar__form-title {
        align-items: flex-start;

        > div {
          font-family: OpenSans, sans-serif;
          font-weight: 700;
        }
      }

      &:last-child {
        flex-grow: 0;
      }
    }
  }

  &__dropdown {
    align-items: flex-start;

    > * {
      width: 100%;
    }
  }

  &__notes {
    margin-left: calc(-1 * Tools.asRem(Spacing.$gutter-small));
    margin-right: calc(-1 * Tools.asRem(Spacing.$gutter-small));
    gap: 0;

    > div {
      gap: Tools.asRem(Spacing.$gutter-small);
      padding: Tools.asRem(Spacing.$gutter-small);
    }

    header.task-sidebar__notes--header {
      padding: Tools.asRem(Spacing.$gutter-small);
      color: Colors.$text-secondary;
      font-family: Panton, sans-serif;
      font-size: Tools.asRem(14px);
      font-weight: 400;
    }

    textarea {
      // resize only vertically
      min-width: 100%;
      max-width: 100%;
    }
  }

  &__footer {
    border-top: 1px solid Colors.$panel-border-color;
    padding: Tools.asRem(Spacing.$gutter-small);
    justify-content: flex-end;

    button {
      height: Tools.asRem(34px);
      font-size: Tools.asRem(16px);
    }
  }
}

@media only screen and (max-width: 960px) {
  .task-sidebar__container {
    &.p-sidebar {
      height: auto;
      max-height: 100%;

      .form-input__container {
        &:last-child {
          flex-grow: 1;
        }
      }

      header.task-sidebar__notes--header {
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}