@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;

.module__canvas:has(> header) > header.data-module__header {
  justify-content: flex-end;
  padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-large);
  background-color: Colors.$ob-brand-black;
  border-bottom: 1px solid Colors.$full-black;
  box-shadow: none;
}

.data-module__main {
  padding-top: Tools.asRem(Spacing.$gutter-large);

  section:has(footer) {
    padding-bottom: Tools.asRem(34px);
  }
}

.data-module__table {
  tr {
    display: grid;
    grid-template-columns:
      minmax(Tools.asRem(440px), 2.5fr)           // subscription name
      repeat(3, minmax(Tools.asRem(100px), 1fr))  // type, number of days, start date
      Tools.asRem(192px)                          // status
      minmax(Tools.asRem(100px), 1fr);            // expiry date

    &.p-rowgroup-footer:empty {
      display: none;
    }

    .not-available__container {
      font-style: italic;
    }
  }
}
