@use "./tools.px-to-rem.scss" as Tool;

@mixin top-right-element {
  width: Tool.asRem(58px);
  height: Tool.asRem(40px);
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent !important;
  border-radius: 0;
  border: 0;

  span:first-child::before {
    width: 18px !important; // override general button styles
    height: 18px !important; // override general button styles
  }
}