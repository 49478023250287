@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;

.audio-player-container {
  width: 100%;
  flex-direction: column;

  &:has(input[type="checkbox"]:checked) {
    position: sticky;
    top: 0;
    z-index: calc(infinite);
  }
  
  &-loading-bar {
    width: 100%;
    overflow: hidden;

    &::before {
      position: relative !important;
    }
  }
}

.audio-player-component {
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: Tools.asRem(Spacing.$gutter-large);

  background-color: Colors.$overlay-background;
  padding: Tools.asRem(Spacing.$gutter-small);
  border-radius: Tools.asRem(3px);

  &-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1;
  }

  &-progress {
    position: relative;
    flex-direction: column;
    flex: 999;
    justify-content: center;

    &-buffer {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 0;

      & .p-progressbar-value {
        border-radius: 2px;
        background-color: Colors.$slate;
      }
    }

    &-slider {
      height: Tools.asRem(Spacing.$gutter-micro);
      background: none;
      z-index: 100;

      & .p-slider-range {
        background-color: Colors.$grass-60;
        border-radius: Tools.asRem(3px);
      }

      & .p-slider-handle {
        height: Tools.asRem(Spacing.$gutter-large);
        width: Tools.asRem(Spacing.$gutter-large);

        background-color: Colors.$grass-60;
        border: none;

        /* Fix thumb position (thumb width / 2) */
        margin-left: calc(-1 * Tools.asRem(Spacing.$gutter-large) / 2) !important;
        margin-right: calc(-1 * Tools.asRem(Spacing.$gutter-large) / 2) !important;

        &:hover {
          background-color: Colors.$grass-60 !important;
          border: none !important;
        }
      }
    }
  }

  &-timer {
    flex-direction: column;
    flex: 2;
    text-wrap: nowrap;
    font-weight: 400;
  }
}
