@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;

.container {
}

.datatable {
	& tr { display: grid; }
}

.aside {
	position: relative;

}

.aside > button {
	width: Tools.asRem(42px);
	height: Tools.asRem(42px);
	position: absolute;
	top: 0;
	right: 0;
	background-color: transparent !important;
	justify-content: flex-start;
	z-index: calc(infinity);
}

$table-reflow-break-point: 648px;

@container main-body (min-width: #{$table-reflow-break-point}) {

  // .p-datatable-header { display: none; }

  .datatable {
		& tr {
			grid-template-columns: 
				Tools.asRem(142px)
				minmax(Tools.asRem(104px), .45fr) 
				Tools.asRem(112px)
				minmax(Tools.asRem(128px), 1fr) 
				repeat(3, .8fr);
		}
	}
}

@container main-body (max-width: #{$table-reflow-break-point}) {

	.datatable {

		& thead { display: none;}

		& tbody { 
			display: flex;
			flex-direction: column;
			gap: Tools.asRem(Spacing.$gutter-small);
			padding: Tools.asRem(Spacing.$gutter-small); 
		};

		& tr {
			background-color: Colors.$charcoal;
			border: 1px solid Colors.$panel-border-color !important;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(5, 1fr);     
			
			& td:first-child { grid-area: 1 / 1 / 1 / 1; }
			& td:nth-child(2) { grid-area: 1 / 2 / 2 / 2; }
			& td:nth-child(3) { grid-area: 1 / 3 / 2 / 3; }
			& td:nth-child(4) { grid-area: 2 / 1 / 3 / 4; }
			& td:nth-child(5) { grid-area: 3 / 1 / 3 / 4; }
			& td:nth-child(6) { grid-area: 4 / 1 / 4 / 4; }
			& td:nth-child(7) { grid-area: 5 / 1 / 5 / 4; }
		}


		& td:not(:last-child) {
			@include Tools.dotted-border($edge: bottom);
		}
		
		& td:nth-child(2) { 
			@include Tools.dotted-border($edge: bottom);
		}
	}
}

// .cell {
// 	// padding-right: unset !important;
// }
.cell > div {
	gap: Tools.asRem(Spacing.$gutter-small);
	justify-content: space-between;
	flex-grow: 1;
	align-items: center;
}