@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.tabview {
	> div {
		height: Tools.asRem(300px);
		width: Tools.asRem(300px);
	}

	.p-tabview-panel {
		flex-direction: column;
	}

  ul {
    flex-grow: 0;
  }
}

.credentialContainer {
	flex-direction: column;
	overflow: hidden;

	> label {
		border-width: 1px 0px;
		border-style: solid;
		border-color: Colors.$full-black;
		background: Colors.$text-tertiary;
		font-family: Panton;
		font-size: 14px;
		font-weight: 400;
		line-height: 14px;
	}

	.credential {
		flex-direction: column;

		> span {
			font-family: OpenSans, sans-serif;
			font-weight: 700;
			font-size: Tools.asRem(11px);
			text-transform: uppercase;
		}

		> div {
			gap: Tools.asRem(Spacing.$gutter-small);

			> span {
				flex: 1;
				color: Colors.$text-primary;
				align-content: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

.contentContainer {
	padding: Tools.asRem(Spacing.$gutter-small);
}

.clddSwitch {
	flex-direction: column;

	padding: Tools.asRem(Spacing.$gutter-small);
	gap: Tools.asRem(Spacing.$gutter-micro);

	label {
		font-size: Tools.asRem(13px);
		line-height: Tools.asRem(18px);
		font-weight: bold;
	}

	div {
		color: Colors.$text-primary;
	}
}

.downloadButtonContainer {
  background-color: Colors.$full-black;
  border: 2px solid Colors.$full-black;
  border-radius: Tools.asRem(Spacing.$gutter-micro);

  i {
    padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);

    color: Colors.$text-primary;
    cursor: pointer;
  }
}

.separator {
	border-bottom: 1px dashed Colors.$slate;
}

.helpContainer {
	gap: Tools.asRem(Spacing.$gutter-large);
}

.helpDescription {
	font-family: OpenSans;
	font-size: 13px;
	line-height: 18px;
	gap: Tools.asRem(Spacing.$gutter-micro);

	color: Colors.$text-primary;
	font-weight: 700;

	> dd {
		color: Colors.$text-secondary;
		font-weight: 400;
	}
}
