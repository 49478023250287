@use 'src/design-system/globals/colors' as Colors;
@use './tools.px-to-rem.scss' as Tool;

@mixin datatable-new-item($border-color: Colors.$ob-brand-orange) {
  tr.newItem,
  tr.p-highlight.p-selectable-row.newItem {
    background-color: Colors.$column-header-background;
    font-weight: 700;
    
    > :first-child {
      border-left: Tool.asRem(4px) solid $border-color;
      border-right: Tool.asRem(4px) solid transparent;
    }
    
    > :last-child {
      border-right: Tool.asRem(4px) solid $border-color;
    }
  }
}
