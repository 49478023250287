@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.p-inputgroup-addon {
    background: Colors.$ob-brand-black;
    color: Colors.$slate-20;
    border-top: $inputBorder;
    border-left: $inputBorder;
    border-bottom: $inputBorder;
    padding: Tools.asRem(Spacing.$gutter-micro);
    min-width: $inputGroupAddOnMinWidth;

    &:last-child {
        border-right: $inputBorder;
    }

    &.addon-color {
        &--egg-plant { background-color: Colors.$egg-plant;}
        &--grass { background-color: Colors.$grass;}
        &--amber { background-color: Colors.$amber;}
        &--slate { background-color: Colors.$slate;}
        &--crimson { background-color: Colors.$crimson;}
    }
}

.p-inputgroup {

  width: unset !important;

  // & input {
  //   width: unset !important; // DONT like this - but primereact minified seems to override this. So have not choice
  // }

    > .p-component,
    > .p-inputwrapper > .p-inputtext,
    > .p-float-label > .p-component {
        border-radius: 0;
        margin: 0;

        + .p-inputgroup-addon {
            border-left: 1px solid transparent; // prevents width jumping when border is set on hover/focus
        }

        &:focus {
            z-index: 1;

            ~ label {
                z-index: 1;
            }
        }
    }
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup > input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.p-inputgroup > div:first-child input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  // border-right: 0;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup > input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

.p-fluid {
    .p-inputgroup {
        .p-button {
            width: auto;

            &.p-button-icon-only {
                width: $buttonIconOnlyWidth;
            }
        }
    }
}

.p-inputgroup:has(.p-disabled.lock-when-disabled) {
    // TODO: add other types when needed
    .p-inputtext,
    .p-inputnumber {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }

  &::after {
    $iconSize: 20px;

    content: '';
    display: block;
    flex-shrink: 0;
    background-image: url('data:image/svg+xml;charset=utf-8,<svg width="#{$iconSize}" height="#{$iconSize}" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 12H17.4C17.7314 12 18 12.2686 18 12.6V19.4C18 19.7314 17.7314 20 17.4 20H6.6C6.26863 20 6 19.7314 6 19.4V12.6C6 12.2686 6.26863 12 6.6 12H8M16 12V8C16 6.66667 15.2 4 12 4C8.8 4 8 6.66667 8 8V12M16 12H8" stroke="grey" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: Colors.$full-grey;
    border: $inputBorder;
    border-left: none;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    width: calc($iconSize + (2 * Spacing.$gutter-small));
    aspect-ratio: 1/1;
  }
}