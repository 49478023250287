@use 'sass:math';

@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;


.p-overlaypanel {

	background: Colors.$overlay-background;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.56);
	border: 1px solid Colors.$full-black;
	border-radius: $borderRadius;
	color: Colors.$text-secondary;
	margin-left: Tools.asRem(Spacing.$gutter-small);
	padding-bottom: Tools.asRem(Spacing.$gutter-medium);
		
	.p-overlaypanel-content {
		// padding: $panelContentPadding;
		flex-direction: column; 
		
		& > div { 
			flex-direction: column; 
			gap: Tools.asRem(Spacing.$gutter-small);
			width: fit-content;
		}

		& > * {
			padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
		}

		& header {
			background: Colors.$overlay-header-background;
			font-family: Panton;
			font-size: Tools.asRem(14px);
			border-bottom: 1px solid Colors.$full-black;
		}
		
		// & header,
		// & label {
		// 	color: Colors.$text-secondary;
		// }

	}

	.p-overlaypanel-close {
			background: $buttonBg;
			color: $buttonTextColor;
			width: $actionIconWidth;
			height: $actionIconHeight;
			transition: $actionIconTransition;
			border-radius: $actionIconBorderRadius;
			position: absolute;
			top: math.div(-1 * $actionIconWidth, 2);
			right: math.div(-1 * $actionIconWidth, 2);

			&:enabled:hover {
					background: $buttonHoverBg;
					color: $buttonTextHoverColor;
			}
	}

	&:after {
		border: solid transparent;
		// border-color: rgba($overlayContentBg, 0);
		border-bottom-color: Colors.$overlay-background;
	}

	&:before {
			border: solid transparent;

			@if (nth($overlayContentBorder, 2) == 'none') {
					border-color: rgba($overlayContentBg, 0);
					border-bottom-color: scale-color($overlayContentBg, $lightness: -5%);
			}
			@else {
					border-color: rgba(nth($overlayContentBorder, 3), 0);
					border-bottom-color: scale-color(nth($overlayContentBorder, 3), $lightness: -5%);
			}
	}

	&.p-overlaypanel-flipped {
		&:after {
				border-top-color: Colors.$overlay-background;
		}

		&:before {
			@if (nth($overlayContentBorder, 2) == 'none') {
					border-top-color: Colors.$overlay-background;
			}
			@else {
					border-top-color: nth($overlayContentBorder, 3);
			}
		}
	}
}
