@use "src/design-system" as *;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;

.p-inline-message {
    // padding: $inlineMessagePadding;
    // margin: $inlineMessageMargin;
    // border-radius: $borderRadius;

		gap: Tools.asRem(Spacing.$gutter-medium);
		padding: Tools.asRem(Spacing.$gutter-medium);

		&-text { max-width: 68ch; }

    &.p-inline-message-info {
        background: $infoMessageBg;
        border: $infoMessageBorder;
        border-width: $inlineMessageBorderWidth;
        color: $infoMessageTextColor;

        .p-inline-message-icon {
            color: $infoMessageIconColor;
        }
    }

    &.p-inline-message-success {
        background: $successMessageBg;
        border: $successMessageBorder;
        border-width: $inlineMessageBorderWidth;
        color: $successMessageTextColor;

        .p-inline-message-icon {
            color: $successMessageIconColor;
        }
    }

    &.p-inline-message-warn {
        background: $warningMessageBg;
        border: $warningMessageBorder;
        border-width: $inlineMessageBorderWidth;
        color: $warningMessageTextColor;

        .p-inline-message-icon {
            color: $warningMessageIconColor;
        }
    }

    &.p-inline-message-error {
        background: $errorMessageBg;
        border: $errorMessageBorder;
        border-width: $inlineMessageBorderWidth;
        color: $errorMessageTextColor;

        .p-inline-message-icon {
            color: $errorMessageIconColor;
        }
    }

    .p-inline-message-icon {
        font-size: $inlineMessageIconFontSize;
        margin-right: $inlineSpacing;
    }

    .p-inline-message-text {
      max-width: 68ch;
    }

    &.p-inline-message-icon-only {
        .p-inline-message-icon {
            margin-right: 0;
        }
    }
}
