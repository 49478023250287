@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.p-dialog {

	&--margin-less {
		margin: 0 !important;
	}

	&--flat-corners .p-dialog-footer,
	&--flat-corners {
		border-radius: 0 !important;
	}

	&--no-header .p-dialog-header {
		display: none;
	}

	&-default {
		border: 1px solid Colors.$full-black;

		& .p-dialog-content {
			padding: Tools.asRem(Spacing.$gutter-large);
		}
	}

	&-mask {
		//	TODO - figure out if there isn't a better way - so no !important. Some minified
		//	preime react code gets in the way so it's needed for now…
		position: absolute !important;
		// align-items: center !important;
		flex-direction: row;
	}

	border-radius: $borderRadius;
	box-shadow: $overlayContainerShadow;
	border: $overlayContentBorder;
	background: Colors.$ob-brand-black;

	&--small {
		max-width: Tools.asRem(360px);
	}
	&--medium {
		max-width: Tools.asRem(480px);
	}
	&--large {
		min-width: Tools.asRem(670px);

		@media only screen and (max-width: 960px) {
			min-width: 95vw;
		}
	}
	&--full {
		min-width: 95vw;
		min-height: 95vh;

		.p-dialog-content {
			padding: Tools.asRem(Spacing.$gutter-small);
		}
	}

	&-header {
			border-bottom: $dialogHeaderBorder;
			background: $dialogHeaderBg;
			color: $dialogHeaderTextColor;
			padding: $dialogHeaderPadding;
            margin: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-large) 0;
			border-top-right-radius: $borderRadius;
			border-top-left-radius: $borderRadius;

			.p-dialog-title {
				font-weight: $dialogHeaderFontWeight;
				font-size: $dialogHeaderFontSize;
				font-family: $dialogHeaderFontFamily;
				line-height: $dialogHeaderLineHeight;
			}

			.p-dialog-header-icons {
				align-self: center;
			}

			.p-dialog-header-icon {
					@include action-icon();
					margin-right: $inlineSpacing;
					color: Colors.$text-primary;
					width: Tools.asRem(24px);
					height: Tools.asRem(24px);

					&:focus {
						outline-offset: -4px;
					}

					&:last-child {
							margin-right: 0;
					}
			}
	}

	&-content {

		flex-direction: column;
		background: Colors.$ob-brand-black;
		padding: Spacing.$gutter-medium Spacing.$gutter-large Spacing.$gutter-large Spacing.$gutter-medium;
		gap: Tools.asRem(Spacing.$gutter-small);
		border-bottom: 1px solid Colors.$full-black;


		& strong { color: Colors.$text-primary; }
		& p {
			color: Colors.$text-primary;
			line-height: Tools.asRem(20px);
		}

		&:last-of-type {
			border-bottom-right-radius: $borderRadius;
			border-bottom-left-radius: $borderRadius;
		}
	}

	&-footer {

		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.56) inset;

		// border-top:  $dialogFooterBorder;
		background: Colors.$charcoal;
		// color: $panelFooterTextColor;
		padding: Spacing.$gutter-large Spacing.$gutter-medium Spacing.$gutter-small Spacing.$gutter-medium;
		// text-align: right;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;

			// button {
			// 		margin: 0 $inlineSpacing 0 0;
			// 		width: auto;
			// }
			& > div { flex-grow: 1; }
	}

	&.p-confirm-dialog {
			.p-confirm-dialog-icon {
					font-size: $primeIconFontSize * 2;
			}

			.p-confirm-dialog-message {
					margin-left: $inlineSpacing * 2;
			}
	}
}
