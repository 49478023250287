@use "src/design-system/globals/colors.scss" as Colors;
@use "src/design-system/globals/spacing.scss" as Spacing;
@use "src/design-system/tools" as Tools;

html {
    display: flex;
    height: 100vh;
    height: 100dvh;
}

html, body {
    margin: 0;
    background-color: Colors.$ob-brand-black;
}

body {
    display: flex;
    color: Colors.$text-primary;
    flex-direction: column;
    flex: 1;
    font-family: OpenSans;
    font-weight: 200;
    font-size: Tools.asRem(13px);
    margin: 0;
}

*:focus-visible {
	// outline: 1px auto Colors.$text-primary
	outline: unset
}

strong {
	font-weight: 700;
}