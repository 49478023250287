@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/fonts' as Fonts;

.distlist-single-side-panel,
.module__canvas main > aside.distlist-single-side-panel {
  flex: 1 1 100%;
  overflow: hidden;
  margin-right: Tools.asRem(Spacing.$gutter-medium);

  header {
    @include Fonts.heading-major;
    padding: Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-secondary;
  }

  .close-button {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    @include Tools.top-right-element;
  }

  .single-details__form {
    gap: Tools.asRem(Spacing.$gutter-small);
  }

  .single-message {
    &-details__spec {
      grid-template-columns: 1fr;
      flex-grow: 0;
      padding-bottom: Tools.asRem(Spacing.$gutter-large);

      @media only screen and (min-width: 960px) {
        grid-template-columns: repeat(4, 1fr);
        max-width: 80%;

        > :nth-child(1),
        > :nth-child(2) {
          grid-column: span 4;
        }
      }

      dd {
        white-space: pre-line;
      }
    }

    &-recipients__container {
      thead {
        border-top: 2px solid Colors.$column-header-border;
      }

      tr,
      th {
        grid-template-columns:
          minmax(Tools.asRem(180px), 2fr)
          minmax(Tools.asRem(99px), 1fr)
          minmax(Tools.asRem(145px), 1.2fr)
          minmax(Tools.asRem(170px), 1.3fr)
          Tools.asRem(83px);

          i.pi-check {
            color: Colors.$positive;
          }
      }

      .distlist__view {
        margin: 0;
        background: transparent;

        &.distlist__messages {
          .grid-element {
            padding-bottom: Tools.asRem(2px);
            padding-top: Tools.asRem(2px);

            .grid-element__body:has(.dl-resend) {
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }

  .not-available__container,
  .column-status {
    font-style: italic;
    color: Colors.$text-secondary;
    gap: Tools.asRem(Spacing.$gutter-small);
  }

  .form-input__container {
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }

  footer {
    justify-content: flex-end;
    background-color: Colors.$charcoal;
    border-top: 1px solid Colors.$full-black;
    flex-direction: row;
    padding: Tools.asRem(Spacing.$gutter-small) !important;

    button i {
      &::before {
        transition: transform .265s ease-in-out;
      }

      &.open::before {
        transform: rotate(-180deg);
      }
    }
  }

  @media only screen and (max-width: 960px) {
    border: 0;
    border-radius: 0;

    .back-button {
      background-color: transparent;
      padding: Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-small);
      border: 0;
      margin-top: Tools.asRem(Spacing.$gutter-micro);
      height: Tools.asRem(32px);
      line-height: Tools.asRem(32px);
    }

    footer {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 3;
      bottom: 0;
      margin: 0;
      padding: Tools.asRem(Spacing.$gutter-small);
      border-top: 1px solid Colors.$full-black;
      background: Colors.$panel-background;

      button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.single-message__create-message-menu {
  min-width: Tools.asRem(230px);

  @media only screen and (max-width: 960px) {
    width: calc(100vw - (2 * Spacing.$gutter-small));
  }

  &.p-menu {
    ul {
      padding: Tools.asRem(Spacing.$gutter-small) 0;
    }
  }
}