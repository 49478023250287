@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;

.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncatedTooltip {
    cursor: help;
}

@container main-body (min-width: #{Spacing.$table-reflow-break-point}) {
	.label {
		display: none;
	}
}

@container main-body (max-width: #{Spacing.$table-reflow-break-point}) {
	.label {
		font-size: Tools.asRem(10px);
		text-transform: uppercase;
		font-family: OpenSans;
		font-weight: bolder;
		color: Colors.$text-secondary;
		line-height: Tools.asRem(11px);
	}
}
