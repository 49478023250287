@use "src/design-system/globals/colors" as Color;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.p-accordion {
    .p-accordion-header {
  
		border-bottom: 1px solid Color.$ob-brand-black;
			
			.p-accordion-header-link {
                padding: $accordionHeaderPadding;
                border: $accordionHeaderBorder;
                color: $accordionHeaderTextColor;
                background: $accordionHeaderBg;
                font-family: Panton, OpenSans;
                font-weight: $accordionHeaderFontWeight;
                font-size: Tools.asRem(18px);
                width: 100%;
                border-radius: $borderRadius;
                transition: $listItemTransition;

            .p-accordion-header-text {
                width: 100%;
            }

            .p-accordion-toggle-icon {
                margin-right: $inlineSpacing;
            }
        }

        &:not(.p-disabled) {
            .p-accordion-header-link {
                &:focus {
                    @include focused();
                }
            }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
            .p-accordion-header-link {
                background: $accordionHeaderHoverBg;
                border-color: $accordionHeaderHoverBorderColor;
                color: $accordionHeaderTextHoverColor;
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
                background: $accordionHeaderActiveBg;
                border-color: $accordionHeaderActiveBorderColor;
                color: $accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:hover {
                .p-accordion-header-link {
                    border-color: $accordionHeaderActiveHoverBorderColor;
                    background: $accordionHeaderActiveHoverBg;
                    color: $accordionHeaderTextActiveHoverColor;
                }
            }
        }
    }

    .p-accordion-content {
			overflow-y: auto;
			
			padding: 0;
			
			& > * {
				padding-left: Tools.asRem(Spacing.$gutter-medium);
				padding-right: Tools.asRem(Spacing.$gutter-medium);
			}

			& > *:last-child {
				padding-bottom: Tools.asRem(Spacing.$gutter-medium);
			}

			//Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-medium);
    }

    .p-accordion-tab {
        margin-bottom: $accordionSpacing;

        @if $accordionSpacing == 0 {
            .p-accordion-header {
                .p-accordion-header-link {
                    border-radius: 0;
                }
            }

            .p-accordion-content {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(:first-child) {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight):not(.p-disabled):hover,
                    &:not(.p-disabled).p-highlight:hover {
                        .p-accordion-header-link {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top-right-radius: $borderRadius;
                        border-top-left-radius: $borderRadius;
                    }
                }
            }

            &:last-child {
                .p-accordion-header:not(.p-highlight) {
                    .p-accordion-header-link {
                        border-bottom-right-radius: $borderRadius;
                        border-bottom-left-radius: $borderRadius;
                    }

                    .p-accordion-content {
                        border-bottom-right-radius: $borderRadius;
                        border-bottom-left-radius: $borderRadius;
                    }
                }
            }
        } 
    }
}
