@use "src/design-system/tools" as Tools;

.sanctions-table--vessel {

    & tr:not(.p-datatable-emptymessage) {

        @media only screen and (max-width: 960px) {
            grid-template-columns:
                minmax(Tools.asRem(132px), Tools.asRem(186px))  // Vessel
                minmax(Tools.asRem(164px), .66fr)               // Flag
                minmax(Tools.asRem(105px), .4fr)                // Created On
                minmax(Tools.asRem(98px), .226fr)               // Risk Level
                Tools.asRem(84px);                              // `Check` button

            & .column__registered-owner,
            & .column__status {
                display: none;
            }
        }

        @media only screen and (min-width: 960px) {
            grid-template-columns:
                minmax(Tools.asRem(132px), Tools.asRem(186px))  // Vessel
                minmax(Tools.asRem(190px), 1fr)                 // Owner
                minmax(Tools.asRem(174px), .66fr)               // Flag
                minmax(Tools.asRem(164px), .3fr)                // Status
                minmax(Tools.asRem(110px), .2fr)                // Created On
                minmax(Tools.asRem(108px), .226fr)              // Risk Level
                Tools.asRem(84px);                              // `Check` button
        }
    }
}