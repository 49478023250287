@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use 'src/design-system/globals/fonts' as Fonts;

.status {
	flex-direction: row;
	align-items: center
}

// .badge,
.label {
	flex-grow: 1;
}

.badge {
	// border: 1px solid red;
}

.definitions {
	padding-bottom: Tools.asRem(Spacing.$gutter-large);
}

.label {
	// @include Fonts.definition-label;
	max-width: Tools.asRem(116px);
	text-transform: uppercase;
}

.risk {

	flex-direction: column;
	// align-items: center;
	display: grid;
	grid-template-rows: Tools.asRem(32px);
	grid-template-columns: repeat(2, 1fr);
	row-gap: Tools.asRem(2px);
	
	& div:first-of-type { 
		align-items: center; 
		padding-left: Tools.asRem(Spacing.$gutter-micro)
	}

	& div:not(:nth-child(2)) { grid-column: 2; }


	& > label {
		align-self: center;
		// min-height: Tools.asRem(32px);
		font-family: "OpenSans";
    color: var(--text-secondary);
    font-size: 0.6875rem;
    font-weight: 800;
    text-transform: uppercase;
		padding-left: Tools.asRem(Spacing.$gutter-small)
	}
}