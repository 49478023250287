@use "colors" as Colors;
@use "spacing" as Spacing;
@use "../tools" as Tools;
@use "fonts.body" as *;

$table-heading-major: Tools.asRem(14px);
$table-heading-minor: Tools.asRem(11px);


%font-headings {
	font-family: Panton;
}


%column-headings-primary {
	@extend %font-headings;
	font-size: $table-heading-major;
	font-weight: 600;
}

%column-headings-secondary {
	@extend %font-body;
	font-size: $table-heading-minor;
	font-weight: 400;
}

