@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.search-tokens {
    &__container {
        align-items: center;
        row-gap: Tools.asRem(Spacing.$gutter-micro);
        // border-bottom: 1px dashed Colors.$slate-80;
        padding-bottom: Tools.asRem(Spacing.$gutter-small);
        @include Tools.dotted-border($edge: bottom);
        min-height: 42px;

        & i {
            font-style: normal;
        }

        & > i.type--and {
            font-weight: 400;
            text-decoration: dotted;
            margin: 0 Tools.asRem(Spacing.$gutter-small);
            text-decoration: underline;
            text-decoration-style: dotted;
            text-underline-offset: 4px;
        }

        & > div > i.type--or {
            color: #353434;
            border: 1px solid Colors.$full-black;
            background-color: Colors.$slate-80;
            padding: Tools.asRem(Spacing.$gutter-micro);
            flex-grow: 1;
            display: inline-flex;
            max-width: Tools.asRem(32px);
            justify-content: center;
            font-size: Tools.asRem(11px);
            min-height: Tools.asRem(26px);
        }

        flex-wrap: wrap;

        & > div {


            background-color: Colors.$slate;
            padding: 4px;


            &:has(> i) {
                margin: 0 -4px;
                font-size: Tools.asRem(12px);
            }

        }
    }
}

.chip-group {

    gap: Tools.asRem(Spacing.$gutter-micro);
    padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(6px);

    align-items: center;
    flex-wrap: wrap;


}