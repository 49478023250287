@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.headerItems {
    gap: Tools.asRem(Spacing.$gutter-small);
    width: 100%;
}

.upper {
    gap: Tools.asRem(Spacing.$gutter-small);

    & > button {
        height: clamp(34px, 34px, 36px);
        flex-shrink: 0;
    }
}

.inputs {
    & > div {
        flex-grow: 1;

        &:last-child { // additional-filters__container
            flex-grow: 0;
        }
    }

    gap: Tools.asRem(Spacing.$gutter-medium);
}

.main {
    padding-top: Tools.asRem(Spacing.$gutter-large);
}

.worksheetError {
	background-color: Colors.$charcoal;
    padding: Tools.asRem(Spacing.$gutter-medium);
}

.worksheetNoLongerAvailable {
    background-color: Colors.$ob-brand-black;
    padding: 0;
}

.worksheetErrorFull {
    background-color: Colors.$charcoal;
    padding: Tools.asRem(Spacing.$gutter-medium);
}

.sortingItem {
    padding: Tools.asRem(Spacing.$gutter-micro);
    cursor: pointer;

    &:has([data-sorting]) {
        color: Colors.$ob-brand-orange;
        gap: Tools.asRem(Spacing.$gutter-small);

        ::after {
            top: 0;
        }
    }
}

.accordion {
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 !important; // override global (_accordion.scss)

    > div {
        flex-direction: column;
    }
}

.accordionHeader {
    :global {
        .p-accordion-header-link {
            .p-accordion-header-text {
                font-size: Tools.asRem(16px);
            }

            .p-accordion-header-text, .p-icon {
                color: Colors.$text-primary;
            }
        }

        svg {
            width: Tools.asRem(16px);
            height: Tools.asRem(16px);
        }
    }
}

.accordionTab {
    flex-direction: column;
    flex: 1 1 auto;
}

.accordionInner {
    .accordionTab {
        &, > * {
            border: none !important; // override global (_accordion.scss)

            :global {
                .p-accordion-header-link {
                    font-size: Tools.asRem(13px);
                }
            }
        }
    }

    .accordionContent {
        padding-top: Tools.asRem(Spacing.$gutter-small);

        :global {
            .p-accordion-content {
                flex-direction: column;
            }
        }
    }

    > div:not(:last-child) {
        border-bottom: 1px dashed Colors.$full-black;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1439px) {
    .inputs {
        display: grid;
        grid-template-columns: repeat(3, 1fr) auto;
        gap: Tools.asRem(Spacing.$gutter-small);

        :global {
            .additional-filters__container {
                grid-column-start: 4;
                grid-row-start: 1;
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .header {
        padding: 0 !important; // override .module_canvas
    }

    .upper {
        flex-direction: column;

        & > button {
            width: fit-content;
            align-self: center;
        }
    }

    .inputs {
        flex-direction: column;
    }

    .main {
        padding-top: Tools.asRem(Spacing.$gutter-small);
        overflow: visible !important; // override .module__canvas main
    }

    .exportButton {
        width: Tools.asRem(175px);
        align-self: center;
        margin-top: Tools.asRem(Spacing.$gutter-small);
        flex: 0 0 clamp(34px, 34px, 36px);
    }
}
