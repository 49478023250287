@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.map-container {
    display: block;
    position: relative;
    width: 100%;

    .atlas-map {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        .atlas-map-canvas-container {
            display: block;
            width: 100%;

            .atlas-map-canvas {
                display: block;
                width: 100%;
            }
        }

        .atlas-control-container {
            display: block;
            z-index: 0;
            width: 100%;
        }

        .map-point {
            display: block;
            height: Tools.asRem(48px);
            width: Tools.asRem(48px);
            background-repeat: no-repeat;
            background-position: center;
            border: 0;
            cursor: pointer;
        }

        .map-label {
            display: flex;
            padding: Spacing.$gutter-micro Spacing.$gutter-small;
            padding-left: Tools.asRem(24px);
            width: auto;
            height: Tools.asRem(28px);
            background-color: Colors.$charcoal;
            border: 1px solid Colors.$full-black;
            border-radius: Tools.asRem(3px);
            color: Colors.$text-primary;
            font-weight: 700;
            font-size: Tools.asRem(13px);
            cursor: pointer;
            background-image: url('../../../public/img/icons/map_flag.svg');
            background-repeat: no-repeat;
            background-position: Tools.asRem(8px) Tools.asRem(6px);
        }

        .marker-container:has(.map-label),
        .marker-container:has(.map-point-arrow),
        .marker-container:has(.map-point-pin),
        .marker-container:has(.map-point-via) {
            z-index: 3;
        }

        .marker-container:has(.map-point-bullet) {
            z-index: 2;
        }
    }


    // Below values are azure map style specific
    .map-style-night {
        background-color: #122d44;
    }

    .map-style-road,
    .map-style-road_shaded_relief {
        background-color: #92c7e6;
    }

    .map-style-satellite,
    .map-style-satellite_road_labels {
        background-color: #010f1c;
    }

    .map-style-grayscale_dark {
        background-color: #0f1d26;
    }

    .map-style-grayscale_light {
        background-color: #afafb4;
    }

    .map-style-high_contrast_dark {
        background-color: #136bfb;
    }

    .map-style-high_contrast_light {
        background-color: #184fbf;
    }

    .map-error {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        background-color: Colors.$charcoal;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        gap: Spacing.$gutter-large;
        color: Colors.$text-secondary;
        font-family: Panton, OpenSans, sans-serif;
        font-weight: 400;
        font-size: Tools.asRem(18px);
        line-height: Tools.asRem(22px);
        text-align: center;
        padding: 0 Spacing.$gutter-large;

        .map-error-icon {
            width: 150px;
            height: 150px;

            &:before {
                color: Colors.$text-secondary;
            }
        }

        .map-error-button {
            font-family: Panton, OpenSans, sans-serif;
            font-weight: 400;
            font-size: Tools.asRem(16px);
            line-height: Tools.asRem(20px);
            margin: Spacing.$gutter-large;
        }
    }

    @media (max-width: 960px) {
        border: 1px solid Colors.$full-black;
        border-radius: Tools.asRem(2px) Tools.asRem(2px) 0 0;
        margin: Spacing.$gutter-small;
    }
}