@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.surveillanceReturnWithCommentsPopup {
  flex-direction: column;
  gap: Tools.asRem(24px);
  flex-grow: 1;

  &Note {
    flex-direction: column;
    flex-grow: 1;

    label {
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(13px);
      line-height: Tools.asRem(18px);
      font-weight: 700;
      color: Colors.$text-secondary;
      padding-bottom: Tools.asRem(Spacing.$gutter-micro);
    }

    form {
      margin-bottom: Tools.asRem(Spacing.$gutter-large);
    }

    textarea::placeholder {
      color: Colors.$text-placeholder;
      font-style: italic;
    }

    small {
      color: Colors.$negative;
    }
  }
}

.invalid {
  border-color: Colors.$negative;
}