@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use 'src/design-system/globals/fonts' as Fonts;

$gap: Tools.asRem(Spacing.$gutter-small);

.definition-list {
	&__container  {
		padding: $gap;
		flex-wrap: wrap;
		row-gap: Tools.asRem(Spacing.$gutter-small);
		max-height: 48dvh;
		overflow-y: scroll;
		justify-content: space-between;

		& > * { 
				width: calc(50% - #{$gap});   
		};
	}

	&__item {

		flex-direction: column;

		& dt { @include Fonts.definition-label; }
		
		& .not-available__container { font-style: italic; }
	}
}

.item--full-width {
	min-width: 100%;
}