@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.heading {
	color: Colors.$ob-brand-red;

	&::before {
		margin-right: Tools.asRem(Spacing.$gutter-large);
	}
}

.container {
	
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-medium);
	line-height: Tools.asRem(24px);

	@media only screen and (max-width: 960px) {
		padding: 0 Tools.asRem(Spacing.$gutter-small);
	}

	& a { 
		display: inline-block;
		margin: 0 Tools.asRem(Spacing.$gutter-small) 
	}

	& > div {
		display: block;
		max-width: 84ch;
	}
}