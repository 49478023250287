@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/colors' as Colors;


.iconButton {
	width: 36px;
	aspect-ratio: 1 / 1;
	border-width: 0px !important;
	background-color: transparent !important;
}

.active {
	color: Colors.$ob-brand-orange !important;
	pointer-events: none;
}

.chartingHeader {
	justify-content: flex-end;
}

.openInNewWindowButton {
	// compansate flex gap
	margin-right: calc(-1 * Tools.asRem(Spacing.$gutter-small));
}