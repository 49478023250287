@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Color;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/animations" as Timings;

.cell__rate-grids {

    &--sentiment,
    &--value {

			display: grid;
			grid-row-gap: Tools.asRem(Spacing.$gutter-micro);
			position: relative;

			& > * { align-items: center;}

			& em {
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					height: 32px;
					width: 32px;
			}

			// & data,
			& address,
			& strong,
			& time {
					padding: 0 Tools.asRem(Spacing.$gutter-small);
			}
    
			& var,
			& > div {
					font-weight: 600;
					font-style: normal;
					text-transform: uppercase;
					color: Color.$text-secondary;
					padding: 0 Tools.asRem(Spacing.$gutter-micro);
					background-color: Color.$full-grey;
					justify-content: flex-start;
					grid-area: 1 / 2 / 2 / 3; 
			}

			& data,
			& var,
			& > div {
					min-height: Tools.asRem(32px);
			}

			& data {

				background-color: transparent;
				grid-area: 1 / 1 / 2 / 2;
				height: 100%;
				transition: background-color Timings.$color-fade;


				& > form { 
					height: 100%; 
				}

				&.outdated { background-color: Color.$amber;}
				&.updated { background-color: Color.$grass;}
				&.invalid { 
						border: 2px solid Color.$ob-brand-red;
						color: Color.$ob-brand-red;

						&em i::before {
								color: Color.$ob-brand-red;
						}
				}

				&:has(.p-dropdown) {
						grid-area: 1 / 1 / 1 / 3;
				}

				& select,
				& input:not(:focus-within) { 
					& { background-color: transparent; }
					color: Color.$text-primary;
				}

				& select:not(:focus),
				& input:not(:focus-within) { 
					border: 0;
				}

				& select { 
					flex-grow: 1; 
					padding-right: Tools.asRem(Spacing.$gutter-small);
				}
			}

			& address,
			& strong {
					
					font-weight: 200;
			}
			
			& address { 
					font-size: Tools.asRem(11px);
					color: Color.$text-secondary;
					grid-area: 2 / 1 / 3 / 3; 
					line-height: Tools.asRem(10px);
					font-style: normal;
			}
			
			& time { grid-area: 3 / 1 / 4 / 3; };
        
			& strong { 
					font-size: Tools.asRem(12px);
					grid-area: 4 / 1 / 4 / 3; 
					color: Color.$ob-brand-red;
					line-height: Tools.asRem(16px);
					padding-bottom: Tools.asRem(Spacing.$gutter-micro);
			};

			& address,
					& strong,
			& time {
				.no--audit-data & { 
					display: none;
				}
			}
    }

    &--sentiment { 
        grid-template-columns: 1.4fr Tools.asRem(32px);

        &:has(.p-dropdown) > div { display: none; }

        & em { right: 32px; }
    }

    &--value { 
        grid-template-columns: 1.4fr fit-content(12ch);

        & em { left: 0; }
    }

}