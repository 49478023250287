@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/colors' as Colors;

.panel {
	margin: 0 Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small);
	background-color: Colors.$panel-background;
	border: 1px solid Colors.$full-black;
	border-radius: 4px 4px 0 0;
}

.search {
	
	margin: Tools.asRem(Spacing.$gutter-small);
	gap: Tools.asRem(Spacing.$gutter-medium);
	
	
	@media only screen and (max-width: 960px) { 
		flex-direction: column;
		padding: 0 Tools.asRem(Spacing.$gutter-small);
	}
	
	@media only screen and (min-width: 960px) { 
		align-items: center;
		justify-content: flex-start;
		gap: Tools.asRem(Spacing.$gutter-small);
	}

	& > div {
		gap: Tools.asRem(Spacing.$gutter-small);
		flex-direction: column;

		& > label { font-weight: 600; }
	}

	& > div > div {
		&:first-child { align-items: baseline };
		
		gap: Tools.asRem(Spacing.$gutter-micro);
			
		& span { 
			&:nth-child(2) { font-size: Tools.asRem(11px); }
			color: Colors.$text-secondary;
		}

		& + div { gap: Tools.asRem(Spacing.$gutter-micro)}
	}

	& > div:not(:last-child) {
		
		@media only screen and (min-width: 960px) { 
			// border-right: 1px dotted Colors.$slate-90;
			@include Tools.dotted-border($edge: right);
			padding-right: Tools.asRem(Spacing.$gutter-small);
		}
	}
}

.unit {
	@media only screen and (min-width: 960px) { 
		& > div:last-child {
			height: Tools.asRem(36px);
			align-items: center;
		}
	}
}

aside div:has(> label + div) {
	
	& label { 
		font-size: Tools.asRem(11px);
		color: Colors.$text-secondary;
		font-weight: 800;
	}
}

@container aside-column (max-width: 45dvw) {

	aside div:not([class]):has( > label + div) { 
		gap: Tools.asRem(Spacing.$gutter-micro);
		// flex-direction: column;
	}

}

@container aside-column (min-width: 45dvw) {
	aside div:has( > label + div) { 
		gap: Tools.asRem(Spacing.$gutter-small);
		align-items: center;
		flex-direction: row;
	}
}

.loader {
	position: relative;
	min-height: Tools.asRem(28px);
}