@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/animations" as Timings;


.cell {

	& * {
		align-items: center;
	}

	flex-grow: 1;
	height: 100%;

	
	& > span { 
		display: flex;
		width: 50%;
		height: 100%;
	}
	
	& span:first-child {
		color: Colors.$text-secondary;
		justify-content: flex-end;
		padding-right: Tools.asRem(Spacing.$gutter-small);
	}
	
	& > span:last-child {
		// justify-content: space-between;
		font-size: Tools.asRem(11px);
		gap: Tools.asRem(Spacing.$gutter-micro);

		&::before {
			margin-left: Tools.asRem(Spacing.$gutter-small);
		}
	}
	
}

.down { & span:first-child { background-color: Colors.$negative; }}
.up { & span:first-child { background-color: Colors.$positive; }}


.pricechange {
	
	& span:first-child {
		font-weight: 700;
		color: Colors.$slate-20;
		animation: fadeBackground var(--speed) ease-out;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		animation-delay: var(--delay);
	};
}

@keyframes fadeBackground {
	from {
		font-weight: 400;
	}
	to { 
		background-color: transparent;
		color: Colors.$text-secondary;
		font-weight: 400;
	}
}

.unit {
	font-family: 'OpenSans';
	font-size: Tools.asRem(11px);
	color: Colors.$text-primary;
	font-weight: 400;
}



.positive { 
	color: Colors.$positive;
}

.negative {
	color: Colors.$negative;
}

.dropDownItem {
	& > *:first-child {
		color: Colors.$text-primary;
		min-width: 40dvw;
	}

	& > *:last-child {
		// color: Colors.$text-secondary;
	}

}

.columnConfig {
	@include Tools.dotted-border($edge: top);
	
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-micro);
	margin-top: Tools.asRem(Spacing.$gutter-micro);
	padding: Tools.asRem(Spacing.$gutter-small) 0;

	& > button {
		width: fit-content;
	}
}

.empty {
	gap: Tools.asRem(Spacing.$gutter-large);
	flex-direction: column;
	padding-left: Tools.asRem(Spacing.$gutter-medium);
	max-width: Tools.asRem(464px);
	line-height: Tools.asRem(26px);

	& > h4 { 
		font-family: OpenSans;
		font-size: Tools.asRem(13px); 
		margin-bottom: Tools.asRem(Spacing.$gutter-large);
	}

	& > button {
		width: fit-content;
		margin: 0 Tools.asRem(Spacing.$gutter-small);
	}
}

.subscriptionBadge {
	background: transparent;
	color: Colors.$amber-90;
	position: absolute;
	top: 0;

	&::before {
		mask-image: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M5 21V5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L13.0815 17.1953C12.4227 16.7717 11.5773 16.7717 10.9185 17.1953L5 21Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	}
}

.priceHeader {
	cursor: pointer;

	button {
		:global{
			.p-button-label {
				width: 0;
			}
		}
	}
}

.contextMenu {
	&:global {
		&.p-menu {
			padding: Tools.asRem(Spacing.$gutter-small) 0;
			border: 1px solid var(--full-black);
			border-radius: 3px;
		}
	}

	:global {
		.p-menu-list {
			margin-left: 0;

			.p-menuitem-icon {
				align-items: center;
				margin-right: 0;
				color: Colors.$text-secondary;
			}

			.p-menuitem-link .p-menuitem-text {
				color: Colors.$text-secondary;
			}

			.p-menuitem-link {
				flex: 1 0 100%;
				padding: Tools.asRem(Spacing.$gutter-small);

				&:hover:not(.p-disabled) {
					background: Colors.$slate;

					.p-menuitem-icon {
						color: Colors.$text-primary;
					}
				}
			}
		}
	}

	ul > :first-child {
		border-bottom: 1px dashed Colors.$datatable-row-border;
	}
}