@import './../styles/shared-variables.scss';

@media (max-width: $tabletBreakPointWidth) {
    .hide-tablet {
        display: none;
    }
}


@media (max-width: $mobileBreakPointWidth) {
    .hide-mobile {
        display: none;
    }
}