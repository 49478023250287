@use 'sass:math';

@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/objects" as *;

.p-multiselect {

    background-color: Colors.$input-background-color; 
    border: 1px solid Colors.$input-border-color;
    transition: $formElementTransition;
    border-radius: $borderRadius;
		min-height: Tools.asRem(36px);
		
		flex-grow: 1;
		
    &:not(.p-disabled):hover {
			border-color: Colors.$input-border-hover
    }
		
    &:not(.p-disabled).p-focus {
			@include focused-input();
    }
		
    &.p-multiselect-clearable {
			.p-multiselect-label {
				padding-right: nth($inputPadding, 2) + $primeIconFontSize;
			}
    }
		
		&-label {
			padding: Tools.asRem(Spacing.$gutter-small) 0;
			display: flex;
			flex-wrap: wrap;
			gap: Tools.asRem(Spacing.$gutter-micro);
		}

		&-token {
			@extend %token;
		}

    .p-multiselect-label {
        // padding: $inputPadding;
      transition: $formElementTransition;
			color: Colors.$text-secondary;

      &.p-placeholder {
            color: Colors.$text-placeholder;
      }
    }

    .p-multiselect-trigger {
        background: transparent;
        color: $inputIconColor;
        width: $inputGroupAddOnMinWidth;
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-multiselect-clear-icon {
        color: $inputIconColor;
        right: $inputGroupAddOnMinWidth;
    }

    &.p-invalid.p-component {
        @include invalid-input();
    }

		&-label-container {
			align-items: center;
			padding-left: Tools.asRem(Spacing.$gutter-small)
		}

		&-footer {
			padding: Tools.asRem(Spacing.$gutter-small);
		}
}

.p-inputwrapper-filled {
    &.p-multiselect {
        &.p-multiselect-chip {
            .p-multiselect-label {
                // padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);

                // &.p-multiselect-items-label {
                //     padding: $inputPadding;
                // }
            }
        }

        &.p-multiselect-clearable {
            .p-multiselect-label {
                padding-right: nth($inputPadding, 2) + $primeIconFontSize;
            }
        }
    }
}

.p-multiselect-panel {
	flex-direction: column;

	background-color: Colors.$dropdown-background;
	
	// color: $inputListTextColor;
	border: 1px solid Colors.$ob-brand-black;
	border-radius: $borderRadius;
	box-shadow: $inputOverlayShadow;

	.p-multiselect-header {
			padding: $inputListHeaderPadding;
			border-bottom: $inputListHeaderBorder;
			color: $inputListHeaderTextColor;
			background: $inputOverlayHeaderBg;
			margin: $inputListHeaderMargin;
			border-top-right-radius: $borderRadius;
			border-top-left-radius: $borderRadius;

			.p-multiselect-filter-container {
					margin: 0 $inlineSpacing;

					.p-inputtext {
							padding-right: nth($inputPadding, 2) + $primeIconFontSize;
					}

					.p-multiselect-filter-icon {
							right: nth($inputPadding, 2);
							color: $inputIconColor;
					}
			}

			.p-multiselect-close {
					@include action-icon();
			}
	}

	.p-multiselect-items {
			
			flex-grow: 1;
			padding: Tools.asRem(Spacing.$gutter-small) 0;

			& > *:not(:last-child) {
				// margin-bottom: Tools.asRem(Spacing.$gutter-medium);
			}


			.p-multiselect-item {
				
				padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
				color: Colors.$text-secondary;
				gap: Tools.asRem(Spacing.$gutter-small);

				&.p-highlight {
						// color: $highlightTextColor;
						// background: $highlightBg;
				}

        &:focus,
        &:hover {
            // color: $inputListItemTextHoverColor;
            // background: $inputListItemHoverBg;
            color: Colors.$ob-brand-black;
            background-color: Colors.$slate-90;

            .p-checkbox {
                border-color: Colors.$ob-brand-black;
            }
        }

            &:focus {
                // @include focused-listitem();
								outline: 0;
            }

            .p-checkbox {
                // margin-right: $inlineSpacing;
            }
        }

        .p-multiselect-item-group {
            // margin: $submenuHeaderMargin;
            // padding: $submenuHeaderPadding;
            // color: $submenuHeaderTextColor;
            // background: $submenuHeaderBg;
            // font-weight: $submenuHeaderFontWeight;
        }

        .p-multiselect-empty-message {
            // padding: $inputListItemPadding;
            // color: $inputListItemTextColor;
            // background: $inputListItemBg;
        }
  }
}

.p-input-filled {
    .p-multiselect {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }
    
        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;
        }
    }
}