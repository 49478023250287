@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/colors' as Colors;

.dragAndDropIcon {
	justify-content: flex-end;
	flex-grow: 1;

	&::before {
		transform: rotate(45deg);
	}
}

.sortableItem {
  padding: Tools.asRem(Spacing.$gutter-micro);

  &.grabbing {
    &, label {
      cursor: grabbing;
    }
  }

  &.grab {
    &, label {
      cursor: grab;
    }
  }

  &.notAllowed {
    &, label {
      cursor: not-allowed;
    }
  }

  &.disabled {
    :global {
      .p-checkbox.p-disabled, label {
        opacity: 0.56;
      }
    }
  }

  &.placeholderItem {
    width: 100%;
    background-color: Colors.$panel-background-color;
    border: 1px solid Colors.$full-black;
  }

  &.activeItem {
    opacity: 0.24;
  }
}

.placeIndicator {
  border-color: Colors.$slate;
  margin-right: Tools.asRem(Spacing.$gutter-small);
}