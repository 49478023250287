@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Color;
@use "src/design-system/tools" as Tools;

html[data-user-agent*='Win64'] {
  ::-webkit-scrollbar {
    width: Tools.asRem(12px);
  }
  
  ::-webkit-scrollbar-track {
    background: Color.$full-grey;
    opacity: 0.26;
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: Color.$charcoal;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-thumb:hover {
    background: Color.$charcoal;
  }

}

