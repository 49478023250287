@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;

.no-internet-connection-ribbon {
    &.p-inline-message {
        padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-large);
        gap: Tools.asRem(Spacing.$gutter-small);
        border: 1px solid Colors.$full-black;
        border-radius: 0;
        background: Colors.$ob-brand-red;

        .p-inline-message-text {
            color: Colors.$full-black;
            font-size: Tools.asRem(13px);
            line-height: Tools.asRem(18px);
            font-weight: 400;
            padding: 0;
        }

        .p-inline-message-icon {
            height: Tools.asRem(16px);
            width: Tools.asRem(16px);
            margin-right: 0;
        }

        *[class*=iconoir-]::before {
            color: Colors.$full-black;
            width: Tools.asRem(16px);
            height: Tools.asRem(16px);
        }
    }
}