@use "src/design-system" as *;
@use "src/design-system/tools" as Tools;

.baltic-rates-table {

    & tr:not(.p-datatable-emptymessage) {
        
        grid-template-columns: 
        minmax(Tools.asRem(96px), Tools.asRem(152px)) 
        minmax(Tools.asRem(64px), .3fr) 
        minmax(Tools.asRem(84px), .3fr)
        minmax(Tools.asRem(164px), .6fr) ;

        @media only screen and (min-width: 960px) {
            grid-template-columns: 
            minmax(Tools.asRem(132px), Tools.asRem(186px)) 
            minmax(Tools.asRem(68px), .5fr) 
            minmax(Tools.asRem(112px), .5fr)
            minmax(Tools.asRem(164px), .6fr) ;
        }

    }
}