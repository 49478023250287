@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.groupSharePopupContainer {
  width: 100%;
  padding: Spacing.$gutter-medium;
  gap: Spacing.$gutter-micro;
  flex-direction: column;
  border-radius: Tools.asRem(3px);

  &Content {
    flex: 1;
    gap: Spacing.$gutter-small;
    align-items: center;

    &Info {
      flex: 2;
      flex-direction: column;

      span {
        display: flex;
        flex-direction: row;
      }
    }

    &Cldd{
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      .p-dropdown {
        height: 100%;
      }

      &Dropdown {
        width: Tools.asRem(370px);
        flex-grow: unset;
      }
    }
  }
}
