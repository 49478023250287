@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/animations" as Animation;
@use "src/design-system/globals/timings" as Timing;

$dimension: Tools.asRem(18px);

.p-checkbox {
    width: fit-content;
    border: 1px solid Colors.$slate;
    padding: Tools.asRem(Spacing.$gutter-micro * .5);
    border-radius: 3px;
    transition: Animation.$animation-border-color;
    position: relative;
    display: inline-flex;
    user-select: none;
    vertical-align: bottom;

    &:hover,
		&:focus-within,
    &:has(.p-focus) {
      border-color: Colors.$slate-70;
    }

    &-box {
        border-radius: 2px;

        background: transparent;
        width: $dimension;
        height: $dimension;
        color: Colors.$text-primary;
        transition: Animation.$animation-background-color;
        align-items: center;
        justify-content: center;
    }

    &.p-disabled {
        pointer-events: none;
        padding: Tools.asRem(2px);

        &.p-highlight .p-checkbox-box {
            background: Colors.$slate-80;
        }
    }

    &-icon {
        transition-duration: Timing.$time-short;
        color: Colors.$text-primary;
        margin: 0 auto;
    }

    &.p-invalid > .p-checkbox-box {
        @include invalid-input();
    }

    &.p-highlight {
        padding: Tools.asRem(2px);
    
        &:not(.p-disabled) .p-checkbox-box {
            background: Colors.$ob-brand-orange;
        }
    }

    // Used in DataTable left column checkbox in selectable row
    & .p-highlight {
        padding: Tools.asRem(2px);

        &:not(.p-disabled) {
            background: Colors.$ob-brand-orange;
        }
    }

    .p-checkbox-input {
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 1;
        outline: 0 none;
        cursor: pointer;
    }

    .p-checkbox { // In Multiselect in Primereact 10.5.2 there is double div with p-checkbox class, in next releases parent div will be  changed to p-multiselect-checkbox as I see
        border: 0;
        padding: 0;
    }
}


