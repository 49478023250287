@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.user-record {
  &--container {
    flex: 1;
    align-items: center;
    gap: Tools.asRem(Spacing.$gutter-medium);
  }

  &--email {
    flex: 1;
    align-items: center;
    gap: Tools.asRem(Spacing.$gutter-micro);

    color: Colors.$text-secondary;
  }
}
