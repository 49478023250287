@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/colors' as Colors;

.container {
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-small);

	@media only screen and (max-width: 960px) {
		min-height: 40dvh;
	}
}

.columnContainer {
	display: flex;
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-micro);
}

.actionsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;

	& > :last-child {
		margin-top: Tools.asRem(20px);
		margin-left: Tools.asRem(Spacing.$gutter-micro);
	}
}

.periodContainer {
	margin-top: Tools.asRem(Spacing.$gutter-small);
}

.container > div {
	// flex-grow: 1
}

.bar {
	&::before {
		top: 38px
	}
}

.error {
	font-weight: 400;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	padding: Tools.asRem(Spacing.$gutter-medium);
	gap: Tools.asRem(Spacing.$gutter-small);

	& > div { 
		color: Colors.$sky-70;
	}
}
