@use "colors" as Colors;
@use "spacing" as Spacing;
@use "../tools" as Tools;


%font-buttons {
	font-family: Panton;
}

%font-button-general {
	@extend %font-buttons;
	font-size: Tools.asRem(16px);
	font-weight: 600;
}

%font-button-small {
	@extend %font-buttons;
	font-size: Tools.asRem(14px);
	font-weight: 400;
}

@mixin font-button-small {
	font-family: Panton;
	font-size: Tools.asRem(14px);
	font-weight: 400;
}