@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.result {
	display: flex;
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-micro / 2);
	background-color: Colors.$charcoal;
	color: Colors.$text-primary;

	font-size: Tools.asRem(13px);
	font-family: OpenSans;

	& > div {
		gap: Tools.asRem(Spacing.$gutter-micro);

		&:first-child {
			margin-bottom: Tools.asRem(3px);
		}
	}

	label {
		cursor: pointer;
	}
}

.datapoint {
	text-transform: uppercase;
	font-weight: 600;
	line-height: Tools.asRem(18px);
	padding: Tools.asRem(Spacing.$gutter-micro / 2) 0;

	& label {
		flex: 0 0 75px;
		color: Colors.$text-secondary;
		font-weight: 400;
		text-transform: none;
	}
}

.location {
	gap: Tools.asRem(Spacing.$gutter-micro);
	line-height: Tools.asRem(18px);
	align-items: center;
	text-transform: uppercase;
}