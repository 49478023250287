@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/helpers/DataTable/Templates/ColumnTemplates/General.module" as General;

%token {
	background-color: Colors.$token-background;
	color: Colors.$token-label;
	border: 1px solid Colors.$token-border;
	border-radius: 2px;
	font-size: Tools.asRem(13px);
	padding: 0 Tools.asRem(6px);
	height: Tools.asRem(24px);
	gap: Tools.asRem(Spacing.$gutter-micro);
	font-weight: 400;
	align-items: center;

	& label { font-weight: 700; }

	.token-template-value { // Truncate value
		max-width: Tools.asRem(133px);
		display: block;
		@extend .truncated;
	}

	.token-template-truncated {
		cursor: help;
	}

	// & .p-multiselect-token-icon,
	& .p-autocomplete-token-icon {
		margin-left: Tools.asRem(Spacing.$gutter-small)
	}

	&:has(span[class*='icon']),
	&:has(button) {
		padding-right: Tools.asRem(2px);
	}

	& span[class*='icon']::before,
	& button {
		padding: 0 !important;
		width: 16px;
		height: 16px;
	}

	&.small { font-size: Tools.asRem(11px); }
}