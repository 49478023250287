@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.workflow-details {
  overflow: auto;

  .workflow-details__container {
    overflow: auto;
    flex-direction: column;
    height: 100%;

    .workflow-details__nav {
      .workflow-details-filter-option:first-of-type {
        margin-left: Tools.asRem(Spacing.$gutter-large);
      }

      .workflow-details__updated-details {
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        padding-right: Tools.asRem(60px); // space for right side element (close button)
        color: Colors.$text-secondary;
        gap: Tools.asRem(Spacing.$gutter-small);
      }
    }

    .progress-tab__container,
    .info-tab__container {
      flex-direction: column;
      overflow: auto;
      height: 100%;
    }

    .info-tab__container {
      .info-tab__details {
        display: grid;
        grid-template-columns: repeat(3, 30%);
        gap: Tools.asRem(Spacing.$gutter-small);
        color: Colors.$text-secondary;

        &:not(.info-tab__details--task-details) {
          flex-grow: 0;
        }

        > :first-child, // title
        > :last-child { // notes
          grid-column-start: 1;
          grid-column-end: 4;
          justify-content: center;
        }

        > div {
          color: Colors.$text-primary;
          flex-direction: column;
          min-height: Tools.asRem(34px);
        }

        span {
          color: Colors.$text-secondary;
          text-transform: uppercase;
          font-size: Tools.asRem(11px);
          font-weight: 700;

          &.not-available__container {
            text-transform: none;
            font-style: italic;
          }
        }
      }

      .info-tab__history {
        flex-direction: column;
        gap: Tools.asRem(Spacing.$gutter-small);

        .p-accordion {
          border-radius: 0;
        }

        .p-accordion-header {
          border: none;

        .p-accordion-header-link {
          border: none;
          border-bottom: 2px solid Colors.$full-black;
          border-radius: 0;
          padding: Tools.asRem(Spacing.$gutter-micro / 2) Tools.asRem(Spacing.$gutter-small);
        }
      }

        .p-accordion-content {
          padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
        }

        .info-tab__details--task-details {
          > :first-child {
            grid-column-start: unset;
            grid-column-end: unset;
          }

          > :nth-last-child(2) {
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }

        h4, small, span {
          font-size: Tools.asRem(13px);
          font-family: OpenSans, sans-serif;
          font-weight: 400;
        }

        h4 {
          font-weight: 700;
        }

        small {
          font-style: italic;
        }
      }
    }

    .progress-tab__container {
      .workflow-details__datatable.p-datatable {
        overflow: auto;

        .p-datatable-tbody {
          // display rows in two columns
          // TODO: this needs to be re-worked as rows should occupy width depending on screen width
          // see mobile resolution below as well
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: Tools.asRem(Spacing.$gutter-small);
          padding: Tools.asRem(Spacing.$gutter-small);

          & > tr {
            border-radius: 3px;


            &:not(.p-rowgroup-header):not(.p-rowgroup-footer) {
              &:not(.p-highlight):hover,
              &:not(.p-highlight):active,
              &:not(.p-highlight):focus-within {
                outline: 1px solid var(--ob-orange);
                outline-offset: -1px;
              }

              &.p-highlight {
                .task-card__container {
                  background-color: transparent;

                  * {
                    color: Colors.$text-primary;
                    background-color: transparent;
                  }
                }
              }
            }

            &:not(.p-datatable-emptymessage) {
              grid-template-columns: unset;
            }

            &.p-rowgroup-header {
              grid-column-start: 1;
              grid-column-end: 3;
              margin-left: calc(-1 * Tools.asRem(Spacing.$gutter-small));

              td {
                // make space for chevron toggler
                padding-left: 2 * Tools.asRem(Spacing.$gutter-large);

                // make whole group header clickable
                .p-row-toggler {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  padding-left: Tools.asRem(Spacing.$gutter-small);
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }
    }

    .p-dropdown {
      border-radius: 0;
      min-height: Tools.asRem(36px); // override rule from _dropdown.scss

      .p-dropdown-trigger {
        color: Colors.$text-primary;
      }
    }

    footer {
      height: Tools.asRem(50px);

      button {
        height: Tools.asRem(34px);
        font-size: Tools.asRem(16px);
      }
    }
  }

  .workflow-details__progress-bar {
    padding: Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-small);
  }

  @media only screen and (max-width: 960px) {
    header.workflow-details__header {
      padding: 0;
      min-height: unset;

      button {
        width: 100%;
      }
    }

    .workflow-details__container {
      .workflow-details__nav {
        flex-direction: column;
        padding: Tools.asRem(Spacing.$gutter-small);
        gap: Tools.asRem(Spacing.$gutter-small);

        .workflow-details__tab-select-dropdown {
          order: 0;
        }

        .workflow-details__filters-container {
          order: 3;

          .workflow-details-filter-option:first-of-type {
            margin-left: 0;
          }
        }

        .workflow-details__updated-details {
          order: 2;
          justify-content: flex-start;
        }

        .close-button {
          display: none;
        }
      }

      .editable-content__container {
        width: 100%;
        flex: 1 0 100%;

        .button--edit {
          .p-button-icon::before {
            color: Colors.$text-secondary;
          }

          &:hover {
            &, .p-button-icon::before {
              // default button-text hover
              color: Colors.$ob-brand-orange;
            }
          }
        }

        form {
          flex-grow: 1;

          .p-inputtext {
            font-size: Tools.asRem(13px);
          }
        }
      }

      .workflow-name__container {
        padding: Tools.asRem(Spacing.$gutter-medium);

        .button--edit {
          font-family: Panton, sans-serif;
          font-size: Tools.asRem(18px);
          line-height: Tools.asRem(22px);
          align-items: stretch;

          .p-button-icon::before {
            width: Tools.asRem(18px);
            height: Tools.asRem(18px);
          }
        }
      }

      .progress-tab__container,
      .info-tab__container {
        overflow: unset;
      }

      .progress-tab__container {
        .workflow-details__datatable.p-datatable {
          overflow: unset;

          .p-datatable-tbody {
            display: flex;
            flex-direction: column;

            & > tr {
              &.p-rowgroup-header span {
                text-wrap: wrap;
              }
            }
          }
        }
      }

      .info-tab__container {
        padding-top: Tools.asRem(Spacing.$gutter-medium);

        .info-tab__details {
          display: flex;
          flex-direction: column;

          .info-tab__details--item {
            &:has(.workflow-name__container) {
              display: none;
            }
          }
        }

        .info-tab__history {
          .info-tab__history--header-container {
            flex-direction: column;
            align-items: flex-start;
            gap: Tools.asRem(Spacing.$gutter-micro);
            padding-bottom: Tools.asRem(Spacing.$gutter-micro);
          }
        }
      }
    }
  }
}