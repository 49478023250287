@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.instrumentSuggestion {
  display: grid;
  grid-template-columns: 1fr .3fr;
  gap: Tools.asRem(Spacing.$gutter-small);

  > :nth-child(2) {
    text-align: end;
    padding-right: calc(2 * Tools.asRem(Spacing.$gutter-large));
  }
}