@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.sharePopupWindow {
  flex-direction: column;
  align-items: flex-start;
  gap: Tools.asRem(24px);

  &LoaderBar {
    &::before {
      animation: loop 2s linear infinite;
    }

    @keyframes loop {
      0% {
        left: 0%;
        right: 100%;
        width: 0%;
      }
      10% {
        left: 0%;
        right: 75%;
        width: 25%;
      }
      75% {
        left: 60%;
        right: 30%;
        width: 40%;
      }
      100% {
        left: 100%;
        right: 0%;
        width: 0%;
      }
    }
  }

  &InfoPanel {
    width: 100%;
  }
}

.usersSection {
  width: 100%;
  flex-direction: column;
  gap: Tools.asRem(Spacing.$gutter-small);

  &Title {
    font-weight: 700;
  }
}
