@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.price-change-subscriptions {
  &__container {
    max-height: 50%;

    &.p-panel.p-component:not(div:first-child) {
      padding: 0;
      gap: unset;
    }

    .p-panel-header {
      padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small);
      border-color: Colors.$panel-border-color;
      background-color: Colors.$column-header-background;
      color: Colors.$text-secondary;
      font-family: Panton, sans-serif;
      font-size: Tools.asRem(14px);
    }

    .p-panel-content {
      gap: Tools.asRem(Spacing.$gutter-micro);
      border: none;
      padding: 0 Tools.asRem(Spacing.$gutter-small);
      background: none;
      flex-grow: 1;
      flex-direction: column;
      height: 100%;
    }

    .p-toggleable-content {
      overflow: auto;
      height: 100%;
      padding-top: Tools.asRem(Spacing.$gutter-small);
    }
  }

  &__loader {
    overflow: hidden;
    position: relative;
    height: Tools.asRem(2px);
  }

  &__content {
    &--no-data {
      gap: Tools.asRem(Spacing.$gutter-small);

      p {
        color: Colors.$slate-80;
      }

      .p-button-text {
        color: Colors.$ob-brand-orange;
        width: Tools.asRem(220px);
      }
    }
  }
}