@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.calculation-results__header {
    padding: Tools.asRem(Spacing.$gutter-small);
    border-bottom: 1px solid Colors.$full-black;
    color: Colors.$text-secondary;
    background: Colors.$charcoal;

    font-size: Tools.asRem(18px);
    font-family: Panton, OpenSans;
    font-weight: normal;
}

.calculation-results__container {

    .p-timeline {
        padding-top: Tools.asRem(Spacing.$gutter-large);

        .p-timeline-event {
            flex-shrink: 0;
        }

        .p-timeline-event-content {
            padding-right: 0;
            padding-bottom: Tools.asRem(Spacing.$gutter-large);
        }
            
        .p-timeline-event-opposite {
            display: none;
        }

        .p-timeline-event-connector {
            background-color: Colors.$slate;
        }
    }

    .p-accordion-header:not(.p-disabled) {
        &:not(.p-highlight),
        &.p-highlight {
            &,
            &:hover {
                .p-accordion-header-link {
                    border-radius: 0;
                    border-left: 3px solid Colors.$ob-brand-orange;
                    border-right: 3px solid Colors.$ob-brand-orange;
                    border-bottom: none;
                }
            }
        }
    }

     &.p-accordion {
        .p-accordion-tab {
            flex-direction: column;
            flex-grow: 1;
        }

        .p-accordion-content {
            width: 100%;
        }

        .p-toggleable-content {
            flex-basis: 0px;
        }

        .p-accordion-toggle-icon {
            align-self: flex-start;
            margin-top: Tools.asRem(Spacing.$gutter-micro);
        }
    }
}
