@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;


.probability {
	flex-grow: 1;
	padding: 0 Tools.asRem(Spacing.$gutter-small);
	
	& img { 
		width: 6px; 
		aspect-ratio: 1 / 1;
		opacity: .6;
	}
	gap: Tools.asRem(Spacing.$gutter-micro * .5);
}

.low {
	background-color: Colors.$ob-brand-red;
}

.medium {
	// border: 1px solid orange;
	background-color: Colors.$ob-brand-orange;
}

.high {
	background-color: Colors.$grass;
}