@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.surveillanceExportPopup {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: Tools.asRem(Spacing.$gutter-large);

  & div {
    flex-direction: column;
    gap: Tools.asRem(Spacing.$gutter-small);

    & label {
      padding: Tools.asRem(Spacing.$gutter-micro) 0;
    }

    &.surveillanceExportPopupInfo {
      width: 100%;
      gap: Tools.asRem(Spacing.$gutter-medium);
    }
  }

  &Info {
    border: 1px solid Colors.$sky-80;
    border-radius: Tools.asRem(3px);
    padding: Tools.asRem(Spacing.$gutter-medium);
    max-width: Tools.asRem(638px);

    & > div {
      flex-direction: row;
    }
  }

  &InfoHeader {
    font-weight: 700;
    align-items: center;

    i {
      color: Colors.$sky-80;
    }
  }

  &SectionHeader {
    color: Colors.$text-primary !important;
  }

  &IncludeInReport {
    flex-direction: column;
    gap: Tools.asRem(Spacing.$gutter-small);

    & div {
      flex-direction: row;
      align-items: center;

      gap: Tools.asRem(Spacing.$gutter-small);
    }
  }

  &TimezoneRadioButtons {
    gap: Tools.asRem(Spacing.$gutter-small);

    & div {
      flex-direction: row;
    }
  }
}
