@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.status {
  color: Colors.$text-secondary;
  gap: Tools.asRem(Spacing.$gutter-small);

  :global {
    *[class*=iconoir-].icon--small::before {
      width: Tools.asRem(18px);
      height: Tools.asRem(18px);
    }
  }

  &Active {
    i::before {
      color: Colors.$grass-80;
    }
  }

  &Expired {
    i::before {
      color: Colors.$tomato-90;
    }
  }

  &Pending {
    i::before {
      color: Colors.$sky-80;
    }
  }

  &Expiring {
    align-items: center;
    padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-primary;
    width: 100%;

    &Warn {
      background-color: Colors.$ob-brand-orange;
    }

    &Danger {
      background-color: Colors.$ob-brand-red;
    }
  }
}