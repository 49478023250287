@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

.closeButton {
	@include Tools.top-right-element;
}

.flexCenterParent,
.flexCenterChild {
  flex: 1;
  flex-wrap: wrap;
  align-content: center;
}
.flexCenterParent { flex-direction: column }
.flexCenterChild { flex-direction: row }

.topRibbon {
	justify-content: start;
	gap: Tools.asRem(Spacing.$gutter-small);
	border: none;
	border-radius: 0;
	height: auto;
	position: relative;

	& span {
		font-size: Tools.asRem(13px) !important;
		line-height: Tools.asRem(18px);
		font-family: OpenSans, sans-serif;
		font-weight: 700;
	}
}

.tabview {
	& > .p-tabview-title {
		font-weight: 600;
	}

	& ul {
		flex-grow: 0;
		margin-right: Tools.asRem(58px); // X button to close side panel

		& li {
			& a {
				display: flex;
				justify-content: center;
				height: 100%;
				margin-left: Tools.asRem(Spacing.$gutter-small);
				margin-right: Tools.asRem(Spacing.$gutter-small);
				padding-bottom: Tools.asRem(Spacing.$gutter-medium);
			}
		}
	}
}

.scroll {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;

  > div {
    flex: 1;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}

.itemContainer {
  padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small) 0px;
}
