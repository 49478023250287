@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use 'src/design-system/globals/fonts' as Fonts;

.container {
	align-items: center;
}

.container > button {
	background-color: transparent !important;
	align-items: center
}

.container > label { 
	@include Fonts.definition-label;
	flex-grow: 1;
}

.detail {
	margin: Tools.asRem(Spacing.$gutter-small);
	border: 1px solid Colors.$panel-border-color;
	flex-direction: column;
}