@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;

.cargo-tracker {
	&__nav {
			display: flex;
			justify-content: space-between;
			padding: Tools.asRem(Spacing.$gutter-small);
			flex-direction: row;

			@media (max-width: 960px) {
					flex-direction: column-reverse;
					padding: 0;

					.p-dropdown{
							min-height: 0;
							padding: Tools.asRem(Spacing.$gutter-small);
							margin: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small) 0 Tools.asRem(Spacing.$gutter-small);

							.p-dropdown-label {
									color: Colors.$text-primary;
									font-size: Tools.asRem(13px);
									font-weight: 400;
									line-height: Tools.asRem(18px);
							}

							.p-dropdown-trigger {
									padding: 0;
							}
					}
			}
	}

	&-add-button__container {
			display: flex;
			justify-content: flex-end;
			padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-large);

			@media (max-width: 960px) {
					width: 100%;
					justify-content: center;
					padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
					background-color: Colors.$charcoal;
			}

			button,
			.p-button.p-button-sm,
			.p-button.p-button-small {
					align-self: center;

					@media (max-width: 960px) {
							width: 100%;
							justify-content: center;
							border: 0;
							font-family: Panton, OpenSans, sans-serif;
							font-size: Tools.asRem(16px);
							font-weight: 400;
							line-height: Tools.asRem(18px);
							padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);

					}
			}
	}

	&__table {
		&--spot.p-datatable,
		&--time-charter.p-datatable {
			overflow: hidden;

			.p-datatable-tbody {
				.row--urgent,
				.row--urgent .lower,
				& > tr.row--urgent.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
					color: Colors.$ob-brand-red;
				}
			}

			.flagged {
					align-items: center;
			}

			.time-badge {
				display: flex;
				padding: Tools.asRem(6px) ;
				border-radius: Tools.asRem(2px);
				margin-bottom: Tools.asRem(1px);
				border: Tools.asRem(1px) solid Colors.$sky-80;
				font-family: OpenSans, sans-serif;
				font-size: Tools.asRem(13px);
				font-weight: 700;
				line-height: Tools.asRem(16px);
				color: Colors.$sky-80;

				&-new {
						color: Colors.$grass-70;
						border-color: Colors.$grass-70;
				}
			}

			.p-datatable-tbody tr.p-rowgroup-footer {
					display: none;
			}
		}

		&--spot tr, &--time-charter tr, &.p-datatable-emptymessage {
				border-bottom: 1px solid Colors.$ob-brand-black;

				th:first-of-type {
						background-color: Colors.$column-header-background;
				}

				td:first-of-type {
						background-color: Colors.$panel-background;
				}

				.cell--unit {
						flex-grow: 1;
						justify-content: space-between;

						:nth-child(2) {
								font-weight: 700;
						}
				}

				&:not(.row--urgent):not(.p-selected-row) .cell--unit :nth-child(2) {
					color: Colors.$text-secondary;
				}
		}

		$grid-template-checkbox-column: Tools.asRem(48px);
		$grid-template-badge-column: Tools.asRem(42px);

		&--spot {
			& tr {
				grid-template-columns:
						$grid-template-checkbox-column minmax(Tools.asRem(164px), 1fr) $grid-template-badge-column repeat(11, minmax(Tools.asRem(164px), 1fr));
				cursor: pointer;
			}
	}

	&--time-charter {
			& tr {
				grid-template-columns:
						$grid-template-checkbox-column minmax(Tools.asRem(164px), 1fr) $grid-template-badge-column repeat(12, minmax(Tools.asRem(164px), 1fr));
				cursor: pointer;
			}
		}
	}

	&__group {
		align-items: center;
		gap: Tools.asRem(Spacing.$gutter-micro);

			label {
					color: Colors.$text-primary;
					font-family: Panton, sans-serif;
					font-size: Tools.asRem(14px);
					font-weight: 400;
					line-height: Tools.asRem(16px);
			}

			.p-dropdown {
					background-color: transparent;

					.p-dropdown-label, .p-inputtext {
							color: Colors.$text-primary;
							font-family: OpenSans, sans-serif;
							font-size: Tools.asRem(13px);
							font-weight: 400;
							line-height: Tools.asRem(18px);
					}

					.p-icon {
							color: Colors.$text-secondary;
					}
			}
	}

	&__main {
			section {
					overflow: hidden;

					.cargo-tracker__view {
							border: 0;
							border-radius: 0;
							background-color: Colors.$ob-brand-black;

							.p-dataview-content {
									background-color: transparent;
									flex-grow: 1;

									.p-grid {
											display: flex;
											flex-direction: column;
											flex-grow: 1;
											gap: Tools.asRem(Spacing.$gutter-small);

											.cargo-element {
													background-color: Colors.$panel-background;
													display: grid;
													grid-template-rows: auto;
													grid-template-columns: repeat(2, 1fr);

													border: 1px solid Colors.$full-black;
													border-radius: Tools.asRem(3px);
													padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);

                                                    &--urgent .grid-element__body {
                                                        color: Colors.$ob-brand-red;
                                                    }

													&:hover,
													&:focus {
															background: Colors.$charcoal;
													}
											}

											.p-dataview-emptymessage {
													border: 0;
													padding: Tools.asRem(Spacing.$gutter-small);
											}
									}
							}
					}
			}

			$close-button-size: 18px;

			.close-button {
				padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);

            @include Tools.top-right-element;
        }
    }
}

.module__canvas .cargo-tracker__main footer:has(> *) {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(20px);
    line-height: Tools.asRem(19px);
}

.table-footer-dialog-body {
    display: flex;
    flex-direction: column;

    h1 {
        border-bottom: 1px dashed Colors.$slate;
        font-family: Panton, OpenSans, sans-serif;
        font-size: Tools.asRem(24px);
        font-weight: 400;
        line-height: Tools.asRem(30px);
        text-align: left;
        padding-bottom: Tools.asRem(Spacing.$gutter-small);
        margin-bottom: Tools.asRem(Spacing.$gutter-small);
        color: Colors.$text-secondary;
    }

    button {
        position: absolute;
        right: Tools.asRem(Spacing.$gutter-large);
        top:  Tools.asRem(Spacing.$gutter-large);
    }
}

@media (max-width: 960px) {
    .module__canvas .cargo-tracker__main {
        overflow: visible;

        &.drawer--active {
            overflow-y: scroll;
        }
    }

    .module__canvas .cargo-tracker__main > section {
        container: none;
        flex-grow: 1;
        padding: 0 Tools.asRem(Spacing.$gutter-small);
    }
}

