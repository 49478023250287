@use "src/design-system/globals/colors" as Colors;

%dotted-border {
  background-origin: border-box;
}
  
@mixin dotted-border($edge: bottom) {

  //  default behaviors…
  $target: right;
  $direction: repeat-x;
  $size: 6px 1px;

  @if ($edge == right or $edge == left) {
    $target: bottom;
    $direction: repeat-y;
    $size: 1px 4px;
  };

  background-image: linear-gradient(to $target, Colors.$datatable-row-border 0%, Colors.$datatable-row-border 33%, transparent 34%);
  background-size: $size;
  background-position: $edge;
  background-repeat: $direction;
}
