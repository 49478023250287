// General UI colours
$charcoal: var(--charcoal);

$full-grey: var(--full-grey);

$slate: var(--slate);
$slate-90: var(--slate-90);
$slate-80: var(--slate-80);
$slate-70: var(--slate-70);
$slate-60: var(--slate-60);
$slate-50: var(--slate-50);
$slate-40: var(--slate-40);
$slate-30: var(--slate-30);
$slate-20: var(--slate-20);




