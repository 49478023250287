@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

main.container {
  gap: Tools.asRem(56px) !important; // override .module__canvas main
  padding: Tools.asRem(Spacing.$gutter-medium) 0;
  max-width: Spacing.$empty-page-max-width;
  margin: auto;
  flex-direction: column;
  align-items: center;

  .image {
    width: Tools.asRem(345px);
  }

  .title,
  .subTitle {
    color: Colors.$text-secondary;
    text-align: center;
  }

  .title {
    text-transform: uppercase;
    font-size: Tools.asRem(18px);
    line-height: Tools.asRem(20px);
    font-weight: 400;
  }

  .subTitle {
    font-size: Tools.asRem(13px);
    line-height: Tools.asRem(18px);
    font-family: OpenSans, sans-serif;
    font-weight: 400;
  }

  .action {
    gap: Tools.asRem(Spacing.$gutter-large);
    align-items: center;
    flex-direction: column;

    button {
      margin-top: Tools.asRem(Spacing.$gutter-large);
      font-size: Tools.asRem(16px);
      line-height: 1;
      padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    }
  }

  @media only screen and (max-width: 960px) {
    padding: Tools.asRem(Spacing.$gutter-small);

    .image {
      width: Tools.asRem(225px);
    }
  }
}