@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.notes {
  flex-direction: column;
  gap: Tools.asRem(Spacing.$gutter-small);

  > * {
    &::before {
      content: '•';
    }
  }
}
