div,
nav,
td,
th,
main,
section,
aside,
header,
hgroup,
time,
data,
var,
form,
figcaption,
button {
    display: flex;
}

tr {
    display: grid;
}