@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

.metadataContainer  {
  flex: 1;
  flex-direction: column;
  gap: 0;
  padding: 0;

  & * {
    flex-direction: row;
  }

  & * > dt {
    align-content: center;
    font-family: OpenSans, sans-serif;
    font-weight: 700;
    font-size: Tools.asRem(11px);
    line-height: Tools.asRem(15px);
    color: Colors.$text-secondary;
    text-transform: uppercase;
  }

  & * > dd {
    align-content: center;
    word-break: break-word;
    font-family: OpenSans, sans-serif;
    font-weight: 400;
    font-size: Tools.asRem(13px);
    line-height: Tools.asRem(18px);
    color: Colors.$text-primary;
  }
}

.metadataOneContainerItem {
  & * {
    flex-direction: column;
  }
}

.metadataTwoContainerItems {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: Tools.asRem(Spacing.$gutter-small);

  & * {
    min-width: 50%;
    flex-direction: column;
  }
}

.metadataRecordIdContentRow {
  align-items: center;
  flex-direction: row;
  gap: Tools.asRem(Spacing.$gutter-small);

  & * {
    flex-direction: unset;
  }
}

.metadataCopyButton {
  padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-medium) !important;
}

.metadataContent {
  flex: 1;
  flex-direction: column;
  gap: Tools.asRem(Spacing.$gutter-small);
  padding: Tools.asRem(Spacing.$gutter-small);
}
