@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;

.cell {
	display: grid;
	grid-template-columns: 1fr;
	gap: Tools.asRem(Spacing.$gutter-micro * .5);
	flex-grow: 1;
}

@container main-body (max-width: #{Spacing.$table-reflow-break-point}) {
	.flagged > div:last-child  { grid-area: 1 / 2 / 2 / 2 };
}

@container main-body (min-width: #{Spacing.$table-reflow-break-point}) {
	.flagged > div:last-child  { grid-area: 1 / 2 / 3 / 2 };
}

.flagged {

	
	> div:last-child {
		height: 100%;
    align-items: center;
	}

	grid-template-columns: 1fr Tools.asRem(24px);
	grid-template-areas: "port flag" "terminal flag";
}

.upper, .lower { 
	// display: flex;
}

.upper { 
	grid-area: 1 / 1 / 1 / 1;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lower { 
	grid-area: 2 / 1 / 2 / 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@container main-body (max-width: #{Spacing.$table-reflow-break-point}) {
	.aa { display: none; }
}

.flag { 
	grid-area: flag; 
}

.unknown {
	font-style: italic;
	opacity: .6;
}

.cap {
  text-transform: uppercase;
}