@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.leg-header {
    &__container {
        flex-direction: column;
    }

    &__label {
        display: flex;
        gap: Tools.asRem(Spacing.$gutter-micro);

        font-family: OpenSans;
        font-weight: normal;
        text-transform: uppercase;
    }

    &__icon {
        width: Tools.asRem(21px);
    }

    &__sublabel {
        color: Colors.$text-secondary;
        text-transform: uppercase;
        font-size: Tools.asRem(11px);
    }
}