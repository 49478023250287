@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.sanction-details {
  &__details-pane {
    button {
      flex: 1 0 Tools.asRem(34px);
    }

    .p-tabview {
      height: 100%;

      &:has(+ .close-button) {
        .p-tabview-nav {
          flex: 0 0 calc(100% - Tools.asRem(58px)); // 100% - `top-right-element` width
        }
      }
    }

    .p-tabview-nav {
      li {
        overflow: unset;
        justify-content: center;
        flex: 1 1 auto;
      }

      .p-tabview-nav-link {
        cursor: pointer;
        text-align: center;
      }

      .p-tabview-ink-bar {
        display: none;
      }
    }

    .p-tabview-panels,
    .p-tabview-panel {
      flex-direction: column;
      overflow: hidden;
    }

    .close-button {
      @include Tools.top-right-element;
      gap: 0 !important;
    }

    // compensate gap if there is a ribbon
    > :has(:first-child.ribbon__container) {
      margin-top: -1 * Tools.asRem(Spacing.$gutter-small);
    }
  }

  &__footer {
    padding: Tools.asRem(Spacing.$gutter-small) !important;
    justify-content: center;
  }
}

.sanctions__header {
  .sanctions__search-container {
    @include Tools.dotted-border($edge: right);
    padding-right: Tools.asRem(Spacing.$gutter-small);
    gap: Tools.asRem(Spacing.$gutter-small);
    align-items: flex-start;
    flex-grow: 1;

    .form-input__container:has(.p-invalid)  {
      max-height: unset;
    }

    .form-input__container:has(.sanctions__search-datetime-input) {
      max-width: Tools.asRem(160px);
    }
  }

  .sanctions-search__accordion {
    &.p-accordion,
    .p-accordion-tab,
    .p-accordion-content {
      flex: 1 1 auto;
      flex-direction: column;

      .sanctions__search-container {
        padding: 0;
        padding-bottom: Tools.asRem(Spacing.$gutter-small);
        flex-direction: column;
        background: none; // reset right `dotted-border`

        > * {
          width: 100%;
          max-width: unset;
          max-height: unset;
          justify-content: center;
        }
      }
    }

    .p-accordion-header {
      .p-accordion-header-text {
        font-size: Tools.asRem(Spacing.$base-font);
        color: Colors.$text-primary;
      }
    }

    .p-accordion-content {
      overflow: visible;
      padding: Tools.asRem(Spacing.$gutter-small);

      > *:last-child {
        padding: 0;
      }
    }
  }

  button {
    height: Tools.asRem(36px);
  }
}

.module__canvas > footer:not(.no-background).sanctions__footer {
  &:not(:empty) {
    padding: Tools.asRem(Spacing.$gutter-small);

    button {
      width: 100%;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 960px) {
  .module__canvas:has(> header) > .sanctions__header {
    padding: 0; // override `.module__canvas:has(> header) > header`
  }
}