@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.surveillanceEscalationsSenderCell {
  div[class="importance"] {
    color: Colors.$negative;

    font-family: Panton;
    font-size: Tools.asRem(18px);
    font-weight: 350;
    line-height: Tools.asRem(22px);
  }
}
