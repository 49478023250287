@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;

// Please note, that it's used also in Cargo Tracker so any change here should be verified also there

.additional-filters {
    $overlayZIndex: 1000;

    &__container {
        position: relative;

        .iconoir-filter {
            color: Colors.$text-primary;
        }

        .p-panel {
            position: absolute;
            top: Tools.asRem(36px); // Filter button height
            right: 0;
            width: max-content;
            z-index: $overlayZIndex + 1;
            flex-direction: column;


            &-header {
                background: Colors.$panel-background;
                border: 1px solid Colors.$full-black;
                color: Colors.$text-secondary;
                font-family: Panton, sans-serif;
                font-weight: 400;
                font-size: Tools.asRem(14px);
                padding: Tools.asRem(Spacing.$gutter-small);
            }

            &-content {
                background: Colors.$charcoal;
                border: 1px solid Colors.$full-black;
                border-top: none;
                padding: Tools.asRem(Spacing.$gutter-medium);
                flex-direction: column;
                gap: Tools.asRem(Spacing.$gutter-small);

                .additional-filters__container--internal {
                    gap: Tools.asRem(Spacing.$gutter-large);
                    align-items: center;
                    display: grid;
                    grid-template-columns: 0.8fr minmax(145px, 1fr) minmax(105px, 1fr);
                }
            }

            .selectable-filter {
                &__container {

                    .p-selectbutton {
                        height: Tools.asRem(34px);
                        padding-left: Tools.asRem(Spacing.$gutter-large);

                        .p-button {
                            background-color: transparent;
                            color: Colors.$text-secondary;
                            border-color: Colors.$slate-80;
                            font-size: Tools.asRem(13px);
                            font-weight: 400;

                            &:not(.p-disabled) {
                                &.p-highlight {
                                    background-color: Colors.$ob-brand-orange;
                                    color: Colors.$text-primary;
                                    border-color: transparent;

                                    &:hover {
                                        background-color: Colors.$ob-brand-orange-lighter;
                                    }
                                }

                                &:not(.p-highlight) {
                                    &:hover {
                                        background-color: transparent;
                                        border-color: Colors.$ob-brand-orange;
                                        color: Colors.$ob-brand-orange;
                                    }
                                }
                            }

                            &:hover + .p-button { // Highlight also left border of next item
                                border-left-color: Colors.$ob-brand-orange;
                            }
                        }
                    }
                }
            }

            .additional-filters__close_button {
                aspect-ratio: 1 / 1;
                position: absolute;
                top: Tools.asRem(0px);
                right: 0;
                background-color: transparent !important;
                padding: Tools.asRem(Spacing.$gutter-micro);

                span:first-child {
                    padding: 0;

                    &::before {
                        width: Tools.asRem(20px);
                        height: Tools.asRem(20px);
                    }
                }
            }
        }

        .p-button {
            overflow: visible;
        }

        $indicator-badge-size: Tools.asRem(22px);

        .p-badge {
            display: flex;
            align-items: center;
            justify-content: center;

            width: $indicator-badge-size;
            height: $indicator-badge-size;
            border-radius: calc($indicator-badge-size / 2);

            font-family: OpenSans, sans-serif;
            font-size: Tools.asRem(14px);
            line-height: Tools.asRem(14px);

            color: Colors.$text-primary;
            background-color: Colors.$ob-brand-orange;

            top: Tools.asRem(3px);
            right: Tools.asRem(3px);
        }

        @media only screen and (max-width: 960px) {
            .p-panel {
                position: initial;
                width: 100%;

                &-content {
                    padding: 0;
                    border: none;
                }
            }

            .additional-filters__button-toggle,
            .additional-filters__overlay-element {
                display: none;
            }
        }
    }

    &__checkbox {
        align-items: center;
        flex: 0 0 auto;
        gap: Tools.asRem(Spacing.$gutter-small);
    }

    &__info {
        max-width: Tools.asRem(210px);
        width: 100%;
        color: Colors.$text-secondary;
        font-size: Tools.asRem(11px);
    }

    &__overlay-element {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $overlayZIndex;
        width: 100vw;
        height: 100vh;
        background: transparent;
    }
}