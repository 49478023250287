@use "src/design-system/globals/colors" as Color;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.avoidings__container {
    flex-direction: column;
    font-size: Tools.asRem(13px);
    line-height: Tools.asRem(18px);
    // padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-medium);
    gap: Tools.asRem(Spacing.$gutter-small);
    color: Color.$text-secondary;

    .avoidings__title {
        font-weight: 700;
    }
    
    .avoidings__checkboxes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: Tools.asRem(Spacing.$gutter-large);
        column-gap: Tools.asRem(Spacing.$gutter-small);

        .checkbox {
            &__item {
                align-items: center;
            }
            
            &__label {
                flex-direction: column;
                color: Color.$text-primary;

                small {
                    color: Color.$text-secondary;
                }
            }
        }

        .p-checkbox {
            margin-right: Tools.asRem(Spacing.$gutter-small);
        }
    }
}