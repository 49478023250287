@use "src/design-system/globals/colors" as Color;
@use "src/design-system/globals/animations" as Animations;

.menu-underlay {
    position: absolute;
    background-color: Color.$full-black;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.underlay {

    &-enter {
        opacity: 0;
    }

    &-enter-active,
    &-enter-done,
    &-exit {
        opacity: .86;
    }

    &-enter-active {
        transition: Animations.$animation-fade-up;
    }

    &-exit-active {
        transition: Animations.$animation-fade-out;
        opacity: 0;
    }
}

