@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/tokens" as Tokens;
@use "src/design-system/tools" as Tools;

$outline-color: Colors.$slate-90;

.p-tooltip {
    .p-tooltip-text {
        background: Colors.$tooltip-background;
        color: Colors.$text-secondary;
				border: 1px solid $outline-color;
        padding: Spacing.$gutter-small;
        box-shadow: $inputOverlayShadow;
        border-radius: 6px;
    }

    &.p-tooltip-right {
        .p-tooltip-arrow {
            border-right-color: $outline-color;
        }
    }

    &.p-tooltip-left {
        .p-tooltip-arrow {
            border-left-color: $outline-color;
        }
    }

    &.p-tooltip-top {
        .p-tooltip-arrow {
            border-top-color: $outline-color;
        }
    }

    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            border-bottom-color: $outline-color;
        }
    }
}
