@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;

.create-workflow {
  &__container {
    padding: Tools.asRem(Spacing.$gutter-small);
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;

		& label + div:has(span.error) {
			flex-direction: column;
		}

		& .error {
			color: Colors.$negative;
		}
  }

  &__warning-dialog {
    max-width: Tools.asRem(330px);

    .p-dialog-content, .p-dialog-content strong {
      color: Colors.$text-secondary;
    }
  }

  form {
    flex-direction: column;
    max-width: Tools.asRem(500px);

    .form-input__container {
      display: grid;
      grid-template-columns: 3fr 4fr;
      align-items: center;

      input, textarea {
        &.p-inputnumber-input {
          text-align: unset;
        }
      }

      label {
        font-size: Tools.asRem(13px);
      }

      .message-invalid {
        grid-column-start: 2;
        grid-column-end: 3;
      }

      .p-dropdown.p-invalid {
        border: 1px solid Colors.$negative;
      }

      .custom-rate-input-field {
        grid-column-start: 2;
        grid-column-end: 3;
      }
    }
  }

  header.create-workflow__header {
    font-family: Panton, sans-serif;
    font-size: Tools.asRem(18px);
    font-weight: 400;
    padding: Tools.asRem(Spacing.$gutter-small);
    color: Colors.$text-secondary;
  }

  footer {
    justify-content: flex-end;
  }

  @media only screen and (max-width: 960px) {
    overflow: auto;

    .create-workflow__container {
      h1 {
        padding: Tools.asRem(Spacing.$gutter-small) 0;
      }

      form {
        max-width: 100%;

        .form-input__container {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          > * {
            width: 100% !important; // override .p-input-group
          }
        }
      }
    }

    header.create-workflow__header {
      padding: 0;
      min-height: unset;

      button {
        width: 100%;
      }
    }

    footer {
      button {
        flex-grow: 1;
        justify-content: center;
      }
    }
  }
}