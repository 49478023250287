@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;

.typeBadge {
  border-radius: Tools.asRem(2px);
  padding: 0 Tools.asRem(6px);
  font-weight: 700;
  font-size: Tools.asRem(13px);

  &:global {
    &.p-badge-success {
      border: 1px solid Colors.$positive;
      color: Colors.$positive !important; // keep current color when row is selected
      background-color: Colors.$grass-20;
    }

    &.p-badge-danger {
      border: 1px solid Colors.$negative;
      color: Colors.$negative !important; // keep current color when row is selected
      background-color: Colors.$tomato-20;
    }
  }
}