@use "./src/design-system/globals/colors" as Colors;
@use "./src/design-system/globals/spacing" as Spacing;
@use "./src/design-system/tools" as Tools;

.datatable {

    & tr:not(.p-datatable-emptymessage) {
      
      @media only screen and (min-width: 960px) {
        grid-template-columns:
          minmax(Tools.asRem(248px), Tools.asRem(194px))              // vessel
          repeat(2, minmax(Tools.asRem(198px), Tools.asRem(120px)))  	// owner + charterer
          minmax(Tools.asRem(212px), .6fr)                						// category
          minmax(Tools.asRem(116px), Tools.asRem(120px))              // size
          minmax(Tools.asRem(236px), 1fr)                             // load portt
          Tools.asRem(124px)                              // arrival
          minmax(Tools.asRem(236px), 1fr)                 						// discharge port
          Tools.asRem(124px)                              // discharge time
          minmax(Tools.asRem(164px), 1fr)                 // supplemental
          Tools.asRem(46px);                              // probability
      }

      @container (max-width: #{Spacing.$full-width-table-medium}) {
        //  below 1280 we should hide the grade (size) column
        grid-template-columns:
          minmax(Tools.asRem(248px), Tools.asRem(194px))  						// vessel
          repeat(2, minmax(Tools.asRem(198px), Tools.asRem(120px)))  	// owner + charterer
          minmax(Tools.asRem(212px), .6fr)                						// category
          minmax(Tools.asRem(236px), 1fr)                             // load port
          Tools.asRem(84px)                               						// arrival
          minmax(Tools.asRem(236px), 1fr)                 						// discharge port
          Tools.asRem(84px)                               						// discharge time
          minmax(Tools.asRem(120px), 1fr)                 						// supplemental
          Tools.asRem(46px);                             							// probability
      }
    }

    & td[colspan] {
        //  Empty message containers need to stretch over all the current columns
        grid-area: 1/1/1/8;
    }
}

.hideable {
    @container (max-width: #{Spacing.$full-width-table-medium}) {
        display: none;
    }
}