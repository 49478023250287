@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;

.label {
  pointer-events: none;
  gap: 0;
  flex-grow: 1;
  position: relative;

  &:not(.doubleLined) {
    align-items: center;
  }
}

.doubleLined {
  flex-direction: column;

  &.badged::after {
    top: 8px;
  }
}

.badged {

  &::after {
    align-items: center;
    background-color: Colors.$ob-brand-orange;
    border-radius: 50%;
    color: Colors.$text-tertiary;
    content: attr(data-index);
    display: inline-flex;
    font-size: Tools.asRem(10px);
    height: 18px;
    justify-content: center;
    margin-right: Tools.asRem(Spacing.$gutter-micro);
    padding: 0 4px;
    width: 18px;
    position: absolute;
    right: 0;
    // top: 8px;
  }
}

.icon {
  pointer-events: none;
}