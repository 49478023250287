@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;

.mobile-version-in-development-ribbon {
    &.p-inline-message {
        padding: 0;
        background: Colors.$charcoal;
        border: 1px solid Colors.$full-black;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        justify-content: flex-start;

        .p-inline-message-text {
            color: Colors.$text-primary;
            font-size: Tools.asRem(11px);
            font-weight: 600;
            line-height: Tools.asRem(16px);
        }

        .p-inline-message-icon {
            min-height: Tools.asRem(48px);
            height: 100%;
            width: Tools.asRem(48px);
            justify-content: center;
            align-items: center;
            background-color: Colors.$sky-80;
        }

        *[class*=iconoir-]::before {
            color: Colors.$full-grey;
            width: Tools.asRem(24px);
            height: Tools.asRem(24px);
        }
    }
}