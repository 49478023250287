@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;

.supplemental-body__container {
    gap: Tools.asRem(Spacing.$gutter-micro);

    .p-chip {
        border-radius: 2px;
        border: 1px solid Colors.$full-black;
        background: Colors.$slate-70;
        height: Tools.asRem(18px);
        color: Colors.$text-tertiary;
        font-size: Tools.asRem(10px);
        padding: 0 Spacing.$gutter-micro;
    }
}