@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

@import 'src/styles/themes/themes/obx/variables';

form {
	gap: Tools.asRem(Spacing.$gutter-small);
}

.form-input__container,
form > div {
	display: flex;
	flex-direction: column;
	gap: Tools.asRem(Spacing.$gutter-micro);

	& label {
		color: Colors.$text-secondary;
		font-size: Tools.asRem(11px);
		font-weight: 700;
		width: fit-content;
	}

	&:has(.p-invalid) {
		// outline: 1px solid Colors.$ob-brand-red;
		// outline-offset: -2px;

		.message-invalid {
			color: Colors.$ob-brand-red;
		}
	}

	.p-inputtext, .p-inputgroup {
		&.p-invalid {
			+ small {
				color: Colors.$ob-brand-red;
				// prevent blinking/shrinking small text on input focus
				display: inline-block;
				overflow: hidden;
			}
		}
	}

	.p-inputgroup {

		.p-inputnumber-input,
		.p-inputtext {
			// text-align: right;
		}

		.p-inputgroup-addon {
			color: Colors.$text-secondary;
			font-weight: 700;
		}

		&.p-invalid {
			border: 1px solid Colors.$ob-brand-red;
			border-radius: $borderRadius;

			.p-inputtext,
			.p-inputgroup-addon {
				border: none;
		}

			.p-inputtext {
				// restore border between input and addon
				border-right: 1px solid Colors.$full-black;
				color: Colors.$ob-brand-red;
			}
		}
	}

}

.form-input {


	&__container {
		header & {
			max-height: Tools.asRem(36px);
		}
		& input { width: 100% !important; } //	TODO - explore how we might be able to prevent primereact from forcing the need for this
	}

	&--radio-buttons {
		gap: Tools.asRem(Spacing.$gutter-large);

		& > div {
			gap: Tools.asRem(Spacing.$gutter-small);
			align-items: center;
		}
	}

	&--grouped-input {
		flex-direction: column;
		gap: -1px;

		& > div:not(:first-child) > input,
		& > div:not(:first-child) > span {
			border-top-width: 0;
			border-radius: 0;
		}

		& > div:not(:first-child) > input {
			// outline-offset: -2px;
		}

		& > div:has(+ div:focus-within) > input  {
			border-bottom: 1px solid Colors.$slate-60;
		}

		& > div:first-child > input,
		& > div:first-child > span {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		& > div:first-child > input {
			border-top-left-radius: 0;
		}

		& > div:last-child > input {
			border-bottom-right-radius: 3px;
		}

		& > div:last-child > span {
			border-bottom-left-radius: 3px;
		}
	}
}

