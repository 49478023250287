@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

.imageAttachment {
  width: 100%;
}

.downloadPanel {
  align-items: center;
  flex: 1;
}

.downloadButtonContainer {
  background-color: Colors.$full-black;
  border: 2px solid Colors.$full-black;
  border-radius: Tools.asRem(Spacing.$gutter-micro);

  i {
    padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);

    color: Colors.$text-primary;
    cursor: pointer;
  }
}

.fileName {
  flex-grow: 1;
  padding: Tools.asRem(Spacing.$gutter-micro);
  word-break: break-word;

  font-weight: 400;
}
