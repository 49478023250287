@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;

.dialogBody {
  gap: Tools.asRem(Spacing.$gutter-small);
  padding: Tools.asRem(Spacing.$gutter-small) 0;
  flex-direction: column;

  > div {
    gap: Tools.asRem(Spacing.$gutter-small);
    align-items: center;
  }

  label {
    color: Colors.$text-secondary;
    font-weight: 700;
  }

  input, label {
    cursor: pointer;
  }
}