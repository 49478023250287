@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

@media only screen and (max-width: 960px) {
  // make the page (with datatable) scrollable (search bar & datatable container) but don't touch aside components
  .module__canvas:has(.blotter__main):not(:has(.blotter__main.drawer--active)) {
    overflow: auto;
    padding-bottom: Tools.asRem(97px); // footer height

    .blotter__main {
      section {
        max-width: 100%;
      }
    }

    .blotter__footer {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}

.module__canvas > header.blotter__header {
  padding: Tools.asRem(Spacing.$gutter-large);

  .blotter-trade-search__container {
    gap: Tools.asRem(Spacing.$gutter-small);
    align-items: stretch;

    .p-button {
      height: Tools.asRem(36px);
    }

    @media only screen and (min-width: 960px) {
      &:has(.p-invalid) {
        align-items: flex-start;

        .form-input__container {
          max-width: Tools.asRem(174px);
          max-height: unset;
        }
      }
    }
  }

  .autocomplete-entity-with-sections {
    .p-autocomplete-items-wrapper {
      .item-template {
        grid-template-columns: minmax(min-content, Tools.asRem(132px)) auto;
      }

      .p-autocomplete-item-group {
        &:has(div.grouped-item-template--instrument),
        &:has(div.grouped-item-template--ob-broker),
        &:has(div.grouped-item-template--price),
        &:has(div.grouped-item-template--quantity),
        &:has(div.grouped-item-template--clearing-id) {
          visibility: hidden; // hide some section names as they have group names on first item
          height: 0;
          min-height: 0;
          padding: 0;
        }
      }

      .p-autocomplete-items {
        .item-template--instrument,
        .item-template--ob-broker,
        .item-template--price,
        .item-template--quantity,
        .item-template--clearing-id {
          .item-template-grouplabel {
            font-family: OpenSans, sans-serif;
            font-size: Tools.asRem(13px);
            font-weight: 700;
            line-height: Tools.asRem(18px);
          }
        }
      }
    }
  }
}

.blotter__main {
  padding-top: Tools.asRem(Spacing.$gutter-large);
}

@media only screen and (max-width: 960px) {
  .module__canvas {
     main.blotter__main  {
      gap: unset;
      padding-top: 0;
    }

    footer.blotter__footer,
    .blotter__aside footer {
      button {
        width: 100%;
        min-height: Tools.asRem(34px);
        flex-grow: 1;
        justify-content: center;
      }
    }

    header.blotter__header {
      padding: 0;
      transition: margin-top 0.1s .1s;

      &--hidden {
        margin-top: Tools.asRem(-70px); // SearchBar height + LastUpdated height
      }
    }

    .blotter-trade-search__container {
      flex-direction: column;
      padding: Tools.asRem(Spacing.$gutter-small);

      button {
        justify-content: center;
      }
    }

    .blotter-trade-search__accordion {
      &.p-accordion,
      .p-accordion-tab,
      .p-accordion-content {
        flex: 1 1 auto;
        flex-direction: column;

        .form-input__container {
          max-height: unset;
        }
      }

      .p-accordion-header {
        .p-accordion-header-text {
          font-size: Tools.asRem(Spacing.$base-font);
          color: Colors.$text-primary;
        }
      }

      .p-accordion-content {
        overflow: visible;
      }
    }
  }
}
