@use "src/design-system/tools" as Tools;

.individual-sanction-detail--container {
    .column {
        &--aka,
        &--pob {
            tr {
                grid-template-columns: 1fr minmax(Tools.asRem(90px), 0.4fr);
            }
        }

        &--address {
            tr {
                grid-template-columns: 1fr minmax(Tools.asRem(90px), 0.3fr) minmax(Tools.asRem(80px), 0.4fr);
            }
        }
    }

    .p-datatable-thead {
        tr {
            align-items: center;
        }
    }

    .p-datatable-tbody {
        tr {
            td {
                &:not(.contents--fill) {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                }
            }
        }
    }
}