@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;

.tabbed-navigation-set {
	&__container {
		flex-direction: column;
		gap: Tools.asRem(Spacing.$gutter-small);
		height: fit-content;


		// & > div:not(:first-child) {
		// 	flex-grow: 1;
		// 	flex-wrap: wrap;

		// 	// & button:first-child { padding-left: Tools.asRem(Spacing.$gutter-medium); }

		// 	@media only screen and (max-width: 960px) { 
		// 		padding: 0 Spacing.$gutter-small; 
		// 	}
		// }
	}

    &--share-space {

        &.as-2-col > button { width: 50%; }
        &.as-3-col > button { width: 33.33%; }
        &.as-4-col > button { width: 25%; }

        & > button { 
					justify-content: center; 
					flex-grow: 1;
				}
    }
}