@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.price-history {
    &__container {
        flex-direction: column;
        gap: Tools.asRem(Spacing.$gutter-small);
				padding-bottom: Tools.asRem(Spacing.$gutter-large);

        & > *:not(header) {
            padding: 0 Tools.asRem(Spacing.$gutter-small);
        }

        & > time {
            margin-left: Tools.asRem(32px);
        }

        & header { 
            justify-content: space-between;
						height: Tools.asRem(40px);
        
            @media only screen and (min-width: 960px) {
                & > button { display: none; }
            }
        
        }
    }

    &__chart {
        padding-top: Tools.asRem(Spacing.$gutter-large);
    }
}