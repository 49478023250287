@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.section > div {
	padding: Tools.asRem(Spacing.$gutter-medium);
}

.group {
	margin-top: Tools.asRem(Spacing.$gutter-small);
	gap: Tools.asRem(Spacing.$gutter-micro);
	align-items: center;
}

.result {
	border: 1px solid Colors.$slate;
	background-color: Colors.$charcoal;
	padding: Tools.asRem(Spacing.$gutter-large);
	font-family: monospace;
}