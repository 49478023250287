@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;


.sanction-status[role="cell"] {
    &:has(.cell-sanction-status--medium),
    &:has(.cell-sanction-status--high) {
        border: 2px solid rgba(var(--charcol), 1);
        height: 100%;

				& label {
					color: Colors.$text-primary;
				}
    }

    &:has(.cell-sanction-status--high) {
        background-color: Colors.$negative;
    }

    &:has(.cell-sanction-status--medium) {
        background-color: Colors.$ob-brand-orange;
    }

}

*[class*="-sanction-status"] {
    gap: Tools.asRem(Spacing.$gutter-small);
}



.cell-sanction-status {
    &--high {
        // font-weight: 400;
        // flex-grow: 1;
        // overflow: hidden;

        & span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &--unknown,
    &--low {
        color: Colors.$text-secondary;
    }

    &--low {

        &::before {
            color: Colors.$grass-80;
        }
    }

    &--unknown { font-style: italic; }
}