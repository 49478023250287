@use "src/design-system/tools" as Tools;



@import 'src/styles/colours.scss', 'src/styles/shared-variables.scss', 'src/styles/responsive.scss';


.rates-grid-table {
    & tr:not(.p-datatable-emptymessage) {
        @media only screen and (min-width: 960px) {
            // grid-template-columns: minmax(Tools.asRem(152px), .6fr) repeat(auto-fit, Tools.asRem(162px)) ;
            grid-template-columns: Tools.asRem(216px);
            grid-auto-columns: Tools.asRem(164px);
            grid-auto-flow: column;
        }

        @media only screen and (max-width: 960px) {
            // grid-auto-columns: Tools.asRem(132px);
            grid-auto-flow: column;

            &:has(> :nth-child(4):last-child) { grid-template-columns: repeat(3, minmax(50%, 196px)); }
            
            &:has(> :nth-child(3):last-child) { grid-template-columns: repeat(2, minmax(50%, 196px)); }


            & > td:first-child,
            & > th:first-child {
                grid-area: 1 / 1 / 1 / 4;
            }
        }
    }

    @media only screen and (max-width: 960px) {
        & thead th:first-child { display: none };
        & tbody tr:not(.p-datatable-emptymessage) {
            grid-template-rows: 32px 1fr;
        }
    }
}


// .rate-grid-table {

    


//     &__container {
//         // display: block;
//     }

//     &__row {

//         gap: 8px;

//         display: flex;
//         flex-direction: row;
//         align-items: flex-start;

//         &>div {
//             max-width: 150px;
//             flex: 1;
//         }

//         .editable-div {
//             display: inline;
//             flex: none;
//             margin-right: 5px;
//         }


//         cursor: pointer;
//         min-height: 32px;
//         font-size: .865rem;
//         padding: 4px 0 8px 0;
//         border-bottom: 1px solid rgba(#fff, .26);

//         &>div:first-child {
//             font-weight: bolder;
//             padding-left: 8px;
//         }

//         & .number {
//             justify-content: flex-end;
//             padding-right: 4px;
//         }
//     }

//     .unit {
//         background-color: $textSecondary;
//     }



// }

// .value-select {
//     background: transparent !important;
//     color: $textPrimary;
//     border: none;

//     option {
//         background: $bgHighlight;
//         color: $textPrimary;

//     }

// }



// .unit {
//     color: $textSecondary;
//     margin-right: 10px;
// }

// .pill {
//     background-color: $bgHighlight;
//     margin: 5px;
//     padding: 3px;
//     height: 30px;
//     border-radius: 5px;
//     min-width: 100px;
//     text-align: center;
//     color: $textPrimary;


//     &.updated {
//         background-color: $success;
//     }

//     &.outdated {
//         background-color: $warning;
//     }

//     &.disabled {
//         background-color: $bgSecondary;
//     }

//     &.invalid {
//         background-color: $danger;
//     }


// }

// .invalid-data {
//     color: $textAttention;
// }

// .value-container {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start
// }

// .updated-at {
//     color: $textSecondary;
//     font-size: .75rem;
// }

// .editable-div {
//     display: flex;
//     flex-direction: row;
//     margin-right: 5px;
//     min-width: 50px;
//     border: dashed #fff 2px;


//     &.no-edit {
//         border-style: none;
//     }

// }

// .row--header {
//     font-weight: bolder;
//     background-color: rgba(#000, .265);

// }

// .download-rate-grid {
//     display: block;
//     flex: none;
//     margin: 10px;
//     margin-left: calc(100% - 150px);
//     background-color: $bgHighlight;
//     border-color: $bgHighlight;
// }

// .rate-grid-notification {
//     display: block;
//     flex: none;
//     margin: 10px;
//     margin-left: calc(100% - 70px);
//     background-color: $bgHighlight;
//     border-color: $bgHighlight;
//     padding: 0.25rem 0.88rem !important;
//     height: 27px !important;

//     &.--enabled {
//         background-color: $success;
//     }

//     &.--disabled {
//         background-color: $danger;
//     }

// }