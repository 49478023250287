//	SASS implimentation of the base CSS file for HC found https://code.highcharts.com/css/highcharts.css. Makes use of the
//	OBX design system properties/variables
@use 'sass:list' as Lists;

@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;


%no-stroke { stroke-width: 0; }
%bold-text { font-weight: bold; }
%no-fill { fill: none; }
%position-absolute { position: absolute; }
%font-80 { font-size: 0.8rem; }
%cursor-pointer { cursor: pointer; }
%cursor-default { cursor: default; }
%no-pointer-evemts { pointer-events: none; }

// .chart {
// 	// &__container {
// 	// 	background-color: transparent;
// 	// 	// border: 1px solid red;

// 	// 	// & .highcharts-credits { display: none;}
// 	// }
// }

/**
 * @license Highcharts
 *
 * (c) 2009-2022 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */

 :root,
 .highcharts-light {
		 /* Colors for data series and points */
		 --highcharts-color-0: #2caffe;
		 --highcharts-color-1: #544fc5;
		 --highcharts-color-2: #00e272;
		 --highcharts-color-3: #fe6a35;
		 --highcharts-color-4: #6b8abc;
		 --highcharts-color-5: #d568fb;
		 --highcharts-color-6: #2ee0ca;
		 --highcharts-color-7: #fa4b42;
		 --highcharts-color-8: #feb56a;
		 --highcharts-color-9: #91e8e1;

		 /* Chart background, point stroke for markers and columns etc */
		 --highcharts-background-color: transparent;

		 /*
		 Neutral colors, grayscale by default. The default colors are defined by
		 mixing the background-color with neutral, with a weight corresponding to
		 the number in the name.

		 https://www.highcharts.com/samples/highcharts/css/palette-helper
		 */

		 /* Strong text. */
		 --highcharts-neutral-color-100: var(--text-primary);

		 /* Main text, axis labels and some strokes. */
		 --highcharts-neutral-color-80: var(--text-secondary);

		 /* Axis title, connector fallback. */
		 --highcharts-neutral-color-60: #{Colors.$slate};

		 /* Credits text, export menu stroke. */
		 --highcharts-neutral-color-40: #999999;

		 /* Disabled texts, button strokes, crosshair etc. */
		 --highcharts-neutral-color-20: #cccccc;

		 /* Grid lines etc. */
		 --highcharts-neutral-color-10: #e6e6e6;

		 /* Minor grid lines etc. */
		 --highcharts-neutral-color-5: #f2f2f2;

		 /* Tooltip backgroud, button fills, map null points. */
		 --highcharts-neutral-color-3: #f7f7f7;

		 /*
		 Highlights, shades of blue by default
		 */

		 /* Drilldown clickable labels, color axis max color. */
		 --highcharts-highlight-color-100: #0022ff;

		 /* Selection marker, menu hover, button hover, chart border, navigator
		 series. */
		 --highcharts-highlight-color-80: #334eff;

		 /* Navigator mask fill. */
		 --highcharts-highlight-color-60: #667aff;

		 /* Ticks and axis line. */
		 --highcharts-highlight-color-20: #ccd3ff;

		 /* Pressed button, color axis min color. */
		 --highcharts-highlight-color-10: #e6e9ff;

		 /* Indicators */
		 --highcharts-positive-color: #{Colors.$positive};
		 --highcharts-negative-color: #{Colors.$negative};

		 --highcharts-navigator-mask-fill: #{Colors.$indigo-50};
 }

//  @media (prefers-color-scheme: dark) {
// 		 :root {
// 				 /* UI colors */
// 				 --highcharts-background-color: rgb(48, 48, 48);

// 				 /*
// 						 Neutral color variations
// 						 https://www.highcharts.com/samples/highcharts/css/palette-helper
// 				 */
// 				 --highcharts-neutral-color-100: rgb(255, 255, 255);
// 				 --highcharts-neutral-color-80: rgb(214, 214, 214);
// 				 --highcharts-neutral-color-60: rgb(173, 173, 173);
// 				 --highcharts-neutral-color-40: rgb(133, 133, 133);
// 				 --highcharts-neutral-color-20: rgb(92, 92, 92);
// 				 --highcharts-neutral-color-10: rgb(71, 71, 71);
// 				 --highcharts-neutral-color-5: rgb(61, 61, 61);
// 				 --highcharts-neutral-color-3: rgb(57, 57, 57);

// 				 /* Highlight color variations */
// 				 --highcharts-highlight-color-100: rgb(122, 167, 255);
// 				 --highcharts-highlight-color-80: rgb(108, 144, 214);
// 				 --highcharts-highlight-color-60: rgb(94, 121, 173);
// 				 --highcharts-highlight-color-20: rgb(65, 74, 92);
// 				 --highcharts-highlight-color-10: rgb(58, 63, 71);
// 		 }
//  }

 .highcharts-dark {
		 /* UI colors */
		 --highcharts-background-color: rgb(48, 48, 48);

		 /* Neutral color variations */
		 --highcharts-neutral-color-100: rgb(255, 255, 255);
		 --highcharts-neutral-color-80: rgb(214, 214, 214);
		 --highcharts-neutral-color-60: rgb(173, 173, 173);
		 --highcharts-neutral-color-40: rgb(133, 133, 133);
		 --highcharts-neutral-color-20: rgb(92, 92, 92);
		 --highcharts-neutral-color-10: rgb(71, 71, 71);
		 --highcharts-neutral-color-5: rgb(61, 61, 61);
		 --highcharts-neutral-color-3: rgb(57, 57, 57);

		 /* Highlight color variations */
		 --highcharts-highlight-color-100: rgb(122, 167, 255);
		 --highcharts-highlight-color-80: rgb(108, 144, 214);
		 --highcharts-highlight-color-60: rgb(94, 121, 173);
		 --highcharts-highlight-color-20: rgb(65, 74, 92);
		 --highcharts-highlight-color-10: rgb(58, 63, 71);
 }

//  .highcharts-container {

//  }

 .highcharts {

	&-background {
		fill: transparent;
	}

	&-container {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		text-align: left;
		line-height: normal;
		z-index: 0; /* #1072 */
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		font-family: 'OpenSans', Helvetica, Arial, sans-serif;
		font-size: 1rem;
		user-select: none;
		touch-action: manipulation;
	}

	&-container, &-markers,	&-a11y-proxy-element,	&-point {
		outline: none;
	}

	&-credits { display: none;}

	&-tick,	&-grid-line, &-axis-line {
		stroke: var(--data-visualisation-axis-line);
	}

	&-root {
		display: block;

		& text { @extend %no-stroke; }
	}

	&-emphasized { font-style: italic; }

	&-a11y-proxy {
		&-element,
		&-container,
		&-container-before,
		&-container-after {
			@extend %position-absolute;
		}

		&-container,
		&-container-before,
		&-container-after {
			white-space: nowrap;
		}

		&-element {
			@extend %cursor-pointer;

			border-width: 0;
			background-color: transparent;
			opacity: 0.001;
			z-index: 999;
			overflow: hidden;
			padding: 0;
			margin: 0;
			display: block;
		}

		&-group li { list-style: none; }
	}

	&-anchor {
		@extend %cursor-pointer;
	}

	&-annotation {
		&-label {
			& text { @extend %font-80; }
		}
	}

	&-axis {
		&-line {
			@extend %no-fill;

			stroke-width: 2px;
		}

		&-labels {
			@extend %cursor-default;
			@extend %font-80;

			fill: Colors.$data-visualisation-axis-labels;
		}

		&-title {
			@extend %font-80;

			fill: Colors.$text-primary;
		}
	}

	&-breadcrumbs {
		&-button {
			@extend %no-fill;
			@extend %cursor-pointer;
		}
	}

	&-button {

		@extend %cursor-default;

		& text { @extend %font-80; }

		&-pressed { @extend %bold-text; }
	}

	$chart-color-options: Colors.$grass-80, Colors.$egg-plant-70, Colors.$sky-70, Colors.$amber-80, Colors.$crimson-80, Colors.$lime-60, Colors.$slate-70, Colors.$indigo-80, Colors.$egg-plant-90, Colors.$ob-brand-orange;

	@each $item in $chart-color-options {

		$index: Lists.index($chart-color-options, $item) - 1;

		&-color-#{$index} {
			fill: $item;
			stroke: $item;
		}
	}

	&-credits {
		@extend %cursor-pointer;

		fill: Colors.$text-secondary;
		font-size: Tools.asRem(12px);
		transition: fill 250ms, font-size 250ms;


		&:hover {
			fill: Colors.$text-tertiary;
			font-size: Tools.asRem(11px);
		}
	}

	&-data-label {
		@extend %bold-text;

		& text {
			@extend %bold-text;
		}

		&-box,
		&-container {
			@extend %no-fill;
		}

		&-hidden { @extend %no-pointer-evemts; }
	}

	&-drilldown {
		&-data-label,
		&-axis-label {
			@extend %bold-text;
		}

		&-data-label,
		&-axis-label,
		&-point {
			@extend %cursor-pointer;
		}
	}

	&-empty {
		&-series {
			@extend %no-fill;
		}
	}

	&-focus {
		&-border {
			@extend %no-fill;

			stroke-width: 2px;
		}
	}

	&-graph {
		@extend %no-fill;

		stroke-width: 2px;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	&-grid {
		&-line {
			@extend %no-fill;
		}
	}

	&-button-pressed, &-drilldown-data-label, &-no-data, &-flags-series .highcharts-point {
		& text { @extend %bold-text; }
	}

	&-label {

		& text {
			@extend %font-80;
			fill: Colors.$data-visualisation-axis-labels;
		}

		// &-box { @extend %no-fill; }
	}

	&-loading {

		@extend %position-absolute;

		&-inner {
			@extend %bold-text;
		}
	}

	&-legend {

		&-box,
		&-title-box {
			@extend %no-fill;
		}

		&-item {

			& > text {
				@extend %bold-text;
				@extend %font-80;
				@extend %cursor-pointer;

				fill: Colors.$text-secondary;

				&-hidden .highcharts-focus-border { fill: none !important; }
			}

			&:hover > text { fill: Colors.$text-primary; }

			&-hidden * {
				transition: fill 250ms;
		 		text-decoration: line-through;
				fill: Colors.$text-tertiary !important;
				stroke: Colors.$text-tertiary !important;
			}
		}

		&-nav- {
			&-active {
				@extend %cursor-pointer;
				fill: red; // TODO - change this as/when its needed...
			}

			&-inactive {
		 		fill: green // TODO - change this as/when its needed...;
			}
		}
	}

	&-mapline {
		&-series .highcharts-point  {
			@extend %no-fill;
		}
	}

	&-mapview {
		&-inset-border {
			@extend %no-fill;
		}
	}

	&-menu {
		&-item {
			@extend %font-80;
			@extend %cursor-pointer;
		}
	}

	&-minor {
		&-grid-line {
			stroke: red; // Leaving this as an obvious colour for now...
		}
	}

	&-navigator {
		&-outline {
			@extend %no-fill;
		}
	}

	&-no-data {
		& text { @extend %font-80; }
	}

	&-plot {
		&-background,
		&-border,
		&-line {
			@extend %no-fill;
		}

		&-line {
			stroke: yellow;
			stroke-width: 1px;
		}

		&-line-label {
			@extend %font-80;
		}
	}

	&-point {
		&-connecting path {
			@extend %no-fill;
		}
	}

	&-range {
		&-label rect,
		&-input rect {
			@extend %no-fill;
		}

		&-input text { @extend %font-80; }
	}

	&-series {
		&-label text {
			@extend %bold-text;

			fill: inherit;
		}
	}

	&-subtitle {
		@extend %font-80;

		fill: Colors.$text-secondary;
	}

	&-strong { @extend %bold-text; }

	&-title {
		@extend %bold-text;

		fill: Colors.$text-primary;
		font-size: Tools.asRem(16px);
	}

	&-tracker {
		&-area {
			@extend %no-stroke;

			fill: rgba(192, 192, 192, 0.0001);
		}

		&-line {
			@extend %no-fill;

			stroke-linejoin: round;
			stroke: rgba(192, 192, 192, 0.0001);
			stroke-width: 22;
		}
	}

	&-treegrid {
		&-node-expanded,
		&-node-collapsed {
			@extend %cursor-pointer;
		}
	}

	&-treemap-series {

		& .highcharts-internal-node-interactive {
			@extend %cursor-pointer;
		}

		& .highcharts-internal-node {
			@extend %no-fill;
		}
	}

	&-title {
		fill: Colors.$text-primary;
		font-size: Tools.asRem(16px);
	}

	&-tooltip {
		@extend %font-80;
		@extend %cursor-default;
		@extend %no-pointer-evemts;

		white-space: nowrap;
		transition: stroke 150ms;
		filter: none;

		& .highcharts-header,
		& > text {
			@extend %font-80;
			fill: Colors.$data-visualisation-axis-labels;
		}

		&-box {
			stroke-width: 1px;
			fill: Colors.$data-visualisation-tool-tip-background;
		}

		&-label-box {
			fill: var(--highcharts-background-color);
		}
	}

	&-vector {
		&-series .highcharts-point {
			@extend %no-fill;
		}
	}

	&-visually-hidden {
		@extend %position-absolute;
	}

	&-windbard {
		&-series .highcharts-point {
			@extend %no-fill;
		}
	}

	&-yaxis {
		& .highcharts-axis-line { @extend %no-stroke; }
	}

	&-xaxis {

		&-grid {
			& .highcharts-grid-line { @extend %no-stroke; }
		}
	}


 }


//  .highcharts-background {

// 		//  fill: var(--highcharts-background-color);
//  }

//  .highcharts-plot-border,
//  .highcharts-plot-background {
// 		 fill: none;
//  }

//  .highcharts-label-box {
// 		 fill: none;
//  }

//  .highcharts-label text {
// 		 fill: var(--highcharts-neutral-color-80);
// 		//  font-size: 0.8em;
//  }

 .highcharts-button-box {
		 fill: inherit;
 }

//  .highcharts-tracker-line {
// 		 stroke-linejoin: round;
// 		 stroke: rgba(192, 192, 192, 0.0001);
// 		 stroke-width: 22;
// 		//  fill: none;
//  }

//  .highcharts-tracker-area {
// 		 fill: rgba(192, 192, 192, 0.0001);
// 		//  stroke-width: 0;
//  }

 /* Titles */
 .highcharts-title {
		//  fill: var(--highcharts-neutral-color-80);
		//  font-size: 1.2em;
		//  font-weight: bold;
 }

 .highcharts-subtitle {
		//  fill: var(--highcharts-neutral-color-60);
		//  font-size: 0.8em;
 }

 /* Axes */
//  .highcharts-axis-line {
// 		//  fill: none;
// 		//  stroke: var(--data-visualisation-axis-line);
// 		 stroke-width: 2px;
//  }

 .highcharts-yaxis .highcharts-axis-line {
		//  stroke-width: 0;
 }

 .highcharts-axis-title {
		//  fill: var(--highcharts-neutral-color-60);
		//  font-size: 0.8rem;
 }

 .highcharts-axis-labels {
		//  fill: var(--text-secondary);
		// //  cursor: default;
		//  font-size: 0.8rem;
 }

 .highcharts-grid-line {
		//  fill: none;
		//  stroke: var(--data-visualisation-axis-line);
		//  stroke-dasharray: ;
 }

 .highcharts-xaxis-grid .highcharts-grid-line {
		//  stroke-width: 0;
 }

 .highcharts-tick {
		//  stroke: var(--highcharts-neutral-color-80);
 }

 .highcharts-yaxis .highcharts-tick {
		//  stroke-width: 0;
 }

 .highcharts-minor-grid-line {
		//  stroke: var(--highcharts-neutral-color-5);
 }

 .highcharts-crosshair-thin {
		 stroke-width: 1px;
		 stroke: var(--highcharts-neutral-color-20);
 }

 .highcharts-crosshair-category {
		 stroke: var(--highcharts-highlight-color-20);
		 stroke-opacity: 0.25;
 }

 /* Credits */
 .highcharts-credits {
		//  cursor: pointer;
		//  fill: var(--highcharts-neutral-color-40);
		//  font-size: 0.6em;
		//  transition: fill 250ms, font-size 250ms;
 }

 .highcharts-credits:hover {
		//  fill: var(--highcharts-neutral-color-100);
		//  font-size: 0.7em;
 }

 /* Tooltip */
//  .highcharts-tooltip {
// 		//  cursor: default;
// 		//  pointer-events: none;
// 		 white-space: nowrap;
// 		 transition: stroke 150ms;
//  }

//  .highcharts-tooltip text {
		//  fill: var(--highcharts-neutral-color-80);
		//  font-size: 0.8em;
//  }

//  .highcharts-tooltip .highcharts-header {
		//  font-size: 0.8em;
//  }

//  .highcharts-tooltip-box {
// 		 stroke-width: 1px;
// 		 fill: var(--data-visualisation-tool-tip-background);
//  }

//  .highcharts-tooltip-box {
// 		 stroke-width: 0;
// 		 fill: var(--highcharts-background-color);
//  }

//  .highcharts-tooltip-box .highcharts-label-box {
// 		 fill: var(--highcharts-background-color);
//  }

//  div.highcharts-tooltip {
// 		//  filter: none;
// 		//  font-size: 0.8em;
//  }

 .highcharts-selection-marker {
		 fill: var(--highcharts-highlight-color-80);
		 fill-opacity: 0.25;
 }

//  .highcharts-graph {
// 		//  fill: none;
// 		//  stroke-width: 2px;
// 		//  stroke-linecap: round;
// 		//  stroke-linejoin: round;
//  }

 .highcharts-empty-series {
		 stroke-width: 1px;
		//  fill: none;
		 stroke: var(--highcharts-neutral-color-20);
 }

 .highcharts-state-hover .highcharts-graph {
		 stroke-width: 3;
 }

 .highcharts-point-inactive {
		 opacity: 0.2;
		 transition: opacity 50ms; /* quick in */
 }

 .highcharts-series-inactive {
		 opacity: 0.2;
		 transition: opacity 50ms; /* quick in */
 }

 .highcharts-state-hover path {
		 transition: stroke-width 50ms; /* quick in */
 }

 .highcharts-state-normal path {
		 transition: stroke-width 250ms; /* slow out */
 }

 /* Legend hover affects points and series */
 g.highcharts-series,
 .highcharts-point,
 .highcharts-markers,
 .highcharts-data-labels {
		 transition: opacity 250ms;
 }

 .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
 .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
 .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
 .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
		 opacity: 0.2;
 }

 /* Series options */

 /* Default colors */
//  .highcharts-color-0 {
// 		 fill: var(--highcharts-color-0);
// 		 stroke: var(--highcharts-color-0);
//  }

//  .highcharts-color-1 {
// 		 fill: var(--highcharts-color-1);
// 		 stroke: var(--highcharts-color-1);
//  }

//  .highcharts-color-2 {
// 		 fill: var(--highcharts-color-2);
// 		 stroke: var(--highcharts-color-2);
//  }

//  .highcharts-color-3 {
// 		 fill: var(--highcharts-color-3);
// 		 stroke: var(--highcharts-color-3);
//  }

//  .highcharts-color-4 {
// 		 fill: var(--highcharts-color-4);
// 		 stroke: var(--highcharts-color-4);
//  }

//  .highcharts-color-5 {
// 		 fill: var(--highcharts-color-5);
// 		 stroke: var(--highcharts-color-5);
//  }

//  .highcharts-color-6 {
// 		 fill: var(--highcharts-color-6);
// 		 stroke: var(--highcharts-color-6);
//  }

//  .highcharts-color-7 {
// 		 fill: var(--highcharts-color-7);
// 		 stroke: var(--highcharts-color-7);
//  }

//  .highcharts-color-8 {
// 		 fill: var(--highcharts-color-8);
// 		 stroke: var(--highcharts-color-8);
//  }

//  .highcharts-color-9 {
// 		 fill: var(--highcharts-color-9);
// 		 stroke: var(--highcharts-color-9);
//  }

 /* Various series-specific */
 .highcharts-area {
		 fill-opacity: 0.75;
		//  stroke-width: 0;
 }

 .highcharts-markers {
		 stroke-width: 1px;
		 stroke: var(--highcharts-background-color);
 }

 .highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover, .highcharts-a11y-marker-visible),
 .highcharts-a11y-marker-hidden {
		 opacity: 0;
 }

 .highcharts-point {
		 stroke-width: 1px;
 }

 .highcharts-dense-data .highcharts-point {
		//  stroke-width: 0;
 }

 .highcharts-data-label text,
 text.highcharts-data-label {
		 font-size: 0.7em;
		//  font-weight: bold;
 }

 .highcharts-data-label-box {
		//  fill: none;
		//  stroke-width: 0;
 }

 .highcharts-data-label text,
 text.highcharts-data-label {
		 fill: var(--highcharts-neutral-color-80);
 }

 .highcharts-data-label-connector {
		//  fill: none;
 }

 .highcharts-data-label-hidden {
		//  pointer-events: none;
 }

 .highcharts-halo {
		 fill-opacity: 0.25;
		//  stroke-width: 0;
 }

 .highcharts-series-label text {
		//  fill: inherit;
		//  font-weight: bold;
 }

 .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
 .highcharts-markers .highcharts-point-select {
		 fill: var(--highcharts-neutral-color-20);
		 stroke: var(--highcharts-neutral-color-100);
 }

 .highcharts-column-series path.highcharts-point,
 .highcharts-bar-series path.highcharts-point {
		 /* path to prevent stroke on 3D columns and bars */
		 stroke: var(--highcharts-background-color);
 }

 .highcharts-column-series .highcharts-point {
		 transition: fill-opacity 250ms;
 }

 .highcharts-column-series .highcharts-point-hover {
		 fill-opacity: 0.75;
		 transition: fill-opacity 50ms;
 }

 .highcharts-pie-series .highcharts-point {
		 stroke-linejoin: round;
		 stroke: var(--highcharts-background-color);
 }

 .highcharts-pie-series .highcharts-point-hover {
		 fill-opacity: 0.75;
		 transition: fill-opacity 50ms;
 }

 .highcharts-funnel-series .highcharts-point {
		 stroke-linejoin: round;
		 stroke: var(--highcharts-background-color);
 }

 .highcharts-funnel-series .highcharts-point-hover {
		 fill-opacity: 0.75;
		 transition: fill-opacity 50ms;
 }

 .highcharts-funnel-series .highcharts-point-select {
		 fill: inherit;
		 stroke: inherit;
 }

 .highcharts-pyramid-series .highcharts-point {
		 stroke-linejoin: round;
		 stroke: var(--highcharts-background-color);
 }

 .highcharts-pyramid-series .highcharts-point-hover {
		 fill-opacity: 0.75;
		 transition: fill-opacity 50ms;
 }

 .highcharts-pyramid-series .highcharts-point-select {
		 fill: inherit;
		 stroke: inherit;
 }

 .highcharts-solidgauge-series .highcharts-point {
		//  stroke-width: 0;
 }

 .highcharts-treemap-series .highcharts-point {
		 stroke-width: 1px;
		 stroke: var(--highcharts-neutral-color-10);
		 transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
 }

 .highcharts-treemap-series .highcharts-point-hover {
		 stroke: var(--highcharts-neutral-color-40);
		 transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
 }

 .highcharts-treemap-series .highcharts-above-level {
		 display: none;
 }

 .highcharts-treemap-series .highcharts-internal-node {
		//  fill: none;
 }

 .highcharts-treemap-series .highcharts-internal-node-interactive {
		 fill-opacity: 0.15;
		//  cursor: pointer;
 }

 .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
		 fill-opacity: 0.75;
 }

 .highcharts-vector-series .highcharts-point {
		//  fill: none;
		 stroke-width: 2px;
 }

 .highcharts-windbarb-series .highcharts-point {
		//  fill: none;
		 stroke-width: 2px;
 }

 .highcharts-lollipop-stem {
		 stroke: var(--highcharts-neutral-color-100);
 }

 .highcharts-focus-border {
		//  fill: none;
		//  stroke-width: 2px;
 }

 .highcharts-legend-item-hidden .highcharts-focus-border {
		//  fill: none !important;
 }

 /* Legend */
 .highcharts-legend-box {
		//  fill: none;
		//  stroke-width: 0;
 }

 .highcharts-legend-item > text {
		//  fill: var(--highcharts-neutral-color-80);
		//  font-weight: bold;
		//  font-size: 0.8em;
		//  cursor: pointer;
		//  stroke-width: 0;
 }

 .highcharts-legend-item:hover text {
		//  fill: var(--highcharts-neutral-color-100);
 }

 .highcharts-legend-item-hidden * {
		//  fill: var(--highcharts-neutral-color-60) !important;
		//  stroke: var(--highcharts-neutral-color-60) !important;
		//  transition: fill 250ms;
		//  text-decoration: line-through;
 }

 .highcharts-legend-nav-active {
		//  fill: var(--highcharts-highlight-color-100);
		//  cursor: pointer;
 }

 .highcharts-legend-nav-inactive {
		//  fill: var(--highcharts-neutral-color-20);
 }

 circle.highcharts-legend-nav-active,
 circle.highcharts-legend-nav-inactive { /* tracker */
		 fill: rgba(192, 192, 192, 0.0001);
 }

 .highcharts-legend-title-box {
		//  fill: none;
		//  stroke-width: 0;
 }

 /* Bubble legend */
 .highcharts-bubble-legend-symbol {
		 stroke-width: 2;
		 fill-opacity: 0.5;
 }

 .highcharts-bubble-legend-connectors {
		 stroke-width: 1;
 }

 .highcharts-bubble-legend-labels {
		 fill: var(--highcharts-neutral-color-80);
		 font-size: 0.7em;
 }

 /* Loading */
 .highcharts-loading {
		//  position: absolute;
		 background-color: var(--highcharts-background-color);
		 opacity: 0.5;
		 text-align: center;
		 z-index: 10;
		 transition: opacity 250ms;
 }

 .highcharts-loading-hidden {
		 height: 0 !important;
		 opacity: 0;
		 overflow: hidden;
		 transition: opacity 250ms, height 250ms step-end;
 }

 .highcharts-loading-inner {
		//  font-weight: bold;
		 position: relative;
		 top: 45%;
 }

 /* Plot bands and polar pane backgrounds */
 .highcharts-plot-band,
 .highcharts-pane {
		 fill: var(--highcharts-neutral-color-100);
		 fill-opacity: 0.05;
 }

 .highcharts-plot-line {
	 stroke: Colors.$egg-plant-70;
	 stroke-dasharray: 4, 4;
	 stroke-width: Tools.asRem(2px);
 }

g.highcharts-custom-label {
	// Overwrite default inline styles
	background-color: transparent !important;
	padding: 0 !important;

	.highcharts-label-box {
		fill: none;
	}
}

div.highcharts-custom-label {
	// Overwrite default inline styles
	left: 0 !important;
	top: 0 !important;

	span {
		// Overwrite default inline styles
		left: Tools.asRem(4px) !important;
		top: Tools.asRem(0px) !important;
		color: Colors.$egg-plant-60;
		padding: Tools.asRem(Spacing.$gutter-micro) 0 0 Tools.asRem(Spacing.$gutter-micro);
		font-size: Tools.asRem(10px);
		text-transform: uppercase;
		font-family: OpenSans, sans-serif;
		font-weight: 400;
		line-height: Tools.asRem(10px);
		z-index: 3;
	}
}

div.highcharts-custom-label-ct,
div.highcharts-custom-label-ct span {
	// Hide Close Time text on chart but not on clipboard copy
	opacity: 0;
}

g.highcharts-custom-label-ct rect {
	// Hide Close Time background on chart but not on clipboard copy
	fill: transparent !important;
}

 .highcharts-plot-line-label {
		//  font-size: 0.8em;
 }

 /* Highcharts More and modules */
 .highcharts-boxplot-box {
		 fill: var(--highcharts-background-color);
 }

 .highcharts-boxplot-median {
		 stroke-width: 2px;
 }

 .highcharts-bubble-series .highcharts-point {
		 fill-opacity: 0.5;
 }

 .highcharts-errorbar-series .highcharts-point {
		 stroke: var(--highcharts-neutral-color-100);
 }

 .highcharts-gauge-series .highcharts-data-label-box {
		 stroke: var(--highcharts-neutral-color-20);
		 stroke-width: 1px;
 }

 .highcharts-gauge-series .highcharts-dial {
		 fill: var(--highcharts-neutral-color-100);
		//  stroke-width: 0;
 }

 .highcharts-polygon-series .highcharts-graph {
		 fill: inherit;
		//  stroke-width: 0;
 }

 .highcharts-waterfall-series .highcharts-graph {
		 stroke: var(--highcharts-neutral-color-80);
		 stroke-dasharray: 1, 3;
 }

 .highcharts-sankey-series .highcharts-point {
		//  stroke-width: 0;
 }

 .highcharts-sankey-series .highcharts-link {
		 transition: fill 250ms, fill-opacity 250ms;
		 fill-opacity: 0.5;
 }

 .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
		 transition: fill 50ms, fill-opacity 50ms;
		 fill-opacity: 1;
 }

 .highcharts-venn-series .highcharts-point {
		 fill-opacity: 0.75;
		 stroke: var(--highcharts-neutral-color-20);
		 transition: stroke 250ms, fill-opacity 250ms;
 }

 .highcharts-venn-series .highcharts-point-hover {
		 fill-opacity: 1;
		 stroke: var(--highcharts-neutral-color-20);
 }

 .highcharts-timeline-series .highcharts-graph {
		 stroke: var(--highcharts-neutral-color-20);
 }

 /* Highstock */
 .highcharts-navigator-mask-outside {
		 fill-opacity: 0;
 }

 .highcharts-navigator-mask-inside {
		 fill: var(--highcharts-navigator-mask-fill); /* navigator.maskFill option */
		 fill-opacity: 0.25;
		 cursor: ew-resize;
 }

 .highcharts-navigator-outline {
		 stroke: var(--highcharts-neutral-color-40);
		//  fill: none;
 }

 .highcharts-navigator-handle {
		 stroke: var(--highcharts-neutral-color-40);
		 fill: var(--highcharts-neutral-color-5);
		 cursor: ew-resize;
 }

 .highcharts-navigator-series {
		//  fill: var(--highcharts-highlight-color-80);
		//  stroke: var(--highcharts-highlight-color-80);
 }

 .highcharts-navigator-series .highcharts-graph {
		 stroke-width: 2px;
 }

 .highcharts-navigator-series .highcharts-area {
		 fill-opacity: 0.05;
 }

 .highcharts-navigator-xaxis .highcharts-axis-line {
		//  stroke-width: 0;
 }

 .highcharts-navigator-xaxis .highcharts-grid-line {
	stroke-width: 1px;
	stroke: var(--highcharts-neutral-color-40);
	stroke-dasharray: 1,3;
 }

 .highcharts-navigator-xaxis.highcharts-axis-labels {
		 fill: var(--highcharts-neutral-color-100);
		 font-size: 0.7em;
		 opacity: 0.6;
 }

 .highcharts-navigator-yaxis .highcharts-grid-line {
		//  stroke-width: 0;
 }

 .highcharts-scrollbar-thumb {
		 fill: var(--highcharts-neutral-color-20);
		 stroke: var(--highcharts-neutral-color-20);
		//  stroke-width: 0;
 }

 .highcharts-scrollbar-button {
		 fill: var(--highcharts-neutral-color-10);
		 stroke: var(--highcharts-neutral-color-20);
		 stroke-width: 1px;
 }

 .highcharts-scrollbar-arrow {
		 fill: var(--highcharts-neutral-color-60);
 }

 .highcharts-scrollbar-rifles {
		 stroke: none;
		 stroke-width: 1px;
 }

 .highcharts-scrollbar-track {
		 fill: rgba(255, 255, 255, 0.001);
		 stroke: var(--highcharts-neutral-color-20);
		 stroke-width: 1px;
 }

 .highcharts-button {
		 fill: var(--highcharts-neutral-color-3);
		 stroke: var(--highcharts-neutral-color-20);
		//  cursor: default;
		 stroke-width: 1px;
		 transition: fill 250ms;
 }

 .highcharts-button text {
		 fill: var(--highcharts-neutral-color-80);
		//  font-size: 0.8em;
 }

 .highcharts-button-hover {
		 transition: fill 0ms;
		 fill: var(--highcharts-neutral-color-10);
		 stroke: var(--highcharts-neutral-color-20);
 }

 .highcharts-button-hover text {
		 fill: var(--highcharts-neutral-color-80);
 }

 .highcharts-button-pressed {
		//  font-weight: bold;
		 fill: var(--highcharts-highlight-color-10);
		 stroke: var(--highcharts-neutral-color-20);
 }

 .highcharts-button-pressed text {
		 fill: var(--highcharts-neutral-color-80);
		//  font-weight: bold;
 }

 .highcharts-button-disabled text {
		 fill: var(--highcharts-neutral-color-80);
 }

 .highcharts-range-selector-buttons .highcharts-button {
		//  stroke-width: 0;
 }

 .highcharts-range-label rect {
		//  fill: none;
 }

 .highcharts-range-label text {
		 fill: var(--highcharts-neutral-color-60);
 }

 .highcharts-range-input rect {
		//  fill: none;
 }

 .highcharts-range-input text {
		 fill: var(--highcharts-neutral-color-80);
		//  font-size: 0.8em;
 }

 .highcharts-range-input {
		 stroke-width: 1px;
		 stroke: var(--highcharts-neutral-color-20);
 }

 input.highcharts-range-selector {
		 position: absolute;
		 border: 0;
		 width: 1px; /* Chrome needs a pixel to see it */
		 height: 1px;
		 padding: 0;
		 text-align: center;
		 left: -9em; /* #4798 */
 }

 .highcharts-crosshair-label text {
		 fill: var(--highcharts-background-color);
		 font-size: 1.7em;
 }

 .highcharts-crosshair-label .highcharts-label-box {
		 fill: inherit;
 }

 .highcharts-candlestick-series .highcharts-point {
		 stroke: var(--highcharts-neutral-color-100);
		 stroke-width: 1px;
		 paint-order: stroke;
 }

 .highcharts-candlestick-series .highcharts-point-up {
	fill: var(--highcharts-positive-color);
	stroke: var(--highcharts-neutral-color-60);
 }

 .highcharts-candlestick-series .highcharts-point-down {
	fill: var(--highcharts-negative-color);
	stroke: var(--highcharts-neutral-color-60);
 }

 .highcharts-hollowcandlestick-series .highcharts-point-down {
		 fill: var(--highcharts-negative-color);
		 stroke: var(--highcharts-negative-color);
 }

 .highcharts-hollowcandlestick-series .highcharts-point-down-bearish-up {
		 fill: var(--highcharts-positive-color);
		 stroke: var(--highcharts-positive-color);
 }

 .highcharts-hollowcandlestick-series .highcharts-point-up {
		 fill: transparent;
		 stroke: var(--highcharts-positive-color);
 }

 .highcharts-ohlc-series .highcharts-point-hover {
		 stroke-width: 3px;
 }

 .highcharts-flags-series .highcharts-point .highcharts-label-box {
		 stroke: var(--highcharts-neutral-color-40);
		 fill: var(--highcharts-background-color);
		 transition: fill 250ms;
 }

 .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
		 stroke: var(--highcharts-neutral-color-100);
		 fill: var(--highcharts-highlight-color-20);
 }

 .highcharts-flags-series .highcharts-point text {
		 fill: var(--highcharts-neutral-color-100);
		 font-size: 0.9em;
		//  font-weight: bold;
 }

 /* Highcharts Maps */
 .highcharts-map-series .highcharts-point {
		 transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
		 stroke: var(--highcharts-neutral-color-20);
		 stroke-width: inherit;
 }

 .highcharts-map-series .highcharts-point-hover {
		 transition: fill 0ms, fill-opacity 0ms;
		 fill-opacity: 0.5;
 }

 .highcharts-mapline-series .highcharts-point {
		//  fill: none;
 }

 .highcharts-heatmap-series .highcharts-point {
		//  stroke-width: 0;
 }

 .highcharts-map-navigation {
		 font-size: 1.3em;
		 font-weight: bold;
		 text-align: center;
 }

 .highcharts-map-navigation.highcharts-button {
		 fill: var(--highcharts-background-color);
		 stroke: var(--highcharts-neutral-color-10);
 }

 .highcharts-map-navigation.highcharts-button:hover {
		 fill: var(--highcharts-neutral-color-10);
 }

 .highcharts-map-navigation.highcharts-button .highcharts-button-symbol {
		 stroke-width: 2px;
 }

 .highcharts-mapview-inset-border {
		 stroke: var(--highcharts-neutral-color-20);
		 stroke-width: 1px;
		//  fill: none;
 }

 .highcharts-coloraxis {
		//  stroke-width: 0;
 }

 .highcharts-coloraxis-marker {
		 fill: var(--highcharts-neutral-color-40);
 }

 .highcharts-null-point {
		 fill: var(--highcharts-neutral-color-3);
 }

 /* 3d charts */
 .highcharts-3d-frame {
		 fill: transparent;
 }

 /* Exporting module */
 .highcharts-contextbutton {
		 /* Fill is needed to capture hover */
		 fill: var(--highcharts-background-color);
		 stroke: none;
		 stroke-linecap: round;
 }

 .highcharts-contextbutton:hover {
		 fill: var(--highcharts-neutral-color-10);
		 stroke: var(--highcharts-neutral-color-10);
 }

 .highcharts-button-symbol {
		 stroke: var(--highcharts-neutral-color-60);
		 stroke-width: 3px;
 }

 .highcharts-menu {
		 border: none;
		 background: var(--highcharts-background-color);
		 border-radius: 3px;
		 padding: 0.5em;
		 box-shadow: 3px 3px 10px #888888;
 }

 .highcharts-menu-item {
		 background: none;
		 border-radius: 3px;
		 color: var(--highcharts-neutral-color-80);
		//  cursor: pointer;
		//  font-size: 0.8em;
		 list-style-type: none;
		 padding: 0.5em;
		 transition: background 250ms, color 250ms;
 }

 .highcharts-menu-item:hover {
		 background: var(--highcharts-neutral-color-5);
 }

 /* Breadcrumbs */
 .highcharts-breadcrumbs-button {
		//  fill: none;
		//  stroke-width: 0;
		//  cursor: pointer;
 }

 .highcharts-breadcrumbs-separator {
		 fill: var(--highcharts-neutral-color-60);
 }

 /* Drilldown module */
 .highcharts-drilldown-point {
		//  cursor: pointer;
 }

 .highcharts-drilldown-data-label text,
 text.highcharts-drilldown-data-label,
 .highcharts-drilldown-axis-label {
		//  cursor: pointer;
		 fill: var(--highcharts-highlight-color-100);
		//  font-weight: bold;
		 text-decoration: underline;
 }

 /* No-data module */
 .highcharts-no-data text {
		//  font-weight: bold;
		//  font-size: 0.8em;
		 fill: var(--highcharts-neutral-color-60);
 }

 /* Drag-panes module */
 .highcharts-axis-resizer {
		 cursor: ns-resize;
		 stroke: var(--highcharts-neutral-color-100);
		 stroke-width: 2px;
 }

 /* Bullet type series */
 .highcharts-bullet-target {
		//  stroke-width: 0;
 }

 /* Lineargauge type series */
 .highcharts-lineargauge-target {
		 stroke-width: 1px;
		 stroke: var(--highcharts-neutral-color-80);
 }

 .highcharts-lineargauge-target-line {
		 stroke-width: 1px;
		 stroke: var(--highcharts-neutral-color-80);
 }

 /* Annotations module */
 .highcharts-annotation-label-box {
		 stroke-width: 1px;
		 stroke: var(--highcharts-neutral-color-100);
		 fill: var(--highcharts-neutral-color-100);
		 fill-opacity: 0.75;
 }

 .highcharts-annotation-label text {
		 fill: var(--highcharts-neutral-color-10);
		//  font-size: 0.8em;
 }

 /* A11y module */
 .highcharts-a11y-proxy-element {
		//  border-width: 0;
		//  background-color: transparent;
		// //  cursor: pointer;
		//  opacity: 0.001;
		//  z-index: 999;
		//  overflow: hidden;
		//  padding: 0;
		//  margin: 0;
		//  display: block;
		//  position: absolute;
 }

 .highcharts-a11y-proxy-group li {
		 list-style: none;
 }

 .highcharts-visually-hidden {
		//  position: absolute;
		 width: 1px;
		 height: 1px;
		 overflow: hidden;
		 white-space: nowrap;
		 clip: rect(1px, 1px, 1px, 1px);
		 margin-top: -3px;
		 opacity: 0.01;
 }

 .highcharts-a11y-invisible {
		 visibility: hidden;
 }

 .highcharts-a11y-proxy-container,
 .highcharts-a11y-proxy-container-before,
 .highcharts-a11y-proxy-container-after {
		//  position: absolute;
		//  white-space: nowrap;
 }

 g.highcharts-series {
		 outline: none;
 }

 /* Gantt */
 .highcharts-treegrid-node-collapsed,
 .highcharts-treegrid-node-expanded {
		//  cursor: pointer;
 }

 .highcharts-point-connecting-path {
		//  fill: none;
 }

 .highcharts-grid-axis .highcharts-tick {
		 stroke-width: 1px;
 }

 .highcharts-grid-axis .highcharts-axis-line {
		 stroke-width: 1px;
 }